import React from "react"
import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  Table,
  Row,
  Col,
} from "reactstrap"
import {
  deleteBookingAssociation,
  deleteBookingFromBackend,
  getElements,
  getModules,
  getUmbrellas,
  patchLoanBooking,
} from "helpers/fakebackend_helper"
import { useEffect, useState } from "react"
import Modaldeposits from "components/planner/Modaldeposits"
import Modalmodifybooking from "components/anagrafiche/ombrelloni/Modalmodifybooking"
import { BsFillTrashFill, BsTrash } from "react-icons/bs"
import { Link } from "@material-ui/core"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import { data } from "autoprefixer"
import ModalExtras from "components/planner/Modalextras"
export default function Bookinglist(props) {
  const [hasLoan, setHasLoan] = useState(false)
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])

  const loadLoanStatus = async () => {
    let data = await getModules()
    data = data.filter(v => v.fk_modules_id == 2)
    if (data.length > 0) {
      setHasLoan(true)
    }
  }
  useEffect(() => {
    loadLoanStatus()
  }, [])

  const handleLoanChange = async (id, status) => {
    console.log(status)
    const data = await patchLoanBooking({
      id: id,
      loaned: status,
    })
    if (data.status) {
      alert("Stato prenotazione modificato")
      loadData()
    }
  }

  const [modalDeposits, setModalDeposits] = useState({
    show: false,
    data: {},
    id: 0,
  })
  const [firstInit, setFirstInit] = useState(true)
  const [elements, setElements] = useState([])
  const [data, setData] = useState([])
  const [modalModify, setModalModify] = useState({ show: false, data: {} })
  const [filteredData, setFilteredData] = useState([])
  const [valueToSearch, setValueToSearch] = useState("")
  const [focusedExtras, setFocusedExtras] = useState({
    show: false,
    data: [],
    id: null,
  })
  const loadData = async () => {
    let data = await getUmbrellas()
    //filter only isUmbrella == 1
    data = data.filter(v => v.isUmbrella == 1)

    let bookings = []
    data.forEach(v => {
      const bookingsInUmbrella = v.bookings
      bookingsInUmbrella.forEach(bk =>
        bookings.push({ ...bk, number: v.number })
      )
    })
    if (focusedExtras.id) {
      const searchIndex = bookings.findIndex(
        v => v.bookingId == focusedExtras.id
      )
      setFocusedExtras(v => ({
        ...v,
        data: bookings[searchIndex].extras,
      }))
    }
    setData(bookings)
  }
  const loadElements = async () => {
    const data = await getElements()
    setElements(data)
  }

  const deleteBooking = async id => {
    const conf = confirm("Sei sicuro di eliminare questa prenotazione?")
    if (conf) {
      const data = await deleteBookingFromBackend(id)
      if (data.status) {
        alert("Record eliminato")
        loadData()
      }
    }
  }
  useEffect(() => {
    loadData()
    loadElements()
  }, [])
  useEffect(() => {
    if (data.length > 0) {
      setFirstInit(false)
    }
  }, [data])
  useEffect(() => {
    if (!firstInit) {
      const url = new URL(window.location.href)
      let searchParams = new URLSearchParams(url.search)
      const id = searchParams.get("id")
      if (id) {
        const searchIndex = data.findIndex(v => v.bookingId == id)
        setModalModify({ show: true, data: data[searchIndex] })
      }
    }
  }, [firstInit])
  useEffect(() => {
    console.log(data)
    const filteredArray = data.filter(
      v =>
        (v.customer_name + " " + v.customer_surname)
          .toLowerCase()
          .includes(valueToSearch.toLowerCase()) ||
        (v.phone_prefix + " " + v.phone).includes(
          valueToSearch.toLowerCase()
        ) ||
        v.number.toString().includes(valueToSearch.toLowerCase()) ||
        v.number.toLowerCase() == valueToSearch.toLowerCase()
    )
    setFilteredData(filteredArray)
  }, [data, valueToSearch])

  const handleRemoveAssociation = async id => {
    const conf = confirm("Sei sicuro di voler disassociare questo utente?")
    if (conf) {
      const data = await deleteBookingAssociation(id)
      if (data.status) {
        alert("Utente disassociato")
        loadData()
      }
    }
  }

  const handleExtras = async id => {
    console.log(id)
    const element = data.find(v => v.bookingId == id)
    setFocusedExtras(v => ({ ...v, show: true, data: element.extras, id: id }))
  }
  return (
    <>
      <ModalExtras
        show={focusedExtras.show}
        data={focusedExtras.data}
        booking_id={focusedExtras.id}
        refresh={loadData}
        onHide={() => setFocusedExtras(v => ({ ...v, show: false }))}
      />
      <Modaldeposits
        updateData={loadData}
        data={modalDeposits.data}
        show={modalDeposits.show}
        onHide={() => setModalDeposits({ data: {}, show: false })}
      />
      <Modalmodifybooking
        show={modalModify.show}
        elements={elements}
        data={modalModify.data}
        updateData={loadData}
        onHide={() => setModalModify(v => ({ ...v, show: false }))}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Row className="mb-3">
            <Col>
              <Link href="new">
                <Button color="primary">Nuova prenotazione</Button>
              </Link>
            </Col>
            <Col xs={6} lg={4}>
              {" "}
              <Input
                placeholder="Cerca..."
                value={valueToSearch}
                onChange={e => setValueToSearch(e.target.value)}
              />
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>Ombrellone</th>
                    <th>Codice prenotazione</th>
                    <th>Configurazione</th>
                    <th>Utente</th>
                    <th>Utenti associati</th>
                    <th>Cellulare</th>
                    <th>Da</th>
                    <th>A</th>
                    <th>Prezzo</th>
                    <th>Da pagare</th>
                    <th>Extra</th>
                    <th>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((v, k) => {
                    let deposit = 0
                    v.deposits.forEach(el => (deposit += el.amount))
                    return (
                      <tr key={k}>
                        <td>{v.number}</td>
                        <td>{v.short_code}</td>
                        <td>
                          {v.elements.map((el, k2) => (
                            <div key={k2}>
                              {el.name} x{el.qty}
                            </div>
                          ))}
                        </td>
                        <td>
                          {v.customer_name} {v.customer_surname}
                        </td>
                        <td>
                          <ul>
                            {v.associatedCustomers.map(v => (
                              <li key={v.id}>
                                {v.name}{" "}
                                <BsTrash
                                  style={{ cursor: "pointer" }}
                                  size={14}
                                  className="text-danger"
                                  onClick={() =>
                                    handleRemoveAssociation(v.associationid)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          {v.phone_prefix}
                          {v.phone ? v.phone.replace(v.phone_prefix, "") : null}
                        </td>
                        <td>{new Date(v.booked_from).toLocaleDateString()}</td>
                        <td>{new Date(v.booked_to).toLocaleDateString()}</td>
                        <td>€{v.price}</td>
                        <td>€{v.price - deposit}</td>
                        <td>
                          €
                          {v.extras.reduce((acc, curr) => {
                            return acc + curr.price
                          }, 0)}
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => deleteBooking(v.bookingId)}
                          >
                            Elimina
                          </button>
                          <button
                            className="mx-2 btn btn-primary"
                            onClick={() => {
                              const bookings = v.deposits
                              setModalDeposits({
                                show: true,
                                data: { ...v, bookings: bookings },
                              })
                            }}
                          >
                            Acconti
                          </button>
                          {v.deposits.length == 0 && (
                            <a href={`edit?id=${v.bookingId}`}>
                              <button className="btn btn-secondary">
                                Modifica
                              </button>
                            </a>
                          )}
                          <Button
                            className="mx-1"
                            onClick={() => {
                              handleExtras(v.bookingId)
                            }}
                          >
                            Extra
                          </Button>
                          {hasLoan &&
                            (v.loaned == 0 ? (
                              <Button
                                onClick={() => handleLoanChange(v.bookingId, 1)}
                                className="m-1"
                                color="success"
                              >
                                Abilita subaffitto
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleLoanChange(v.bookingId, 0)}
                                className="m-1"
                                color="danger"
                              >
                                Annulla subaffitto
                              </Button>
                            ))}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}
