import React from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Container,
  Button,
  Table,
  Modal,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useState } from "react"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import { useEffect } from "react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Modals
import UmbrellaModal from "components/anagrafiche/ombrelloni/umbrellaModal"
import MultipleQR from "components/anagrafiche/ombrelloni/multipleQR"
import {
  deleteBookingAssociation,
  deleteUmbrellaFromBackend,
  getModules,
  getSectors,
  getUmbrellas,
  patchLoanBooking,
} from "helpers/fakebackend_helper"
import { BsTrash } from "react-icons/bs"
import { deleteBookingFromBackend } from "helpers/fakebackend_helper"
import { ToastContainer, toast } from "react-toastify"

const Ombrelloni = () => {
  const [hasLoan, setHasLoan] = useState(false)
  const loadLoanStatus = async () => {
    let data = await getModules()

    data = data.filter(v => v.fk_modules_id == 2)
    if (data.length > 0) {
      setHasLoan(true)
    }
  }
  useEffect(() => {
    loadLoanStatus()
  }, [])
  const handleLoanChange = async (id, status) => {
    console.log(id, status)
    const data = await patchLoanBooking({
      id: id,
      loaned: status,
    })
    if (data.status) {
      alert("Stato prenotazione modificato")
      setUmbrellas()
    }
  }
  const [searchField, setSearchField] = useState("")
  const [formattedData, setFormattedData] = useState([])
  const [isPlanner, setIsPlanner] = useState(true)
  const setUmbrellas = async () => {
    const response = await getUmbrellas()
    const onlyUmbrellas = response.filter(v => v.isUmbrella == 1)
    setUmbrellaNumbers(response.map(v => v.number))
    setData(onlyUmbrellas)
  }

  const checkIfUserHasPlanner = async () => {
    const data = await getModules()
    const planner = data.find(v => v.fk_modules_id === 1)
    planner ? setIsPlanner(true) : setIsPlanner(false)
  }

  useEffect(() => {
    checkIfUserHasPlanner()
    setUmbrellas()
    loadSectors()
  }, [])

  const handleDelete = async id => {
    const conf = confirm("Sei sicuro di eliminare questo record?")
    if (conf) {
      const { status } = await deleteUmbrellaFromBackend(id)
      if (status) {
        setUmbrellas()
        alert("Record eliminato")
      }
    }
  }
  const [umbrellaNumbers, setUmbrellaNumbers] = useState([])
  const [data, setData] = useState([])

  const [modalModify, setModalModify] = useState(false)
  const [modalQR, setModalQR] = useState({
    show: false,
    min: Math.max(...data.map(o => o.number + 1)),
  })
  const [checkedRecords, setCheckedRecords] = useState([])
  const [records, setRecords] = useState([])
  const [toRefresh, setToRefresh] = useState(false)
  const [sectors, setSectors] = useState([])

  const printSelected = () => {
    //get the qr codes of the selected umbrellas by its id
    const selectedUmbrellas = data.filter(v => checkedRecords.includes(v.id))
    const qrCodes = selectedUmbrellas.map(v => v.qrcode)
    const joined = qrCodes.filter(v => v != "empty").join("|")
    window.open(
      `https://dev2.dasporto.net/newcalls/selectedQrCodes.php?list=${joined}`
    )
  }
  useEffect(() => {
    if (searchField == "") {
      setFormattedData(data)
    } else {
      const formattedArray = formattedData.filter(
        v =>
          v.qrcode.toLowerCase().includes(searchField.toLowerCase()) ||
          v.number.toString().includes(searchField)
      )
      setFormattedData(formattedArray)
    }
  }, [data, searchField])

  const massCheck = op => {
    if (op == 1) {
      setCheckedRecords(() => data.map(v => v.id))
    } else {
      setCheckedRecords([])
    }
  }
  const handleRemoveAssociation = async id => {
    const conf = confirm("Sei sicuro di voler disassociare questo utente?")
    if (conf) {
      const data = await deleteBookingAssociation(id)
      if (data.status) {
        alert("Utente disassociato")
        setUmbrellas()
      }
    }
  }
  const changeBlockedStatus = id => {
    //handle axios call

    //axios call end
    setData(val => {
      const index = val.findIndex(item => item.id === id)
      let arr = val
      arr[index].blocked = !val[index].blocked
      return arr
    })
    setToRefresh(val => !val)
  }
  const loadSectors = async () => {
    const data = await getSectors()
    setSectors(data)
  }

  const handleDeleteBooking = async id => {
    const conf = confirm("Sei sicuro di voler eliminare questa prenotazione?")
    if (!conf) return

    const data = await deleteBookingFromBackend(id)
    if (data.status) {
      toast.success("Prenotazione eliminata")
    }
  }

  return (
    <>
      <ToastContainer />
      <MultipleQR
        updateData={() => setUmbrellas()}
        setVisibility={setModalQR}
        show={modalQR.show}
        min={modalQR.min}
      />
      <UmbrellaModal
        onHide={() => setModalModify(v => ({ ...v, show: false }))}
        show={modalModify.show}
        data={modalModify.data}
        updateData={() => setUmbrellas()}
        numbers={umbrellaNumbers}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Anagrafiche" breadcrumbItem="Ombrelloni" />
          <div className="d-flex mb-2 w-100">
            {!isPlanner && (
              <>
                <button
                  onClick={() => setModalModify({ data: false, show: true })}
                  className="btn btn-primary text-light"
                >
                  Nuovo ombrellone
                </button>
                <button
                  className="btn btn-primary text-light mx-2"
                  onClick={() =>
                    setModalQR(val => ({
                      show: true,
                      min: Math.max(...data.map(o => o.number + 1)),
                    }))
                  }
                >
                  Crea QR multipli
                </button>
              </>
            )}
            <button
              className="btn btn-primary text-light"
              onClick={() => printSelected()}
            >
              Stampa selezionati
            </button>
            <div style={{ marginLeft: "auto" }}>
              <input
                className="form-control"
                placeholder="Cerca per codice o numero"
                value={searchField}
                onChange={e => setSearchField(e.target.value)}
              />
            </div>
          </div>
          <Row>
            <Col>
              <div className="table-responsive">
                <Table className="table table-hover mb-0">
                  <thead className="bg-white">
                    <tr>
                      <th>
                        <div className="form-check d-flex w-100 h-100 justify-content-center align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={e =>
                              e.target.checked ? massCheck(1) : massCheck(0)
                            }
                          />
                        </div>
                      </th>
                      <th>Omberllone numero</th>
                      <th>Clienti associabili</th>
                      {!isPlanner && <th>Clienti Associati</th>}
                      {!isPlanner && <th>Codice prenotazione</th>}
                      <th>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formattedData.map((val, index) => {
                      const sectorIndex = sectors.findIndex(
                        v => v.id == val.sector
                      )
                      const sector =
                        sectorIndex !== -1 ? sectors[sectorIndex] : 0

                      //get the booking from bookings where booked_to is more than current date (booked_to is string)
                      const booking = val.bookings.find(v => {
                        const date = new Date(v.booked_to)
                        const now = new Date()
                        //now needs to be at the same hour/minutes/seconds of date

                        now.setHours(date.getHours())
                        return date >= now
                      })
                      console.log(booking)

                      return (
                        <tr key={index}>
                          <td>
                            <div className="form-check d-flex w-100 h-100 justify-content-center align-items-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                /* onChange={() => {}} */
                                checked={
                                  checkedRecords.findIndex(e => e == val.id) !==
                                  -1
                                }
                                onClick={e => {
                                  if (!e.target.checked) {
                                    setCheckedRecords(el => [...el, val.id])
                                  } else {
                                    setCheckedRecords(el =>
                                      el.filter(v => v !== val.id)
                                    )
                                  }
                                }}
                              />
                            </div>
                          </td>

                          <td>{val.number}</td>
                          <td>{val.max_customers}</td>
                          {!isPlanner && booking ? (
                            <>
                              <td>
                                <ul>
                                  {booking.associatedCustomers.map((v, i) => {
                                    return (
                                      <li key={i}>
                                        {v.name} {v.surname}{" "}
                                        <BsTrash
                                          style={{ cursor: "pointer" }}
                                          className="text-danger"
                                          size={16}
                                          onClick={() =>
                                            handleRemoveAssociation(
                                              v.associationid
                                            )
                                          }
                                        />
                                      </li>
                                    )
                                  })}
                                </ul>
                              </td>
                              <td>
                                {booking.short_code}{" "}
                                {/* {booking.loaned_from !== 0 && (
                                  <small
                                    style={{ backgroundColor: "#ffc252" }}
                                    className="p-1 rounded"
                                  >
                                    Subaffitto
                                  </small>
                                )} */}
                              </td>
                            </>
                          ) : (
                            !isPlanner && (
                              <>
                                <td></td>
                                <td></td>
                              </>
                            )
                          )}

                          <td className="d-flex">
                            <button
                              type="button"
                              className="btn btn-light btn-sm mx-1"
                              onClick={() =>
                                setModalModify({ data: val, show: true })
                              }
                            >
                              Modifica
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm mx-1"
                              onClick={() => handleDelete(val.id)}
                            >
                              Elimina
                            </button>
                            {!hasLoan && booking && (
                              <button
                                type="button"
                                className="btn btn-dark btn-sm mx-1"
                                onClick={() =>
                                  handleDeleteBooking(booking.bookingId)
                                }
                              >
                                Elimina prenotazione
                              </button>
                            )}
                            {!hasLoan &&
                              (booking && booking.loaned == 0 ? (
                                <Button
                                  onClick={() =>
                                    handleLoanChange(booking.bookingId, 1)
                                  }
                                  className="btn-sm"
                                  color="primary"
                                >
                                  Abilita subaffitto
                                </Button>
                              ) : (
                                booking && (
                                  <Button
                                    onClick={() => {
                                      console.log("prova ", val)
                                      handleLoanChange(booking.bookingId, 0)
                                    }}
                                    className="btn-sm"
                                    color="warning"
                                  >
                                    Annulla subaffitto
                                  </Button>
                                )
                              ))}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Ombrelloni
