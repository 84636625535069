import { Input, Modal, Row, Col, ModalBody } from "reactstrap"
import React, { useState, useEffect } from "react"

const Confirmbooking = props => {
  return (
    <Modal
      centered={true}
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header d-flex w-100 align-items-center justify-content-center">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Prenotazione confermata
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body w-100 h-100 d-flex flex-column align-items-center justify-content-center p-5">
        <h5>Vorresti inviare un email di conferma?</h5>
        <div className="d-flex w-50 justify-content-center">
          <button
            className="btn btn-secondary mx-1"
            onClick={() => props.onHide()}
          >
            Ignora
          </button>
          <button
            className="btn btn-primary mx-1"
            onClick={() => {
              console.log(props.email)
              props.onHide()
            }}
          >
            Invia
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default Confirmbooking
