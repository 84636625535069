import { appendExtras, deleteExtraBooking } from "helpers/fakebackend_helper"
import React, { useState } from "react"
import { useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap"

export default function ModalExtras({
  show,
  data,
  onHide,
  booking_id,
  refresh,
}) {
  const [pending, setPending] = useState(false)
  const [extras, setExtras] = useState([])

  useEffect(() => {
    if (data) {
      setExtras(data)
    }
  }, [data])
  const [formData, setFormData] = useState({
    description: "",
    price: "1.00",
    qty: 1,
    booking_id: false,
    notes: "",
  })
  useEffect(() => {
    if (booking_id) {
      setFormData({
        ...formData,
        booking_id: booking_id,
      })
    }
  }, [booking_id])

  const handleAppend = async () => {
    setPending(true)
    const response = await appendExtras(formData)
    if (response.status) {
      //reset formData
      setFormData({
        description: "",
        price: "1.00",
        qty: 1,
        booking_id: formData.booking_id,
        notes: "",
      })
      alert("Extra aggiunto")
      refresh()
    } else {
      alert("Errore durante l'aggiunta dell'extra")
    }
    setPending(false)
  }
  const handleDelete = async id => {
    const conf = confirm("Sei sicuro di voler eliminare questo extra?")
    if (!conf) return
    const response = await deleteExtraBooking(id)
    if (response.status) {
      alert("Extra eliminato")
      refresh()
    }
  }

  const handleModify = async () => {}
  console.log(extras)
  return (
    <Modal isOpen={show} toggle={onHide} size="xl">
      <ModalHeader toggle={onHide}>Extra prenotazione</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <label>Data</label>
          </Col>
          <Col>
            <label>Descrizione</label>
          </Col>
          <Col>
            <label>Quantità</label>
          </Col>
          <Col>
            <label>Totale</label>
          </Col>
          <Col>
            <label>Note</label>
          </Col>
          <Col>
            <label>Azioni</label>
          </Col>
        </Row>
        {extras &&
          extras.map((v, i) => (
            <Row key={i} className="m-1">
              <Col>{new Date(v.date).toLocaleDateString()}</Col>
              <Col>{v.description}</Col>
              <Col>{v.qty}</Col>
              <Col>€{v.price}</Col>
              <Col>{v.notes}</Col>
              <Col>
                <Button onClick={() => handleDelete(v.id)}>Elimina</Button>
              </Col>
            </Row>
          ))}
        <Row>
          <Col>
            <Input
              type="date"
              onChange={({ target }) =>
                setFormData(v => ({ ...v, date: target.value }))
              }
              value={formData.date}
            />
          </Col>
          <Col>
            <Input
              onChange={({ target }) =>
                setFormData(v => ({ ...v, description: target.value }))
              }
              value={formData.description}
            />
          </Col>

          <Col>
            <Input
              type="number"
              value={formData.qty}
              onChange={({ target }) =>
                setFormData(v => ({ ...v, qty: target.value }))
              }
            />
          </Col>
          <Col>
            <InputGroup>
              <Input
                type="number"
                value={formData.price}
                step={0.01}
                onChange={({ target }) =>
                  setFormData(v => ({ ...v, price: target.value }))
                }
              />
              <InputGroupText>€</InputGroupText>
            </InputGroup>
          </Col>
          <Col>
            <textarea
              className="form-control"
              value={formData.notes}
              onChange={e => {
                setFormData(v => ({ ...v, notes: e.target.value }))
              }}
            ></textarea>
          </Col>
          <Col>
            <Button disabled={pending} onClick={() => handleAppend()}>
              Aggiungi
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHide}>
          {" "}
          Chiudi{" "}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
