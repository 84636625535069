import { Input, Modal, Row, Col, Button } from "reactstrap"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import { patchBookingBackend } from "helpers/fakebackend_helper"

const Modalmodifybooking = props => {
  console.log(props)
  const [data, setData] = useState(false)
  const handleElementChange = (e, index) => {
    setData(v => {
      let elements = v.elements.map((elem, i) => {
        if (i === index) {
          return { ...elem, name: e.label, id: e.value }
        } else {
          return elem
        }
      })
      return { ...v, elements: elements }
    })
    /* console.log(e, index) */
  }
  const handleSubmit = async () => {
    const response = await patchBookingBackend(data)
    if (response.status) {
      props.updateData()
      props.onHide()
    }
  }
  const handleRemove = async index => {
    setData(v => {
      const elements = v.elements.filter((e, i) => i !== index)
      return {
        ...v,
        elements: elements,
      }
    })
    console.log(index)
  }
  const Newelement = () => {
    setData(v => {
      let elements = v.elements
      elements = [
        ...elements,
        {
          id: props.elements[0].id,
          name: props.elements[0].name,
          qty: 1,
          added: true,
        },
      ]
      return { ...v, elements: elements }
    })
  }
  useEffect(() => {
    setTimeout(() => {
      setData(props.data)
    }, 200)
  }, [JSON.stringify(props.data), props.show])
  return (
    <Modal
      size="md"
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Modifica prenotazione
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <label>Da</label>
            <Input
              type="date"
              value={data.booked_from}
              onChange={e =>
                setData(v => ({ ...v, booked_from: e.target.value }))
              }
            />
          </Col>
          <Col>
            <label>A</label>
            <Input
              type="date"
              value={data.booked_to}
              onChange={e =>
                setData(v => ({ ...v, booked_to: e.target.value }))
              }
            />
          </Col>
          <Col>
            <label>Prezzo</label>
            <div className="input-group">
              <div className="input-group-text">€</div>
              <Input
                type="number"
                value={data.price}
                onChange={e => setData(v => ({ ...v, price: e.target.value }))}
              ></Input>
            </div>
          </Col>
        </Row>
        <Row>
          {data.elements &&
            data.elements.map((ele, k) => (
              <Row key={k} className="mt-4">
                <Col xs={5}>
                  <Select
                    options={props.elements.map(v => ({
                      value: v.id,
                      label: v.name,
                    }))}
                    value={{ value: ele.id, label: ele.name }}
                    onChange={e => handleElementChange(e, k)}
                  />
                  {/* <Input disabled value={ele.name} /> */}
                </Col>
                <Col xs={4}>
                  <Input
                    value={ele.qty}
                    type={"number"}
                    min={0}
                    onChange={e =>
                      setData(v => {
                        let elements = v.elements
                        elements[k] = { ...elements[k], qty: e.target.value }
                        return { ...v, elements: elements }
                      })
                    }
                  />
                </Col>
                <Col>
                  <button
                    className=" btn-danger btn"
                    onClick={() => handleRemove(k)}
                  >
                    Elimina
                  </button>
                </Col>
              </Row>
            ))}
        </Row>
        <Button className="btn-success mt-2" onClick={Newelement}>
          Aggiungi
        </Button>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          type="button"
          className="btn btn-primary "
          onClick={handleSubmit}
        >
          Modifica
        </button>
        <button
          type="button"
          className="btn btn-info "
          onClick={() => setData(props.data)}
        >
          Ripristina
        </button>
      </div>
    </Modal>
  )
}

export default Modalmodifybooking
