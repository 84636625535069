import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
} from "reactstrap"
import { downloadExcel } from "react-export-table-to-excel"

import {
  retrieveEventAnswers,
  retrieveEvents,
  retrieveBarDashboard,
  getMostOrderedProducts,
  getProductPerTime,
  getCategories,
  retrieveCategories,
  retrieveBarCategories,
  loadProducts,
} from "helpers/fakebackend_helper"
import Chart from "react-apexcharts"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import Select from "react-select"

const Dashboard = () => {
  const [dashboardSearching, setDashboardSearching] = useState(false)
  const [barCategories, setBarCategories] = useState([])
  const [barProducts, setBarProducts] = useState([])
  const [filterBy, setFilterBy] = useState("categoria")
  const [dashboardPending, setDashboardPending] = useState(false)
  useEffect(() => {
    checkIfUserHasPurchasedModule(6)
    const fetchBarCategories = async () => {
      const barCategoriesFromServer = await retrieveBarCategories()
      setBarCategories(barCategoriesFromServer)
      console.log(barCategoriesFromServer)
    }
    const fetchBarProducts = async () => {
      const barProducts = await loadProducts()
      setBarProducts(barProducts.map(v => ({ value: v.id, label: v.name })))
    }
    fetchBarProducts()
    fetchBarCategories()
  }, [])
  const [maxBookedBar, setMaxBookedBar] = useState([])
  const [times, setTimes] = useState([])
  const fetchMaxBookedBar = async () => {
    const bookedBarFromServer = await getProductPerTime()
    setTimes(bookedBarFromServer.map(v => v.time))
    let ids = []
    bookedBarFromServer.forEach(v => {
      v.fields.forEach(c => {
        const foundId = ids.findIndex(e => c.id === e.id)
        if (foundId === -1) {
          ids.push({ id: c.id, name: c.name, times: [] })
        }
      })
    })

    bookedBarFromServer.forEach((v, coun) => {
      const rawIds = ids.map(v => v.id)
      v.fields.forEach(c => {
        ids.forEach((v, i) => {
          if (v.id == c.id) {
            ids[i].times[coun] = c.total_bookings
            rawIds.splice(rawIds.indexOf(c.id), 1)
          }
        })
      })
      rawIds.forEach(v => {
        ids.forEach((v, i) => {
          ids[i].times[coun] = 0
        })
      })
    })

    console.log(ids)
    setMaxBookedBar(ids)
  }
  const [mostBookedSearch, setMostBookedSearch] = useState("")
  const [mostBookedSearchResults, setMostBookedSearchResults] = useState([])
  const handleSearch = async () => {
    setDashboardSearching(true)
    const data = await getMostOrderedProducts({
      filter: filterBy,
      value: mostBookedSearch,
    })

    data.sort((a, b) => b.qty - a.qty)
    data.splice(20)

    setMostOrderedProducts(data)
    setDashboardSearching(false)
  }
  const handleReset = () => {
    setMostBookedSearch("")
    setMostBookedSearchResults(mostOrderedProducts)
  }

  const [range, setRange] = useState({
    start: null,
    end: null,
  })
  const [mostOrderedProducts, setMostOrderedProducts] = useState([])
  const [tableData, setTableData] = useState([])

  const generateColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
  }
  const loadDashboard = async () => {
    setDashboardPending(true)
    const data = await retrieveBarDashboard(range)

    setTableData(data)
    setDashboardPending(false)
  }
  const loadMostOrderedProducts = async () => {
    const data = await getMostOrderedProducts()
    data.sort((a, b) => b.qty - a.qty)
    data.splice(20)
    setMostOrderedProducts(data)
  }
  useEffect(() => {
    if (mostOrderedProducts.length > 0) {
      setMostBookedSearchResults(mostOrderedProducts)
    }
  }, [mostOrderedProducts])
  useEffect(() => {
    //in the first render, set the range to the last 7 days, make sure to add the leading zero if necessary
    const today = new Date()
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    )

    //set the range with the string standard compatible with the input type date
    setRange({
      start: `${lastWeek.getFullYear()}-${lastWeek.getMonth() + 1 < 10
        ? `0${lastWeek.getMonth() + 1}`
        : lastWeek.getMonth() + 1
        }-${lastWeek.getDate() < 10 ? `0${lastWeek.getDate()}` : lastWeek.getDate()
        }`,
      end: `${today.getFullYear()}-${today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
        }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`,
    })
    loadMostOrderedProducts()
    fetchMaxBookedBar()
  }, [])

  const totaleDrink = tableData.reduce(
    (acc, curr) => {

      let price
      //replace comma with dot
      if (curr.food_or_drink == "Food") return acc
      if (curr.price.includes(",")) {
        price = curr.price.replace(",", ".")
      } else {
        price = curr.price
      }
      acc += parseFloat(price) * curr.quantity
      return acc
    },
    0
  )

  const totaleFood = tableData.reduce(
    (acc, curr) => {

      let price
      //replace comma with dot
      if (curr.food_or_drink == "Drink") return acc
      if (curr.price.includes(",")) {
        price = curr.price.replace(",", ".")
      } else {
        price = curr.price
      }
      acc += parseFloat(price) * curr.quantity
      return acc
    },
    0
  )

  return (
    <div className="page-content">
      <h1>Dashboard</h1>
      <Row>
        <Col xs={6}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Risposte</h4>
              <Row>
                <Col xs={4}>
                  <label>Da</label>
                  <Input
                    value={range.start}
                    onChange={e => {
                      setRange({ ...range, start: e.target.value })
                    }}
                    type="date"
                  />
                </Col>
                <Col xs={4}>
                  <label>A</label>
                  <Input
                    value={range.end}
                    onChange={e => {
                      setRange({ ...range, end: e.target.value })
                    }}
                    type="date"
                  />
                </Col>
                <Col xs={2} className="d-flex flex-column">
                  <label>&nbsp;</label>
                  <Button color="primary" onClick={loadDashboard}>
                    Filtra
                  </Button>
                </Col>
              </Row>
              {dashboardPending ? (
                <div className="d-flex justify-content-center mt-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <Table className="mt-4 rounded overflow-hidden">
                    <thead>
                      <tr>
                        <th>Prodotto</th>
                        <th>Quantità</th>

                        <th>Prezzo unitario</th>
                        <th>Incasso per prodotto</th>
                        <th>Tipo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.name}</td>
                          <td>{data.quantity}</td>
                          <td>€{data.price}</td>
                          <td>€{data.total_price}</td>
                          <td>{data.food_or_drink}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col xs={4} style={{ textAlign: "center" }}>
                      <h3>Totale Food</h3>
                      <h4>
                        €
                        {totaleFood.toFixed(2)}
                      </h4>
                    </Col>
                    <Col xs={4} style={{ textAlign: "center" }}>
                      <h3>Totale Drink</h3>
                      <h4>
                        €
                        {totaleDrink.toFixed(2)}
                      </h4>
                    </Col>
                    <Col xs={4} style={{ textAlign: "center" }}>
                      <h3>Totale</h3>
                      <h4>
                        €
                        {tableData.reduce(
                          (acc, curr) => {
                            let price
                            //replace comma with dot
                            if (curr.price.includes(",")) {
                              price = curr.price.replace(",", ".")
                            } else {
                              price = curr.price
                            }
                            acc += parseFloat(price) * curr.quantity
                            return acc
                          },
                          0
                        ).toFixed(2)}
                      </h4>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xs={6}>
          <Card>
            <CardBody>
              <CardTitle>Prodotti più ordinati</CardTitle>
              <CardSubtitle>Prodotti più ordinati per ogni campo</CardSubtitle>
              <div className="mt-3 mb-3">
                <label>Cerca il tuo prodotto</label>
                <InputGroup>
                  <InputGroupText>Cerca per</InputGroupText>
                  <select
                    className="form-select"
                    value={filterBy}
                    onChange={e => setFilterBy(e.target.value)}
                  >
                    <option value="categoria">Categoria</option>
                    <option value="prodotto">Prodotto</option>
                  </select>
                  {/*                   <Input
                    value={mostBookedSearch}
                    placeholder={
                      filterBy == "categoria"
                        ? "Cerca categoria"
                        : "Cerca prodotto"
                    }
                    onChange={e => setMostBookedSearch(e.target.value)}
                  /> */}
                  {filterBy == "categoria" ? (
                    <select
                      className="form-select"
                      value={mostBookedSearch}
                      onChange={e => setMostBookedSearch(e.target.value)}
                    >
                      <option value="">Seleziona categoria</option>
                      {barCategories.map((v, i) => (
                        <option key={i} value={v.value}>
                          {v.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      value={mostBookedSearch}
                      onChange={e => setMostBookedSearch(e.target.value)}
                      className="form-select"
                    >
                      <option value="">Seleziona prodotto</option>
                      {barProducts.map((v, i) => (
                        <option key={i} value={v.value}>
                          {v.label}
                        </option>
                      ))}
                    </select>
                  )}
                  <Button
                    color="primary"
                    onClick={handleSearch}
                    disabled={dashboardSearching}
                  >
                    {dashboardSearching ? "Caricando..." : "Cerca"}
                  </Button>
                  <Button color="warning" onClick={handleReset}>
                    Reset
                  </Button>
                </InputGroup>
              </div>
              {mostBookedSearchResults.length > 0 ? (
                <Chart
                  options={{
                    labels:
                      mostBookedSearchResults.length > 0
                        ? mostBookedSearchResults.map(v =>
                          v.name !== null ? v.name : "Nessun nome"
                        )
                        : ["Nessun prodotto"],
                    legend: {
                      /* show: false, */
                    },
                    colors: [
                      "#008FFB",
                      "#00E396",
                      "#FEB019",
                      "#FF4560",
                      "#775DD0",
                      "#546E7A",
                      "#26a69a",
                      "#D10CE8",
                      "#DC3545",
                      "#FFC107",
                      "#17a2b8",
                      "#6610f2",
                      "#6f42c1",
                      "#e83e8c",
                      "#20c997",
                      "#fd7e14",
                      "#007bff",
                      "#28a745",
                      "#343a40",
                      "#dc3545",
                      "#f8f9fa",
                      "#6c757d",
                      "#ffc107",
                      "#198754",
                      "#0dcaf0",
                      "#6610f2",
                      "#d63384",
                      "#20c997",
                      "#343a40",
                      "#6f42c1",
                      "#e83e8c",
                      "#fd7e14",
                      "#007bff",
                      "#28a745",
                      "#dc3545",
                      "#17a2b8",
                      "#ffc107",
                      "#198754",
                      "#6610f2",
                      "#6c757d",
                      "#0dcaf0",
                      "#343a40",
                      "#e83e8c",
                      "#fd7e14",
                      "#20c997",
                      "#007bff",
                      "#28a745",
                      "#d63384",
                      "#6610f2",
                      "#17a2b8",
                      "#dc3545",
                    ],
                  }}
                  series={
                    mostBookedSearchResults.length > 0
                      ? mostBookedSearchResults.map(v => v.qty)
                      : [2]
                  }
                  type="donut"
                />
              ) : (
                <p>Nessun risultato</p>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle>Orari con più ordini</CardTitle>
              <CardSubtitle>
                Gli orari con più ordini della stagione corrente
              </CardSubtitle>
              <Chart
                options={{
                  xaxis: {
                    categories: times,
                  },
                  stroke: {
                    curve: "smooth",
                  },
                }}
                series={maxBookedBar.map(v => ({
                  name: v.name,
                  data: v.times,
                }))}
                type="area"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard
