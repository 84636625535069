import React, { useState } from "react"
import { Col, InputGroup, InputGroupText, Row } from "reactstrap"
import { Input, Button } from "reactstrap"
import { BiDrink } from "react-icons/bi"
import { GiKnifeFork } from "react-icons/gi"
import { useEffect } from "react"
import { v4 } from "uuid"
import {
  deleteCategory,
  patchOrPutSubCategory,
  putorPatchProduct,
  updateCategoryOrdering,
} from "helpers/fakebackend_helper"
import { BsArrowDown, BsArrowUp } from "react-icons/bs"

export default function Modalrow({ category, refresh }) {
  const [enabled, setEnabled] = useState(false)
  const [data, setData] = useState(category)
  const [selectedTimeslot, setSelectedTimeslot] = useState(false)
  const getDay = day => {
    switch (parseInt(day)) {
      case 1:
        return "Lunedì"
        break
      case 2:
        return "Martedì"
        break
      case 3:
        return "Mercoledì"
        break
      case 4:
        return "Giovedì"
        break
      case 5:
        return "Venerdì"
        break
      case 6:
        return "Sabato"
        break
      case 7:
        return "Domenica"
        break
    }
  }
  useEffect(() => {
    if (data) {
      let v = data
      const tms = v.timeslots.map(el => ({
        ...el,
        to_delete: false,
        to_add: false,
      }))
      setData(el => ({ ...el, timeslots: tms }))
    }
  }, [data.id, data.timeslots.length, enabled])
  useEffect(() => {
    console.log(data)
  }, [data])
  const handleDelete = async id => {
    //confirm the user's action
    const conf = confirm("Sei sicuro di eliminare questa categoria?")
    if (conf) {
      console.log(id)
      const data = await deleteCategory(id)
      if (data.status) {
        alert("Categoria eliminata")
        refresh()
      } else {
        alert("Errore")
      }
    }
  }

  const handleMovement = async (operation, id) => {
    const data = {
      operation: operation,
      id: id,
    }
    const response = await updateCategoryOrdering(data)
    if (response.status) {
      refresh()
    }
  }

  const handleSubmit = async () => {
    const request = {
      ...data,
      timeslots: data.timeslots.filter(v => v.to_delete == false),
    }

    const rep = await patchOrPutSubCategory(request)
    if (rep.status) {
      alert("Categoria aggiornata correttamente")
      setEnabled(false)
      refresh()
    }
  }
  useEffect(() => {
    //reset timeslot when change category
    setSelectedTimeslot(false)
  }, [data.id])
  useEffect(() => {
    if (data.timeslots.length > 0) {
      setSelectedTimeslot(data.timeslots[data.timeslots.length - 1])
    }
  }, [data.timeslots.length])
  return (
    <div className={` rounded  py-3 border-bottom`}>
      <Row key={category.id}>
        <Col xs={4}>
          <div className="d-flex">
            <Button
              color="success"
              className="mx-1"
              onClick={() => handleMovement(1, category.id)}
            >
              <BsArrowUp />
            </Button>
            <Button color="warning" onClick={() => handleMovement(0, category.id)}>
              <BsArrowDown />
            </Button>
            <Input
            className="mx-1"
              onChange={e => setData(v => ({ ...v, name: e.target.value }))}
              value={data.name}
              disabled={!enabled}
            />
          </div>
        </Col>
        <Col xs={1}>
          <BiDrink
            size={24}
            style={{ opacity: enabled ? 1 : 0.5 }}
            className={`${data.food_or_drink == "drink" && "text-danger"}`}
            onClick={() =>
              enabled && setData(v => ({ ...v, food_or_drink: "drink" }))
            }
          />
          <GiKnifeFork
            size={24}
            style={{ opacity: enabled ? 1 : 0.5 }}
            className={`${data.food_or_drink == "food" && "text-danger"}`}
            onClick={() =>
              enabled && setData(v => ({ ...v, food_or_drink: "food" }))
            }
          />
        </Col>
        <Col xs={2}>
          <InputGroup>
            <Input
              type="number"
              onChange={e =>
                setData(v => ({ ...v, preparation_time: e.target.value }))
              }
              value={data.preparation_time}
              disabled={!enabled}
            />
            <InputGroupText>Min.</InputGroupText>
          </InputGroup>
        </Col>
        <Col xs={2}>
          <Input
            onChange={e =>
              setData(v => ({ ...v, day_quantity: e.target.value }))
            }
            value={data.day_quantity}
            disabled={!enabled || !data.day_quantity}
            type="number"
          />
          <input
            type="checkbox"
            className="form-check-input"
            checked={!data.day_quantity}
            disabled={!enabled}
            onClick={e =>
              e.target.checked
                ? setData(v => ({ ...v, day_quantity: 1 }))
                : setData(v => ({ ...v, day_quantity: 0 }))
            }
          />
          <span className="mx-1">NON DEFINITA</span>
        </Col>
        <Col xs={3}>
          {!enabled ? (
            <>
              <Button onClick={() => setEnabled(true)}>Modifica</Button>
              <Button
                className="mx-1"
                color="danger"
                onClick={() => handleDelete(category.id)}
              >
                Elimina
              </Button>
            </>
          ) : (
            <>
              <Button
                className="m-1 w-100"
                onClick={() => {
                  setEnabled(false)
                  setData(category)
                  setSelectedTimeslot(false)
                }}
              >
                Annulla
              </Button>
              <Button
                className="m-1 w-100"
                onClick={() => handleSubmit()}
                color="primary"
              >
                Salva
              </Button>
            </>
          )}
        </Col>
      </Row>
      {data.timeslots && data.timeslots.length == 0 && enabled && (
        <Button
          onClick={() =>
            setData(v => {
              const timeslots = v.timeslots
              timeslots.push({
                to_delete: false,
                to_add: true,
                id: v4(),
                time_from: "00:00",
                time_to: "00:00",
                day: 1,
              })
              return { ...v, timeslots }
            })
          }
        >
          Aggiungi timeslot
        </Button>
      )}
      {data.timeslots && data.timeslots.length > 0 && enabled && (
        <>
          <Row className="mt-4">
            <Col xs={4}>Giorno della settimana</Col>
            <Col>Dalle</Col>
            <Col>Alle</Col>
            <Col xs={4}>Azioni</Col>
          </Row>

          {data.timeslots
            .filter(v => v.to_delete == false)
            .map((v, i) => {
              return (
                <Row key={i} className="mb-4">
                  <Col xs={4}>
                    <select
                      className={`form-select ${
                        category.timeslots[i] &&
                        v.day == category.timeslots[i].day
                          ? ""
                          : "bg-warning "
                      } `}
                      value={v.day}
                      onChange={e =>
                        setData(v => {
                          const timeslots = v.timeslots
                          if (i !== -1) {
                            timeslots[i].day = parseInt(e.target.value)
                          }
                          return { ...v, timeslots }
                        })
                      }
                    >
                      <option value={1}>Lunedì</option>
                      <option value={2}>Martedì</option>
                      <option value={3}>Mercoledì</option>
                      <option value={4}>Giovedì</option>
                      <option value={5}>Venerdì</option>
                      <option value={6}>Sabato</option>
                      <option value={7}>Domenica</option>
                    </select>
                  </Col>
                  <Col xs={2}>
                    <Input
                      type="time"
                      value={v.time_from}
                      onChange={({ target }) =>
                        setData(v => {
                          const timeslots = v.timeslots
                          if (i !== -1) {
                            timeslots[i].time_from = target.value
                          }
                          return { ...v, timeslots }
                        })
                      }
                    />
                  </Col>
                  <Col xs={2}>
                    <Input
                      type="time"
                      value={v.time_to}
                      onChange={({ target }) =>
                        setData(v => {
                          const timeslots = v.timeslots
                          if (i !== -1) {
                            timeslots[i].time_to = target.value
                          }
                          return { ...v, timeslots }
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      color="danger"
                      onClick={() => {
                        setData(prevData => {
                          const timeslots = prevData.timeslots.filter(
                            (timeslot, index) => index !== i
                          )
                          return { ...prevData, timeslots }
                        })
                      }}
                    >
                      Elimina
                    </Button>
                    {i ==
                      data.timeslots.filter(v => v.to_delete == false).length -
                        1 && (
                      <Button
                        color="primary"
                        className="mx-1"
                        onClick={() =>
                          setData(v => {
                            const timeslots = v.timeslots
                            timeslots.push({
                              to_delete: false,
                              to_add: true,
                              id: v4(),
                              time_from: "00:00",
                              time_to: "00:00",
                              day: 1,
                            })
                            return { ...v, timeslots }
                          })
                        }
                      >
                        Aggiungi
                      </Button>
                    )}
                  </Col>
                </Row>
              )
            })}
        </>
      )}
    </div>
  )
}
