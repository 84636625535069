import {
  loadSubCategories,
  patchOrPutSubCategory,
} from "helpers/fakebackend_helper"
import { useEffect, useState } from "react"
import React from "react"
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { v4 } from "uuid"
import { BiDrink } from "react-icons/bi"
import { GiKnifeFork } from "react-icons/gi"
import { BsPlus } from "react-icons/bs"
import Modalrow from "./Modalrow"
const Modalcategories = ({
  show,
  onHide,
  newcategory,
  updateCategory,
  refresh,
}) => {
  const [categories, setCategories] = useState([])
  const [selectedRecord, setSelectedRecord] = useState({ timeslots: [] })
  const [selectedTimeslot, setSelectedTimeslot] = useState(false)
  const [toUpdate, setToUpdate] = useState(false)
  const getCategories = async () => {
    const data = await loadSubCategories()

    setCategories(data)
  }
  useEffect(() => {
    getCategories()
  }, [toUpdate, updateCategory])

  const handleSubmit = async () => {
    setSelectedRecord(v => {
      const arr = v.timeslots
      arr.push(selectedTimeslot)
      return { ...v, timeslots: arr }
    })
    //put the selected timeslot in the request object, which starts from selectedRecord
    const request = {
      ...selectedRecord,
      timeslots: selectedRecord.timeslots.map(v => {
        if (v.id === selectedTimeslot.id) {
          return selectedTimeslot
        }
        return v
      }),
    }
    const data = await patchOrPutSubCategory(request)
    if (data.status) {
      alert("Categoria aggiornata correttamente")
      setSelectedRecord({ timeslots: [] })
      /* onHide() */
      getCategories()
    }
  }
  useEffect(() => {
    //reset timeslot when change category
    setSelectedTimeslot(false)
  }, [selectedRecord.id])
  useEffect(() => {
    if (selectedRecord.timeslots.length > 0) {
      setSelectedTimeslot(
        selectedRecord.timeslots[selectedRecord.timeslots.length - 1]
      )
    }
  }, [JSON.stringify(selectedRecord.timeslots)])
  return (
    <Modal toggle={onHide} isOpen={show} size="xl">
      <ModalHeader closeButton>
        <h3>Categorie</h3>
      </ModalHeader>
      <ModalBody>
        <Button color="dark" onClick={() => newcategory(true)}>
          Aggiungi
        </Button>
        <div>
          {selectedRecord.id && (
            <>
              <Row>
                <Col>
                  <label>Nome categoria</label>
                  <Input
                    value={selectedRecord.name}
                    onChange={e =>
                      setSelectedRecord(v => ({ ...v, name: e.target.value }))
                    }
                  />
                </Col>
                <Col>
                  <label>Tipo</label>
                  <select
                    className="form-select"
                    value={selectedRecord.food_or_drink}
                    onChange={e =>
                      setSelectedRecord(v => ({
                        ...v,
                        food_or_drink: e.target.value,
                      }))
                    }
                  >
                    <option value={"drink"}>Drink</option>
                    <option value={"food"}>Cibo</option>
                  </select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label>Tempo di preparazione</label>
                  <InputGroup>
                    <Input
                      type="number"
                      value={selectedRecord.preparation_time}
                      onChange={({ target }) =>
                        setSelectedRecord(v => ({
                          ...v,
                          preparation_time: target.value,
                        }))
                      }
                    />
                    <InputGroupText>Minuti</InputGroupText>
                  </InputGroup>
                </Col>
                <Col>
                  <label>Qta. giornaliera (0 se NON DEFINITA)</label>
                  <Input
                    type="number"
                    value={selectedRecord.day_quantity || 0}
                    onChange={({ target }) =>
                      setSelectedRecord(v => ({
                        ...v,
                        day_quantity: target.value,
                      }))
                    }
                  />
                </Col>
                <Col>
                  <label>Ordine stampa</label>

                  <Input
                    type="number"
                    value={selectedRecord.print_ordering || 0}
                    onChange={({ target }) =>
                      setSelectedRecord(v => ({
                        ...v,
                        print_ordering: target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
              <h4 className="mt-3 mb-3">Orari apertura categoria</h4>

              <div className="d-flex justify-content-end">
                <Button onClick={handleSubmit} className="mt-2" color="primary">
                  Salva
                </Button>
              </div>
            </>
          )}
          {!selectedRecord && (
            <p className="text-muted fst-italic">
              la categoria selezionata comparirà qui
            </p>
          )}
        </div>
        <Row>
          <Col xs={4}>
            <strong>Categoria</strong>
          </Col>
          <Col xs={1}>
            <strong>Tipo</strong>
          </Col>
          <Col xs={2}>
            <strong>Tempo preparazione</strong>
          </Col>
          <Col xs={2}>
            <strong>Quantità giornaliera</strong>
          </Col>
          <Col xs={3}>
            <strong>Azioni</strong>
          </Col>
        </Row>

        {/*             <tr>
              <th>Categoria</th>
              <th>Tipo</th>
              <th>Tempo preparazione</th>
              <th>Quantità giornaliera</th>
              <th>Azioni</th>
            </tr> */}

        {categories.map(category => (
          <Modalrow
            refresh={() => setToUpdate(v => !v)}
            key={category.id}
            category={category}
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onHide}>
          Chiudi
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Modalcategories
