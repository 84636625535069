import { takeEvery, put, call } from "redux-saga/effects"

import { GET_ANAGRAFICA, GET_PAYMENT_OPTIONS, GET_TAGS } from "./actionTypes"
import {
  getAnagraficaSuccess,
  getAnagraficaFail,
  getTagsSuccess,
  getTagsFail,
  getPaymentOptionsSuccess,
  getPaymentOptionsFail,
} from "./actions"

import {
  getAnagrafica,
  getTags,
  getPaymentOptions,
} from "../../../helpers/fakebackend_helper"

function* fetchAnagrafica({payload: id}) {
  try {
    const response = yield call(getAnagrafica, id)
    yield put(getAnagraficaSuccess(response))
  } catch (error) {
    yield put(getAnagraficaFail(error))
  }
}
function* fetchTags() {
  try {
    const response = yield call(getTags)
    yield put(getTagsSuccess(response))
  } catch (error) {
    yield put(getTagsFail(error))
  }
}
function* fetchPaymentOptions() {
  try {
    const response = yield call(getPaymentOptions)
    yield put(getPaymentOptionsSuccess(response))
  } catch (error) {
    yield put(getPaymentOptionsFail(error))
  }
}

function* AnagraficaSaga() {
  yield takeEvery(GET_ANAGRAFICA, fetchAnagrafica)
  yield takeEvery(GET_TAGS, fetchTags)
  yield takeEvery(GET_PAYMENT_OPTIONS, fetchPaymentOptions)
}
export default AnagraficaSaga
