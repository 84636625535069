const singleQuestions = [
  {
    question: "Qual è il tuo nome?",
    type: "text",
  },
  {
    question: "Qual è il tuo cognome?",
    type: "text",
  },
  {
    question: "Qual è il tuo numero di telefono?",
    type: "text",
  },
  {
    question: "Qual è la tua età?",
    type: "number",
  },
  {
    question: "Aggiungi ulteriori note",
    type: "text",
  },
]

const groupQuestions = [
  {
    question: "Nome squadra",
    type: "text",
    indexing: 1,
  },
  {
    question: "Nome e cognome componente",
    type: "text",
    indexing: 2,
  },
  {
    question: "Recapito telefonico",
    type: "text",
    indexing: 3,
  },
  {
    question: "Codice Fiscale componente",
    type: "text",
    indexing: 4,
  },
  {
    question: "Aggiungi ulteriori note",
    type: "text",
    indexing: 5,
  },
]

export { groupQuestions, singleQuestions }
