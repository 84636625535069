import Editsector from "components/anagrafiche/sectors/EditSector"
import NewSector from "components/anagrafiche/sectors/newSector"
import { deleteSector, getSectors } from "helpers/fakebackend_helper"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import { React, useEffect, useState } from "react"
import { Button, Container, Table } from "reactstrap"

const Sectors = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])
  const [newModal, setNewModal] = useState(false)
  const [editModal, setEditModal] = useState({
    show: false,
    data: {},
  })
  const [sectors, setSectors] = useState([])
  const loadSectors = async () => {
    const data = await getSectors()
    setSectors(data)
  }
  useEffect(() => {
    loadSectors()
  }, [])
  const callDeleteSector = async id => {
    const data = await deleteSector(id)
    if (data.status) {
      loadSectors()
    }
  }
  return (
    <>
      <NewSector
        show={newModal}
        onHide={() => setNewModal(false)}
        updateData={() => loadSectors()}
      />
      <Editsector
        show={editModal.show}
        onHide={() => setEditModal(v => ({ ...v, show: false }))}
        data={editModal.data}
        updateData={() => loadSectors()}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Button onClick={() => setNewModal(true)}>Nuovo settore</Button>
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {sectors.length > 0
                ? sectors.map((v, i) => (
                    <tr key={i}>
                      <td>{v.name}</td>
                      <td>
                        <button
                          className="btn btn-primary m-1"
                          onClick={e => {
                            const data = sectors.filter(el => el.id == v.id)
                            setEditModal({ show: true, data: data[0] })
                          }}
                        >
                          Modifica
                        </button>
                        <button
                          className="btn btn-danger m-1"
                          onClick={() => callDeleteSector(v.id)}
                        >
                          Elimina
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  )
}

export default Sectors
