import React, { useEffect, useRef } from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Container,
  Button,
  Table,
  Modal,
  Input,
  InputGroup,
  InputGroupText,
  Toast,
  ToastBody,
  Badge,
} from "reactstrap"
import { useState } from "react"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import {
  getActivityDates,
  getCabins,
  getElements,
  getFictionalCustomers,
  getPriceUmbrellas,
  getSeasons,
  getUmbrellas,
  putBooking,
  shouldSendAlert,
} from "helpers/fakebackend_helper"

//Import Breadcrumb
import Whatnext from "components/planner/Whatnext"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreatableSelect from "react-select/creatable"
import Select from "react-select"
import moment from "moment"
import Confirmbooking from "components/planner/confirmBooking"
import phonePrefixes from "components/prefixes"
import { ToastContainer, toast } from "react-toastify"
import { BiQuestionMark } from "react-icons/bi"
import { BsFillQuestionCircleFill } from "react-icons/bs"

const New = () => {
  const [isSeasonal, setIsSeasonal] = useState(false)
  const [daysInLoan, setDaysInLoan] = useState({ visible: false, days: 0 })
  const [warnings, setWarnings] = useState([])
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const [phonePrefix, setPhonePrefix] = useState("+39")
  const [recommendedPrice, setRecommendedPrice] = useState(0)
  const [hasLoadedUmbrellas, setHasLoadedUmbrellas] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [fictionalCustomers, setFictionalCustomers] = useState([])
  const [differenceInDays, setDifferenceInDays] = useState(1)
  const [formData, setFormData] = useState({
    sponsor: { label: "Nessuno", value: 0 },
    clientType: 2,
    cabins: [],
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
    umbrellaId: 0,
    name: "",
    surname: "",
    phone: "",
    email: "",
    sponsorName: "",
    companyName: "",
    address: "",
    nation: "",
    province: "",
    zip: "",
    vat: "",
    bookingType: 3,
    fc: "",
    certfiedEmail: "",
    sdi: "",
    customers: 8,
    price: 0,
    discount: 0,
  })
  const [whatnext, setWhatnext] = useState(false)
  const [elementsData, setElementsData] = useState([])
  const [umbrellas, setUmbrellas] = useState([])
  const [cabins, setCabins] = useState([])
  const [elements, setElements] = useState([])
  const [filteredUmbrellas, setFilteredUmberllas] = useState([])

  const loadFictionalCustomers = async () => {
    const data = await getFictionalCustomers()
    setFictionalCustomers(data)
  }
  const loadUmbrellas = async id => {
    const val = await getUmbrellas(id)
    setUmbrellas(val)
    if (val.length == 0) {
      alert("Devi prima inserire un ombrellone")
      window.location.href = "/anagrafiche/ombrelloni"
    }
    setHasLoadedUmbrellas(true)
  }
  const loadElements = async id => {
    const val = await getElements(id)
    if (val.length == 0) {
      alert("Inserisci prima un elemento")
      window.location.href = "/anagrafiche/types"
    }
    setElements(val)
  }
  const loadCabins = async id => {
    const val = await getCabins(id)
    const resp = val.map(e => ({ label: e.number, value: e.id }))
    setCabins(resp)
  }
  const handleInputChange = (e, i) => {
    console.log(parseInt(e.target.value))
    let updatedElementsData = [...elementsData]
    updatedElementsData[i] = e.target.value
    console.log(updatedElementsData)
    setElementsData(updatedElementsData)
  }
  const handleSubmit = async () => {
    if (formData.umbrellaId == 0 && formData.cabins.length == 0) {
      return alert("Non hai selzionato alcun ombrellone o cabina")
    }
    if (
      formData.name == "" ||
      formData.surname == "" ||
      formData.phone == "" ||
      phonePrefix == ""
    ) {
      return alert("Inserisci i dati anagrafici")
    }

    if (recommendedPrice == 0) {
      const conf = confirm(
        "Sei sicuro di voler procedere? Non hai calcolato il prezzo"
      )
      if (!conf) return
    }
    const formattedElems = elementsData.map((v, i) => ({
      qty: v,
      id: elements[i].id,
    }))
    const req = {
      ...formData,
      elements: formattedElems,
      sponsor: formData.sponsor.value,
      phone: `${formData.phone.value}`,
      fk_places_id: localStorage.getItem("authUser"),
      phone_prefix: phonePrefix,
    }
    setPendingSubmit(true)
    const response = await putBooking(req)
    if (response.status) {
      setConfirmationModal({ show: true, email: formData.email })
    }
    setPendingSubmit(false)
    setFormData({
      sponsor: { label: "Nessuno", value: 0 },
      clientType: 2,
      cabins: [],
      from: moment(new Date()).format("YYYY-MM-DD"),
      to: moment(new Date()).format("YYYY-MM-DD"),
      umbrellaId: 0,
      name: "",
      surname: "",
      phone: "",
      email: "",
      sponsorName: "",
      companyName: "",
      address: "",
      nation: "",
      province: "",
      zip: "",
      vat: "",
      bookingType: 3,
      fc: "",
      certfiedEmail: "",
      sdi: "",
      customers: 1,
      price: 0,
    })
  }
  useEffect(() => {
    const formattedElems = elements.map(v => ({ qty: 0, id: v.id }))
    setFormData(v => ({ ...v, elements: formattedElems }))
  }, [elements])
  const calculatePrices = async () => {
    if (
      (formData.umbrellaId == 0 || formData.umbrellaId === null) &&
      formData.cabins.length == 0
    ) {
      return alert("Inserisci prima un ombrellone o una cabina")
    }
    const formattedElems = elementsData.map((v, i) => ({
      qty: v,
      id: elements[i].id,
    }))

    const req = {
      ...formData,
      isSeasonal: isSeasonal,
      elements: formattedElems,
      sponsor: formData.sponsor.value,
      phone: `${formData.phone.value}`,
      displacements: [...formData.cabins, formData.umbrellaId.value],
      fk_places_id: localStorage.getItem("authUser"),
    }
    const response = await getPriceUmbrellas(req)
    if (!response.status) return alert("Errore")

    setRecommendedPrice(response.total)
    console.log(response.warnings)
    response.warnings.forEach(v => {
      toast.warning(v)
    })
  }
  useEffect(() => {
    if (warnings.length > 0) {
      setTimeout(() => setWarnings([]), 5000)
    }
  }, [warnings.length])
  //Da monitorare
  useEffect(() => {
    const { from, to } = formData
    //create an array of DATES starting from to to, store only string
    const dates = []
    const date = new Date(from)
    while (date <= new Date(to)) {
      dates.push(date.toISOString().substring(0, 10))
      date.setDate(date.getDate() + 1)
    }

    const umbrellasWithBusyDates = umbrellas.map(v => {
      //get the id
      const id = v.id
      //cycle all the bookings
      const busyDates = []
      v.bookings.forEach(el => {
        console.log(el)
        //store all the dates in string, starting form booking from to booking to
        const date = new Date(el.booked_from)
        while (date <= new Date(el.booked_to)) {
          busyDates.push(date.toISOString().substring(0, 10))
          date.setDate(date.getDate() + 1)
        }
      })
      //now recyclce again in each bookings, and remove if they exists the date ranges in loaning_times (date_from and date_to) from busyDates
      v.bookings.forEach(el =>
        el.loaning_times.forEach(loaning => {
          const date = new Date(loaning.date_from)
          while (date <= new Date(loaning.date_to)) {
            const index = busyDates.findIndex(
              v => v == date.toISOString().substring(0, 10)
            )

            if (index !== -1) {
              busyDates.splice(index, 1)
            }
            date.setDate(date.getDate() + 1)
          }
        })
      )
      return { id, busyDates }
    })

    //now the filteredUmbrellas should be the umbrellas that have NO dates in common with the dates array
    const filtered = umbrellasWithBusyDates.filter(v => {
      const hasCommon = dates.some(r => v.busyDates.includes(r))
      return !hasCommon
    })

    setFilteredUmberllas(
      umbrellas.filter(v => filtered.some(r => r.id == v.id))
    )
  }, [formData.from, formData.to])

  const checkPricelistConfiguration = async () => {
    const shouldSend = await shouldSendAlert()
    if (shouldSend) {
    } else {
      const handleWindowBeforeUnload = event => {
        event.preventDefault()
        event.returnValue = ""
      }

      window.addEventListener("beforeunload", handleWindowBeforeUnload)

      return () => {
        window.removeEventListener("beforeunload", handleWindowBeforeUnload)
      }
    }
  }
  const setSeasonal = async bool => {
    if (bool === true) {
      const data = await getActivityDates()
      const from = new Date(data.from).toISOString().substring(0, 10)
      const to = new Date(data.to).toISOString().substring(0, 10)
      setFormData(v => ({ ...v, from: from, to: to }))
      setIsSeasonal(true)
    } else {
      setIsSeasonal(false)
    }
  }
  const handleChange = newValue => {
    const i = fictionalCustomers.findIndex(v => v.phone === newValue.value)
    if (i !== -1) {
      const foundValue = fictionalCustomers[i]

      setFormData(v => ({
        ...v,
        clientType: foundValue.clientType,
        name: foundValue.name,
        surname: foundValue.surname,
        email: foundValue.email,
        companyName: foundValue.companyName,
        address: foundValue.address,
        nation: foundValue.nation,
        province: foundValue.province,
        zip: foundValue.zip,
        vat: foundValue.vat,
        fc: foundValue.fc,
        certfiedEmail: foundValue.certfiedEmail,
        sdi: foundValue.sdi,
        phone: newValue,
      }))
    } else {
      setFormData(v => ({ ...v, phone: newValue }))
    }
  }

  const handleCreate = inputValue => {
    const newOption = {
      value: inputValue.toLowerCase(),
      label: inputValue,
    }
    setSelectedOption(newOption)
    // you can also add the new option to the options array if you want
  }
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    email: "",
  })

  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach((v, i) => {
        setElementsData(el => {
          console.log(v)
          let arr = el
          arr[i] = v.fk_default_elements_id == 2 ? 2 : 0
          return arr
        })
      })
    }
  }, [elements])
  useEffect(() => {
    selectedOption ? setFormData(v => ({ ...v, phone: selectedOption })) : null
  }, [selectedOption])

  useEffect(() => {
    if (formData.umbrellaId && formData.umbrellaId.value) {
      const umbrella = umbrellas.find(v => v.id == formData.umbrellaId.value)
      //get the loaning_times of each booking o the umbrella
      const loaningTimes = umbrella.bookings.map(v => v.loaning_times).flat()
      const datesInLoan = []
      loaningTimes.forEach(v => {
        const date = new Date(v.date_from)
        while (date <= new Date(v.date_to)) {
          datesInLoan.push(date.toISOString().substring(0, 10))
          date.setDate(date.getDate() + 1)
        }
      })
      const from = new Date(formData.from)
      const to = new Date(formData.to)
      const dates = []
      while (from <= to) {
        dates.push(from.toISOString().substring(0, 10))
        from.setDate(from.getDate() + 1)
      }

      console.log(datesInLoan)
      const daysInLoan = dates.filter(v => datesInLoan.includes(v))

      if (daysInLoan.length > 0) {
        setDaysInLoan({ visible: true, days: daysInLoan.length })
      } else {
        setDaysInLoan({ visible: false, days: daysInLoan.length })
      }
    } else {
      setDaysInLoan({ visible: false, days: 0 })
    }
  }, [formData.umbrellaId, formData.from, formData.to])

  useEffect(() => {
    const userPlace = localStorage.getItem("authUser")
    loadUmbrellas(userPlace)
    loadElements(userPlace)
    loadCabins(userPlace)
    loadFictionalCustomers()
  }, [])
  useEffect(() => {
    const diffInMs = Math.abs(new Date(formData.to) - new Date(formData.from))
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
    setDifferenceInDays(diffInDays + 1)
  }, [formData.to, formData.from])

  useEffect(() => {
    setFormData(v => ({ ...v, price: recommendedPrice }))
  }, [recommendedPrice])

  useEffect(() => {
    checkPricelistConfiguration()
  }, [])
  useEffect(() => {
    setFormData(e => ({
      ...e,
      companyName: "",
      address: "",
      nation: "",
      province: "",
      zip: "",
      vat: "",
      fc: "",
      certfiedEmail: "",
      sdi: "",
    }))
  }, [formData.clientType])
  useEffect(() => {
    setFormData(v => ({ ...v, sponsorName: "" }))
  }, [formData.sponsor])
  useEffect(() => {
    const date = new Date(formData.from)
    date.setDate(date.getDate() + parseInt(differenceInDays) - 1)
    const formattedDate = moment(date).format("YYYY-MM-DD")
  }, [differenceInDays])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("from") && urlParams.get("to")) {
      const from = new Date(urlParams.get("from"))
      const to = new Date(urlParams.get("to"))
      setFormData(v => ({
        ...v,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      }))
    }
    const id = urlParams.get("id")
    if (id && umbrellas.length > 0) {
      const isCabin = urlParams.get("isCabin")
      if (isCabin == "true") {
        const index = umbrellas.findIndex(v => id == v.id)
        if (index !== -1) {
          const obj = {
            label: `${umbrellas[index].number} - ${umbrellas[index].sectorName}`,
            value: umbrellas[index].id,
          }
          setFormData(v => ({ ...v, cabins: [obj] }))
        }
      } else {
        const index = umbrellas.findIndex(v => id == v.id)
        if (index !== -1) {
          const obj = {
            label: `${umbrellas[index].number} - ${umbrellas[index].sectorName}`,
            value: umbrellas[index].id,
          }

          setFormData(v => ({ ...v, umbrellaId: obj }))
        }
      }
    }
  }, [hasLoadedUmbrellas])

  return (
    <>
      <Confirmbooking
        onHide={() => {
          setWhatnext(true)
          setConfirmationModal({ email: "", show: false })
        }}
        show={confirmationModal.show}
        email={confirmationModal.email}
      />
      <Whatnext onHide={() => setWhatnext(false)} show={whatnext} />

      <ToastContainer />
      <div
        className="position-absolute"
        style={{ top: "300px", right: "0", zIndex: 1200 }}
      >
        {warnings.map((v, k) => (
          <Toast
            key={k}
            isOpen={true}
            className=" rounded overflow-hidden mb-1"
          >
            <ToastBody className="bg-danger text-white">{v}</ToastBody>
          </Toast>
        ))}
      </div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Planner" breadcrumbItem="Nuova prenotazione" />
          <Row>
            <Col xs={8}>
              <Card className="h-100">
                <CardBody>
                  <Row>
                    <Col>
                      <label>Da</label>
                      <Input
                        disabled={isSeasonal}
                        type="date"
                        value={formData.from}
                        onChange={e =>
                          setFormData(v => ({ ...v, from: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>A</label>
                      <Input
                        disabled={isSeasonal}
                        type="date"
                        min={formData.from}
                        value={formData.to}
                        onChange={e =>
                          setFormData(v => ({ ...v, to: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Giorni</label>
                      <Input
                        type="text"
                        readOnly
                        onChange={e => setDifferenceInDays(e.target.value)}
                        value={isSeasonal ? "Stagionale" : differenceInDays}
                      />
                    </Col>
                    {formData.from == formData.to ? (
                      <Col>
                        <>
                          <label>Durata</label>
                          <Select
                            onChange={e =>
                              setFormData(v => ({ ...v, bookingType: e.value }))
                            }
                            defaultValue={{ label: "Intero", value: 3 }}
                            options={[
                              { label: "Mattina", value: 1 },
                              { label: "Pomeriggio", value: 2 },
                              { label: "Intero", value: 3 },
                            ]}
                          />
                        </>
                      </Col>
                    ) : null}
                    <Col className="d-flex flex-column">
                      <label>&nbsp;</label>
                      <div className="d-flex align-items-center h-100">
                        <input
                          type={"checkbox"}
                          className="mx-2"
                          onClick={e => setSeasonal(e.target.checked)}
                        />
                        <span>Stagionale?</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="w-100 mt-2">
                    <Col xs={4}>
                      <label>N. Ombrellone</label>
                      <Select
                        isClearable={true}
                        value={formData.umbrellaId}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            umbrellaId: e,
                          }))
                        }
                        options={filteredUmbrellas
                          .filter(v => v.isUmbrella == 1)
                          .map(v => ({
                            label: `${v.number} - ${v.sectorName}`,
                            value: v.id,
                          }))}
                      ></Select>
                    </Col>
                    <Col className="d-flex flex-column">
                      <label>&nbsp;</label>
                      <div className="d-flex align-items-center h-100">
                        {daysInLoan.visible && (
                          <Badge color="warning">
                            {" "}
                            {daysInLoan.days} Giorn
                            {daysInLoan.days > 1 ? "i" : "o"} in subaffito
                          </Badge>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <h5 className="mt-4">Configurazione</h5>
                  <Row className="w-100">
                    <Col xs={3} className="mb-2">
                      <label>Clienti associabili</label>
                      <Input
                        type="number"
                        min={1}
                        value={formData.customers}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            customers: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    {elements.map((v, i) => {
                      return (
                        <Col key={v.id} xs={3} className="mb-2">
                          <label>{v.name}</label>
                          <Input
                            type="number"
                            min={0}
                            value={
                              elementsData[i]
                              /*  (elementsData[i] && elementsData[i].toString()) ||
                              "2" */
                            }
                            onChange={e => handleInputChange(e, i)}
                          />
                        </Col>
                      )
                    })}
                    <Col xs={3} className="mb-2">
                      <label>Cabina</label>
                      <Select
                        isMulti
                        options={cabins}
                        value={formData.cabins}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            cabins: e,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <h5 className="mt-4">Cliente</h5>
                  <Row className="mb-2">
                    <Col xs={6}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => {}}
                          onClick={() =>
                            setFormData(v => ({
                              ...v,
                              clientType: formData.clientType == 2 ? 1 : 2,
                            }))
                          }
                          checked={formData.clientType == 1}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Azienda
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="position-relative">
                      <label>Prefisso</label>
                      <Select
                        className="w-100"
                        value={{ label: phonePrefix, value: phonePrefix }}
                        onChange={e => {
                          setPhonePrefix(e.value)
                          setFormData(v => ({ ...v, phone: "" }))
                        }}
                        options={phonePrefixes.map(v => ({
                          value: v,
                          label: v,
                        }))}
                        defaultValue={{
                          value: "+39",
                          label: "+39",
                        }}
                      />
                    </Col>
                    <Col className="position-relative">
                      <label>Telefono</label>
                      <CreatableSelect
                        formatCreateLabel={e =>
                          `Aggiungi un numero di telefono: ${e}`
                        }
                        className="w-100"
                        value={formData.phone}
                        onChange={handleChange}
                        onCreateOption={handleCreate}
                        options={
                          fictionalCustomers
                            ? fictionalCustomers
                                .map(v => {
                                  if (
                                    v.phone_prefix == phonePrefix ||
                                    !phonePrefix
                                  ) {
                                    return {
                                      label: v.phone.replace(
                                        v.phone_prefix,
                                        ""
                                      ),
                                      value: v.phone,
                                    }
                                  } else {
                                    return null
                                  }
                                })
                                .filter(v => v !== null)
                            : null
                        }
                      />
                    </Col>
                    <Col>
                      <label>Nome</label>
                      <Input
                        value={formData.name}
                        onChange={e =>
                          setFormData(v => ({ ...v, name: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Cognome</label>
                      <Input
                        value={formData.surname}
                        onChange={e =>
                          setFormData(v => ({ ...v, surname: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Email</label>
                      <Input
                        value={formData.email}
                        onChange={e =>
                          setFormData(v => ({ ...v, email: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Sponsor</label>
                      <Select
                        defaultValue={formData.sponsor}
                        onChange={e => setFormData(d => ({ ...d, sponsor: e }))}
                        options={[
                          { label: "Nessuno", value: 0 },
                          { label: "Hotel", value: 1 },
                          { label: "Azienda", value: 2 },
                        ]}
                      />
                    </Col>
                    <Col>
                      {formData.sponsor.value !== 0 ? (
                        <>
                          <label>
                            Nome {formData.sponsor.label.toLowerCase()}
                          </label>
                          <Input
                            value={formData.sponsorName}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                sponsorName: e.target.value,
                              }))
                            }
                          />
                        </>
                      ) : null}
                    </Col>
                    {formData.sponsor.value !== 0 && (
                      <Col xs={2}>
                        <label>Sconto</label>
                        <InputGroup>
                          <InputGroupText>%</InputGroupText>
                          <Input
                            type="number"
                            value={formData.discount}
                            onChange={e => {
                              setFormData(v => ({
                                ...v,
                                discount: e.target.value,
                              }))
                            }}
                          />
                        </InputGroup>
                      </Col>
                    )}
                  </Row>
                  {formData.sponsor.value !== 0 ? (
                    <>
                      <h5>{formData.sponsor.label}</h5>
                      <Row className="mb-2">
                        <Col>
                          <label>Nome</label>
                          <Input
                            value={formData.companyName}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                companyName: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Via</label>
                          <Input
                            value={formData.address}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                address: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Nazione</label>
                          <Input
                            value={formData.nation}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                nation: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Provincia</label>
                          <Input
                            value={formData.province}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                province: e.target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>CAP</label>
                          <Input
                            value={formData.zip}
                            onChange={e =>
                              setFormData(v => ({ ...v, zip: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>P.IVA</label>
                          <Input
                            value={formData.vat}
                            onChange={e =>
                              setFormData(v => ({ ...v, vat: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Codice Fiscale</label>
                          <Input
                            value={formData.fc}
                            onChange={e =>
                              setFormData(v => ({ ...v, fc: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>PEC</label>
                          <Input
                            value={formData.certfiedEmail}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                certfiedEmail: e.target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <label>SDI</label>
                          <Input
                            value={formData.sdi}
                            onChange={e =>
                              setFormData(v => ({ ...v, sdi: e.target.value }))
                            }
                          />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                      </Row>
                    </>
                  ) : null}
                  <Row className="mt-3">
                    <Col>
                      <button
                        onClick={handleSubmit}
                        disabled={pendingSubmit}
                        className="btn btn-primary"
                      >
                        Crea prenotazione
                      </button>
                      <button
                        onClick={calculatePrices}
                        className="btn btn-secondary mx-2"
                      >
                        Calcola prezzi
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="bg-dark text-light h-100">
                <CardBody>
                  <section className="d-flex flex-column justify-content-around h-100">
                    <div>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Listino</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input
                              type="number"
                              min={0}
                              value={recommendedPrice}
                              disabled
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="w-75 mb-5">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Prezzo Effettivo</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input
                              type="number"
                              min={0}
                              value={formData.price}
                              onChange={e =>
                                setFormData(v => ({
                                  ...v,
                                  price: parseInt(e.target.value),
                                }))
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div>
                    {/*                     <div>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Acconto</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input type="number" min={0} />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Acconto</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input type="number" min={0} />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div> */}
                    {/*                     <div>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Da pagare</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input type="number" min={0} />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div> */}
                  </section>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default New
