import React, { useState } from "react"
import { Col, InputGroup, InputGroupText, Row } from "reactstrap"
import { Input, Button, Modal, ModalBody } from "reactstrap"
import { BiDrink } from "react-icons/bi"
import { GiKnifeFork } from "react-icons/gi"
import { useEffect } from "react"
import { v4 } from "uuid"
import {
  patchOrPutSubCategory,
  putorPatchProduct,
} from "helpers/fakebackend_helper"

const Newcategory = ({ onHide, show, toggle, triggerUpdateCategory }) => {
  const handleSubmit = async () => {
    if (data.name == "") {
      return alert("Inserisci un nome valido")
    }
    //put the selected timeslot in the request object, which starts from selectedRecord
    const request = {
      ...data,
      timeslots: data.timeslots
        .filter(v => v.to_delete == false)
        .map(v => {
          if (v.id === selectedTimeslot.id) {
            return selectedTimeslot
          }
          return v
        }),
    }

    const res = await patchOrPutSubCategory(request)
    if (res.status) {
      alert("Categoria inserita correttamente")
      triggerUpdateCategory(v => !v)
      setData({
        name: "",
        food_or_drink: "drink",
        preparation_time: 0,
        day_quantity: 0,
        print_ordering: 0,
        timeslots: [],
      })
      onHide()
    }
  }

  useEffect(() => {
    setData({
      name: "",
      food_or_drink: "drink",
      preparation_time: 0,
      day_quantity: 0,
      print_ordering: 0,
      timeslots: [
        {
          to_delete: false,
          to_add: true,
          id: v4(),
          time_from: "08:00",
          time_to: "20:00",
          day: 1,
        },
        {
          to_delete: false,
          to_add: true,
          id: v4(),
          time_from: "08:00",
          time_to: "20:00",
          day: 2,
        },
        {
          to_delete: false,
          to_add: true,
          id: v4(),
          time_from: "08:00",
          time_to: "20:00",
          day: 3,
        },
        {
          to_delete: false,
          to_add: true,
          id: v4(),
          time_from: "08:00",
          time_to: "20:00",
          day: 4,
        },
        {
          to_delete: false,
          to_add: true,
          id: v4(),
          time_from: "08:00",
          time_to: "20:00",
          day: 5,
        },
        {
          to_delete: false,
          to_add: true,
          id: v4(),
          time_from: "08:00",
          time_to: "20:00",
          day: 6,
        },
        {
          to_delete: false,
          to_add: true,
          id: v4(),
          time_from: "08:00",
          time_to: "20:00",
          day: 7,
        },
      ],
    })
  }, [show])
  const getDay = day => {
    switch (parseInt(day)) {
      case 1:
        return "Lunedì"
        break
      case 2:
        return "Martedì"
        break
      case 3:
        return "Mercoledì"
        break
      case 4:
        return "Giovedì"
        break
      case 5:
        return "Venerdì"
        break
      case 6:
        return "Sabato"
        break
      case 7:
        return "Domenica"
        break
    }
  }

  const [data, setData] = useState({
    name: "",
    food_or_drink: "drink",
    preparation_time: 0,
    day_quantity: 0,
    print_ordering: 0,
    timeslots: [],
  })
  const [selectedTimeslot, setSelectedTimeslot] = useState(false)

  useEffect(() => {
    if (data) {
      let v = data
      const tms = v.timeslots.map(el => ({
        ...el,
        to_delete: false,
        to_add: false,
      }))
      setData(el => ({ ...el, timeslots: tms }))
    }
  }, [data.id, data.timeslots.length])

  useEffect(() => {
    //reset timeslot when change category
    setSelectedTimeslot(false)
  }, [data.id])
  useEffect(() => {
    if (data.timeslots.length > 0) {
      setSelectedTimeslot(data.timeslots[data.timeslots.length - 1])
    }
  }, [data.timeslots.length])

  return (
    <Modal isOpen={show} toggle={toggle} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Aggiungi categoria</h5>
      </div>
      <ModalBody>
        <Row>
          <Col>
            <label>Nome categoria</label>
            <Input
              value={data.name}
              onChange={e => setData(v => ({ ...v, name: e.target.value }))}
            />
          </Col>
          <Col>
            <label>Tipo</label>
            <select
              className="form-select"
              value={data.food_or_drink}
              onChange={e =>
                setData(v => ({
                  ...v,
                  food_or_drink: e.target.value,
                }))
              }
            >
              <option value={"drink"}>Drink</option>
              <option value={"food"}>Cibo</option>
            </select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <label>Tempo di preparazione</label>
            <InputGroup>
              <Input
                type="number"
                value={data.preparation_time}
                onChange={({ target }) =>
                  setData(v => ({
                    ...v,
                    preparation_time: target.value,
                  }))
                }
              />
              <InputGroupText>Minuti</InputGroupText>
            </InputGroup>
          </Col>
          <Col>
            <label>Qta. giornaliera (0 se NON DEFINITA)</label>
            <Input
              type="number"
              value={data.day_quantity || 0}
              onChange={({ target }) =>
                setData(v => ({
                  ...v,
                  day_quantity: target.value,
                }))
              }
            />
          </Col>
          <Col>
            <label>Ordine stampa</label>

            <Input
              type="number"
              value={data.print_ordering || 0}
              onChange={({ target }) =>
                setData(v => ({
                  ...v,
                  print_ordering: target.value,
                }))
              }
            />
          </Col>
        </Row>
        <h4 className="mt-3 mb-3">Orari apertura categoria</h4>
        {/* <InputGroup>
          <select
            className="form-select"
            value={selectedTimeslot && selectedTimeslot.id}
            onChange={e => {
              if (selectedTimeslot && selectedTimeslot.id) {
                setData(v => {
                  let timeslots = v.timeslots
                  const index = timeslots.findIndex(
                    v => v.id == selectedTimeslot.id
                  )
                  if (index !== -1) {
                    timeslots[index] = selectedTimeslot
                  } else {
                    timeslots.push(selectedTimeslot)
                  }
                  return { ...v, timeslots }
                })
              }
              setSelectedTimeslot(
                data.timeslots.find(v => v.id == e.target.value)
              )
            }}
          >
            <option value={false}>Seleziona un timeslot</option>
            {data.timeslots.map((timeslot, i) => (
              <option key={i} value={timeslot.id}>
                {timeslot.time_from} - {timeslot.time_to} -{" "}
                {getDay(timeslot.day)}
              </option>
            ))}
          </select>
          <Button
            onClick={() =>
              setData(v => {
                const timeslots = v.timeslots
                timeslots.push({
                  toAdd: true,
                  id: v4(),
                  time_from: "00:00",
                  time_to: "00:00",
                  day: 1,
                })
                return { ...v, timeslots }
              })
            }
          >
            Aggiungi
          </Button>
        </InputGroup>

        {selectedTimeslot && (
          <>
            <Row className="mt-2">
              <Col>
                <label>Da</label>

                <Input
                  type="time"
                  value={
                    selectedTimeslot ? selectedTimeslot.time_from : "00:00"
                  }
                  onChange={({ target }) =>
                    setSelectedTimeslot(v => ({
                      ...v,
                      time_from: target.value,
                    }))
                  }
                />
              </Col>
              <Col>
                <label>Da</label>

                <Input
                  type="time"
                  value={selectedTimeslot ? selectedTimeslot.time_to : "00:00"}
                  onChange={({ target }) =>
                    setSelectedTimeslot(v => ({
                      ...v,
                      time_to: target.value,
                    }))
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <label>Giorno</label>

                <select
                  className="form-select"
                  value={selectedTimeslot ? selectedTimeslot.day : 1}
                  onChange={e =>
                    setSelectedTimeslot(v => ({
                      ...v,
                      day: e.target.value,
                    }))
                  }
                >
                  <option value={1}>Lunedì</option>
                  <option value={2}>Martedì</option>
                  <option value={3}>Mercoledì</option>
                  <option value={4}>Giovedì</option>
                  <option value={5}>Venerdì</option>
                  <option value={6}>Sabato</option>
                  <option value={7}>Domenica</option>
                </select>
              </Col>
            </Row>{" "}
          </>
        )} */}
        {data.timeslots && data.timeslots.length == 0 && (
          <Button
            onClick={() =>
              setData(v => {
                const timeslots = v.timeslots
                timeslots.push({
                  to_delete: false,
                  to_add: true,
                  id: v4(),
                  time_from: "00:00",
                  time_to: "00:00",
                  day: 1,
                })
                return { ...v, timeslots }
              })
            }
          >
            Aggiungi timeslot
          </Button>
        )}
        {data.timeslots && data.timeslots.length > 0 && (
          <>
            <Row className="mt-4">
              <Col xs={4}>Giorno della settimana</Col>
              <Col>Dalle</Col>
              <Col>Alle</Col>
              <Col xs={4}>Azioni</Col>
            </Row>

            {data.timeslots
              .filter(v => v.to_delete == false)
              .map((v, i) => {
                return (
                  <Row key={i} className="mb-4">
                    <Col xs={4}>
                      <select
                        className={`form-select ${
                          data.timeslots[i] && v.day == data.timeslots[i].day
                            ? ""
                            : "bg-warning "
                        } `}
                        value={v.day}
                        onChange={e =>
                          setData(v => {
                            const timeslots = v.timeslots
                            if (i !== -1) {
                              timeslots[i].day = parseInt(e.target.value)
                            }
                            return { ...v, timeslots }
                          })
                        }
                      >
                        <option value={1}>Lunedì</option>
                        <option value={2}>Martedì</option>
                        <option value={3}>Mercoledì</option>
                        <option value={4}>Giovedì</option>
                        <option value={5}>Venerdì</option>
                        <option value={6}>Sabato</option>
                        <option value={7}>Domenica</option>
                      </select>
                    </Col>
                    <Col xs={2}>
                      <Input
                        type="time"
                        value={v.time_from}
                        onChange={({ target }) =>
                          setData(v => {
                            const timeslots = v.timeslots
                            if (i !== -1) {
                              timeslots[i].time_from = target.value
                            }
                            return { ...v, timeslots }
                          })
                        }
                      />
                    </Col>
                    <Col xs={2}>
                      <Input
                        type="time"
                        value={v.time_to}
                        onChange={({ target }) =>
                          setData(v => {
                            const timeslots = v.timeslots
                            if (i !== -1) {
                              timeslots[i].time_to = target.value
                            }
                            return { ...v, timeslots }
                          })
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        color="danger"
                        onClick={() => {
                          setData(prevData => {
                            const timeslots = prevData.timeslots.filter(
                              (timeslot, index) => index !== i
                            )
                            return { ...prevData, timeslots }
                          })
                        }}
                      >
                        Elimina
                      </Button>
                      {i ==
                        data.timeslots.filter(v => v.to_delete == false)
                          .length -
                          1 && (
                        <Button
                          color="primary"
                          className="mx-1"
                          onClick={() =>
                            setData(v => {
                              const timeslots = v.timeslots
                              timeslots.push({
                                to_delete: false,
                                to_add: true,
                                id: v4(),
                                time_from: "00:00",
                                time_to: "00:00",
                                day: 1,
                              })
                              return { ...v, timeslots }
                            })
                          }
                        >
                          Aggiungi
                        </Button>
                      )}
                    </Col>
                  </Row>
                )
              })}
          </>
        )}
        <div className="d-flex justify-content-end">
          <Button onClick={onHide} className="mt-2 mx-1">
            Annulla
          </Button>
          <Button onClick={handleSubmit} className="mt-2" color="primary">
            Salva
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default Newcategory
