import React from "react"
import { useState, useEffect } from "react"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap"
import { v4 } from "uuid"

export default function Bookingtimeslots({ data, show, onHide }) {
  const [ranges, setRanges] = useState([])

  useEffect(() => {
    if (data.ranges) {
      setRanges(data.ranges)
    }
  }, [data, show])

  const handleSubmit = async () => {
    console.log(ranges)
  }
  return (
    <Modal isOpen={show} toggle={onHide} size="lg">
      <ModalHeader toggle={onHide}>Prenotazioni</ModalHeader>
      <ModalBody>
        {ranges.map(v => {
          const uuid = v4()
          return (
            <Row key={v.id || uuid} className="my-1">
              <Col>
                <label>Dalle</label>
                <Input
                  type="time"
                  value={v.date_from}
                  onChange={e => {
                    setRanges(oe => {
                      const newEl = oe.map(l => {
                        if (l.id === v.id) {
                          return { ...l, date_from: e.target.value }
                        }
                        return l
                      })
                      return newEl
                    })
                  }}
                />
              </Col>
              <Col>
                <label>Alle</label>
                <Input
                  type="time"
                  value={v.date_to}
                  onChange={e => {
                    setRanges(oe => {
                      const newEl = oe.map(l => {
                        if (l.id === v.id) {
                          return { ...l, date_to: e.target.value }
                        }
                        return l
                      })
                      return newEl
                    })
                  }}
                />
              </Col>
              <Col className="d-flex flex-column">
                <label>&nbsp;</label>
                <div className="d-flex">
                  <Button
                    color="danger"
                    onClick={() => {
                      //splice the index
                      setRanges(oe => {
                        const newEl = oe.filter(l => l.id !== v.id)
                        return newEl
                      })
                    }}
                  >
                    Elimina
                  </Button>
                  {
                    //if its the last element, add a button to add a new one at the end
                    ranges[ranges.length - 1].id === v.id && (
                      <Button
                        color="primary"
                        className="mx-1"
                        onClick={() => {
                          setRanges(oe => {
                            const newEl = oe.concat({ id: v4() })
                            return newEl
                          })
                        }}
                      >
                        Aggiungi
                      </Button>
                    )
                  }
                </div>
              </Col>
            </Row>
          )
        })}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Salva
        </Button>
        <Button color="secondary" onClick={onHide}>
          Chiudi
        </Button>
      </ModalFooter>
    </Modal>
  )
}
