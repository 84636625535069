import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import Tablerow from "./Tablerow"
import { BsClock, BsCurrencyEuro, BsTrash } from "react-icons/bs"
import Select from "react-select"
import {
  addServiceTimeslot,
  addTimeslotFunction,
  deleteTimeslot,
  deleteTimeslotService,
} from "helpers/fakebackend_helper"
export default function Slotmodal({
  show,
  data,
  onHide,
  handleUpdate,
  toUpdate,
}) {
  console.log(data.nocturnal_time)
  const [selectedDay, setSelectedDay] = useState({ value: false, index: false })
  console.log(data)
  const [values, setValues] = useState({
    places_services_id: data.id,
    from: data.opening_time_booking,
    to: "23:00",
    weeksday: "",
    duration: 60,
    diurnal_price: 0,
    nocturnal_price: 0,
  })
  useEffect(() => {
    setValues(v => ({
      ...v,
      places_services_id: data.id,
      from: data.opening_time_booking,
      to: "23:00",
      duration: data.duration,
    }))
    console.log("ciao")
  }, [JSON.stringify(data)])
  const dayKeys = [
    { italian: "Lunedì", key: "Monday" },
    { italian: "Martedì", key: "Tuesday" },
    { italian: "Mercoledì", key: "Wednesday" },
    { italian: "Giovedì", key: "Thursday" },
    { italian: "Venerdì", key: "Friday" },
    { italian: "Sabato", key: "Saturday" },
    { italian: "Domenica", key: "Sunday" },
  ]
  let freeDays = [...dayKeys]
  data.slots &&
    data.slots.forEach(v => {
      const index = freeDays.findIndex(el => el.key == v.key)
      if (index !== -1) {
        freeDays.splice(index, 1)
      }
    })
  const handleDeleteTimeslot = async (id, key) => {
    const data = await deleteTimeslotService({ id: id, key: key })

    if (data.status) {
      await handleUpdate()
      toUpdate(v => !v)
    }
  }
  const handleAddTimeslot = async () => {
    if (values.weeksday.length == 0) {
      return alert("Inserisci prima uno o più giorni")
    }
    const formattedWeekdays = values.weeksday.map(v => v.value)
    const data = await addServiceTimeslot({
      ...values,
      weeksday: formattedWeekdays,
    })
    if (data == "ok") {
      await handleUpdate()
      toUpdate(v => !v)
      setValues(v => ({ ...v, weeksday: [] }))
    }
  }
  return (
    <Modal isOpen={show} toggle={() => onHide()} size="xl">
      <ModalHeader toggle={() => onHide()}>Slots</ModalHeader>
      <ModalBody>
        {selectedDay.index !== false ? (
          <>
            <a
              href="#"
              onClick={() => setSelectedDay({ value: false, index: false })}
            >
              Ritorna indietro
            </a>
            <Card className=" mt-3">
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Dalle</th>
                      <th>Alle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.slots[selectedDay.index].slots.map((v, c) => {
                      return (
                        <Tablerow handleUpdate={handleUpdate} obj={v} key={c} />
                      )
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            {data.slots &&
              data.slots.map((v, i) => {
                console.log(dayKeys, v)
                const italianIndex = dayKeys.findIndex(el => el.key == v.key)
                let value = ""
                console.log(italianIndex)
                if (italianIndex != -1) {
                  value = dayKeys[italianIndex].italian
                }
                return (
                  <Card className="bg-light " key={i}>
                    <CardBody className="d-flex w-100 align-items-center">
                      <a
                        href="#"
                        onClick={() =>
                          setSelectedDay({ value: v.key, index: i })
                        }
                      >
                        {value}
                      </a>
                      <BsTrash
                        onClick={() => handleDeleteTimeslot(data.id, v.key)}
                        style={{ marginLeft: "auto", cursor: "pointer" }}
                        size={20}
                        className="text-danger"
                      />
                    </CardBody>
                  </Card>
                )
              })}
            {freeDays.length > 0 ? (
              <section className="mt-5">
                <Row>
                  <Col>
                    <label>Dalle</label>
                    <InputGroup>
                      <Input
                        type="time"
                        value={values.from}
                        onChange={e =>
                          setValues(v => ({ ...v, from: e.target.value }))
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label>Alle</label>
                    <InputGroup>
                      <Input
                        type="time"
                        value={values.to}
                        onChange={e =>
                          setValues(v => ({ ...v, to: e.target.value }))
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label>Giorno</label>
                    <Select
                      isMulti
                      value={values.weeksday}
                      onChange={e => setValues(v => ({ ...v, weeksday: e }))}
                      options={freeDays.map(v => ({
                        value: v.key,
                        label: v.italian,
                      }))}
                    />
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => handleAddTimeslot()}>
          Aggiungi
        </Button>{" "}
        <Button color="secondary" onClick={() => onHide()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
