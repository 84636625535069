import Modalmodify from "components/loans/Modalmodify"
import {
  patchLoanBooking,
  patchLoanSettings,
  retrieveLoanSettings,
  retrieveLoans,
} from "helpers/fakebackend_helper"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap"

const Settings = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(2)
  }, [])
  const [formData, setFormData] = useState({
    clause: 0,
    price: 0,
  })

  const loadSettings = async () => {
    const data = await retrieveLoanSettings()
    setFormData(data)
  }
  useEffect(() => {
    loadSettings()
  }, [])

  const handleSave = async () => {
    const data = await patchLoanSettings(formData)
    if (data.status) {
      toast.success("Impostazioni salvate con successo")
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Row>
          <Col xs={6}>
            <Card>
              <CardBody>
                <div>
                  <h3>Configurazione subaffito</h3>
                  <span>
                    Gestisci le impostazioni predefinite degli ombrelloni in
                    subaffitto
                  </span>
                </div>

                <label className="mt-4">
                  Fai pagare{" "}
                  <input
                    type="number"
                    value={formData.price}
                    onChange={e =>
                      setFormData(v => ({ ...v, price: e.target.value }))
                    }
                    style={{ width: "40px" }}
                  />{" "}
                  euro al{" "}
                  <select
                    value={formData.clause}
                    onChange={e =>
                      setFormData(v => ({ ...v, clause: e.target.value }))
                    }
                  >
                    <option value={0}>Seleziona</option>
                    <option value={1}>Periodo</option>
                    <option value={2}>giorno</option>
                  </select>{" "}
                  per ombrellone subaffittato.
                </label>
              </CardBody>
              <CardFooter className="d-flex justify-content-end">
                <Button color="primary" onClick={() => handleSave()}>
                  Salva
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Settings
