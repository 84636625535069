import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Row, Table } from "reactstrap"
import { downloadExcel } from "react-export-table-to-excel"
import {
  retrieveEventAnswers,
  retrieveEvents,
} from "helpers/fakebackend_helper"

const Eventmodules = () => {
  const tableRef = useRef(null)
  const [id, setId] = useState(0)
  const [tableData, setTableData] = useState({
    questions: [],
    answers: [],
  })
  const [events, setEvents] = useState([])

  const loadEvents = async () => {
    const data = await retrieveEvents()

    setEvents(data)
  }

  const loadEventAnswers = async () => {
    const data = await retrieveEventAnswers(id)
    console.log(data)
    setTableData(data)
  }

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "react-export-table-to-excel -> downloadExcel method",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header: tableData.questions.map(questions => questions.label),
        // accept two different data structures
        body: tableData.data.map(data2 => {
          return data2.answers.map(answer => answer.value)
        }),
      },
    })
  }
  useEffect(() => {
    loadEvents()
  }, [])
  useEffect(() => {
    loadEventAnswers()
  }, [id])
  return (
    <div className="page-content">
      <h1>Risposte</h1>
      <div className="d-flex">
        <div className="d-flex flex-column">
          <label>&nbsp;</label>
          <Button className="w-100" onClick={handleDownloadExcel}>
            {" "}
            Esporta in excel{" "}
          </Button>
        </div>

        <div className=" mx-2">
          <label>Evento</label>
          <select
            className="form-select"
            value={id}
            onChange={e => setId(e.target.value)}
          >
            <option value={0}>Seleziona un evento</option>
            {events.map(event => (
              <option key={event.id} value={event.id}>
                {event.title}
              </option>
            ))}
          </select>
        </div>
      </div>
      {tableData.questions.length > 0 && (
        <table ref={tableRef} className="table">
          <thead>
            <tr>
              <th>Ordinazione</th>
              <th>Nominativo acquisto</th>
              <th>Data prenotazione</th>
              {tableData.questions.map(questions => (
                <th key={questions.id}>{questions.label}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {tableData.data &&
              tableData.data.map(data2 => {
                return (
                  <tr key={data2.id}>
                    <th>{data2.order}</th>
                    <td>{data2.customer_name}</td>
                    <td>{new Date(data2.datetime).toLocaleString()}</td>
                    {data2.answers.map((answer, i) => (
                      <td key={i}>{answer.value}</td>
                    ))}
                  </tr>
                )
              })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default Eventmodules
