import React, { useEffect, useRef } from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Container,
  Button,
  Table,
  Modal,
  Input,
  InputGroup,
  InputGroupText,
  Toast,
  ToastBody,
} from "reactstrap"
import { useState } from "react"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import {
  getActivityDates,
  getCabins,
  getElements,
  getFictionalCustomers,
  getPriceUmbrellas,
  getSeasons,
  getUmbrellas,
  patchBookingNew,
  putBooking,
  shouldSendAlert,
} from "helpers/fakebackend_helper"
import { ToastContainer, toast } from "react-toastify"

//Import Breadcrumb
import Whatnext from "components/planner/Whatnext"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreatableSelect from "react-select/creatable"
import Select from "react-select"
import moment from "moment"
import Confirmbooking from "components/planner/confirmBooking"
import phonePrefixes from "components/prefixes"

const Editbooking = () => {
  const [isSeasonal, setIsSeasonal] = useState(false)
  const [filteredUmbrellas, setFilteredUmbrellas] = useState([])
  const [warnings, setWarnings] = useState([])
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const [phonePrefix, setPhonePrefix] = useState("+39")
  const [recommendedPrice, setRecommendedPrice] = useState(0)
  const [hasLoadedUmbrellas, setHasLoadedUmbrellas] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [fictionalCustomers, setFictionalCustomers] = useState([])
  const [differenceInDays, setDifferenceInDays] = useState(1)
  const [formData, setFormData] = useState({
    sponsor: { label: "Nessuno", value: 0 },
    clientType: 2,
    cabins: [],
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
    umbrellaId: 0,
    name: "",
    surname: "",
    phone: "",
    email: "",
    sponsorName: "",
    companyName: "",
    address: "",
    nation: "",
    province: "",
    zip: "",
    vat: "",
    bookingType: 3,
    fc: "",
    certfiedEmail: "",
    sdi: "",
    customers: 1,
    price: 0,
  })
  const [whatnext, setWhatnext] = useState(false)
  const [elementsData, setElementsData] = useState([])
  const [umbrellas, setUmbrellas] = useState([])
  const [cabins, setCabins] = useState([])
  const [elements, setElements] = useState([])
  const [bookingId, setBookingId] = useState(0)
  const loadData = async () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get("id")
    const data = await getUmbrellas()
    setBookingId(id)
    const bookings = []
    data.forEach(v => {
      v.bookings.forEach(el => {
        bookings.push({ ...el, sponsor: parseInt(el.sponsor) })
      })
    })
    console.log(id)
    const booking = bookings.find(v => v.bookingId == parseInt(id))
    let phoneSpliced = ""
    if (booking.phone) {
      phoneSpliced = booking.phone.replace(booking.phone_prefix || "+39", "")
    }
    const phone = { value: phoneSpliced, label: phoneSpliced }
    console.log(booking)
    setFormData(v => ({ ...booking, cabins: booking.cabins, phone: phone }))
  }
  useEffect(() => {
    loadData()
  }, [])
  useEffect(() => {
    const { from, to } = formData
    if (formData.umbrellaId) {
      const bookings = umbrellas.find(v => v.id == formData.umbrellaId.value)
      //check if a booking with loaned == 1 collides with the selected dates
      const overlap = bookings.bookings.some(booking => {
        const bookedFrom = new Date(booking.booked_from)
        const bookedTo = new Date(booking.booked_to)
        return (
          new Date(from) <= bookedTo &&
          new Date(to) >= bookedFrom &&
          booking.loaned == 1
        )
      })
      if (overlap) {
        toast.info(
          "L'ombrellone selezionato comprende un periodo in subaffitto"
        )
      }
    }
  }, [formData.umbrellaId])
  useEffect(() => {
    let mappedElements = []
    if (formData.elements) {
      elements.forEach(v => {
        const i = formData.elements.findIndex(el => el.id == v.id)
        if (i !== -1) {
          mappedElements.push({ qty: formData.elements[i].qty, id: v.id })
        }
      })
      setElementsData(() => mappedElements)
    }
  }, [formData.elements])

  const loadFictionalCustomers = async () => {
    const data = await getFictionalCustomers()
    setFictionalCustomers(data)
  }
  const loadUmbrellas = async id => {
    const val = await getUmbrellas(id)
    setUmbrellas(val)
    if (val.length == 0) {
      alert("Devi prima inserire un ombrellone")
      window.location.href = "/anagrafiche/ombrelloni"
    }
    setHasLoadedUmbrellas(true)
  }
  const loadElements = async id => {
    const val = await getElements(id)
    if (val.length == 0) {
      alert("Inserisci prima un elemento")
      window.location.href = "/anagrafiche/types"
    }
    setElements(val)
  }
  const loadCabins = async id => {
    const val = await getCabins(id)
    const resp = val.map(e => ({ label: e.number, value: e.id }))
    setCabins(resp)
  }
  const handleInputChange = (e, i) => {
    const updatedElementsData = [...elementsData]
    updatedElementsData[i].qty = parseInt(e.target.value)
    setElementsData(updatedElementsData)
  }
  const handleSubmit = async () => {
    if (formData.umbrellaId == 0 && formData.cabins.length == 0) {
      return alert("Non hai selzionato alcun ombrellone o cabina")
    }
    if (
      formData.name == "" ||
      formData.surname == "" ||
      formData.phone == "" ||
      phonePrefix == ""
    ) {
      return alert("Inserisci i dati anagrafici")
    }

    if (recommendedPrice == 0) {
      const conf = confirm(
        "Sei sicuro di voler procedere? Non hai calcolato il prezzo"
      )
      if (!conf) return
    }
    const formattedElems = elementsData.map((v, i) => ({
      qty: v,
      id: elements[i].id,
    }))
    const req = {
      ...formData,
      elements: formattedElems,
      sponsor: formData.sponsor.value,
      phone: `${phonePrefix}${formData.phone.value}`,
      fk_places_id: localStorage.getItem("authUser"),
      phone_prefix: phonePrefix,
    }

    setPendingSubmit(true)
    const response = await patchBookingNew(req)
    if (response.status) {
      setConfirmationModal({ show: true, email: formData.email })
    }
    setPendingSubmit(false)
    window.location.href = "/planner/bookinglist"
  }
  const calculatePrices = async () => {
    console.log("attivato")

    if (
      (formData.umbrellaId == 0 || formData.umbrellaId === null) &&
      formData.cabins.length == 0
    ) {
      return alert("Inserisci prima un ombrellone o una cabina")
    }

    console.log(elementsData)
    const formattedElems = elementsData.map((v, i) => ({
      qty: v,
      id: v.id,
    }))

    const req = {
      ...formData,
      isSeasonal: isSeasonal,
      elements: elementsData,
      sponsor: formData.sponsor.value,
      phone: `${formData.phone.value}`,
      displacements: [
        ...formData.cabins,
        formData.umbrellaId.value
          ? formData.umbrellaId.value
          : formData.umbrellaId,
      ],
      fk_places_id: localStorage.getItem("authUser"),
    }
    const response = await getPriceUmbrellas(req)
    if (!response.status) return alert("Errore")
    console.log(response)
    setRecommendedPrice(response.total)
    console.log(response.warnings)
    response.warnings.forEach(v => {
      toast.warn(v)
    })
  }
  useEffect(() => {
    if (warnings.length > 0) {
      setTimeout(() => setWarnings([]), 5000)
    }
  }, [warnings.length])
  //Da monitorare
  useEffect(() => {
    const from = formData.booked_from
    const to = formData.booked_to
    //create an array of DATES starting from to to, store only string
    const dates = []
    const date = new Date(from)
    while (date <= new Date(to)) {
      dates.push(date.toISOString().substring(0, 10))
      date.setDate(date.getDate() + 1)
    }

    const umbrellasWithBusyDates = umbrellas.map(v => {
      //get the id
      const id = v.id
      //cycle all the bookings
      const busyDates = []
      v.bookings.forEach(el => {
        if (bookingId == el.bookingId) return
        console.log(el)
        //store all the dates in string, starting form booking from to booking to
        const date = new Date(el.booked_from)
        while (date <= new Date(el.booked_to)) {
          busyDates.push(date.toISOString().substring(0, 10))
          date.setDate(date.getDate() + 1)
        }
      })
      console.log(busyDates, v.id)
      //now recyclce again in each bookings, and remove if they exists the date ranges in loaning_times (date_from and date_to) from busyDates
      v.bookings
        .filter(v => v.bookingId !== bookingId)
        .forEach(el =>
          el.loaning_times.forEach(loaning => {
            const date = new Date(loaning.date_from)
            while (date <= new Date(loaning.date_to)) {
              const index = busyDates.findIndex(
                v => v == date.toISOString().substring(0, 10)
              )

              if (index !== -1) {
                busyDates.splice(index, 1)
              }
              date.setDate(date.getDate() + 1)
            }
          })
        )
      return { id, busyDates }
    })
    console.log(umbrellasWithBusyDates)
    console.log(dates)

    //now the filteredUmbrellas should be the umbrellas that have NO dates in common with the dates array
    const filtered = umbrellasWithBusyDates.filter(v => {
      const hasCommon = dates.some(r => v.busyDates.includes(r))
      return !hasCommon
    })
    console.log(filtered)

    setFilteredUmbrellas(
      umbrellas.filter(v => filtered.some(r => r.id == v.id))
    )
    console.log(
      "ciao",
      umbrellas.filter(v => filtered.some(r => r.id == v.id))
    )
  }, [formData.booked_from, formData.booked_to])

  //Fine da monitorare

  const checkPricelistConfiguration = async () => {
    const shouldSend = await shouldSendAlert()
    if (shouldSend) {
    } else {
      const handleWindowBeforeUnload = event => {
        event.preventDefault()
        event.returnValue = ""
      }

      window.addEventListener("beforeunload", handleWindowBeforeUnload)

      return () => {
        window.removeEventListener("beforeunload", handleWindowBeforeUnload)
      }
    }
  }
  const setSeasonal = async bool => {
    if (bool === true) {
      const data = await getActivityDates()
      const from = new Date(data.from).toISOString().substring(0, 10)
      const to = new Date(data.to).toISOString().substring(0, 10)
      setFormData(v => ({ ...v, booked_from: from, booked_to: to }))
      setIsSeasonal(true)
    } else {
      setIsSeasonal(false)
    }
  }
  const handleChange = newValue => {
    const i = fictionalCustomers.findIndex(v => v.phone === newValue.value)
    if (i !== -1) {
      const foundValue = fictionalCustomers[i]

      setFormData(v => ({
        ...v,
        clientType: foundValue.clientType,
        name: foundValue.name,
        surname: foundValue.surname,
        email: foundValue.email,
        companyName: foundValue.companyName,
        address: foundValue.address,
        nation: foundValue.nation,
        province: foundValue.province,
        zip: foundValue.zip,
        vat: foundValue.vat,
        fc: foundValue.fc,
        certfiedEmail: foundValue.certfiedEmail,
        sdi: foundValue.sdi,
        phone: newValue,
      }))
    } else {
      setFormData(v => ({ ...v, phone: newValue }))
    }
  }

  const handleCreate = inputValue => {
    const newOption = {
      value: inputValue.toLowerCase(),
      label: inputValue,
    }
    setSelectedOption(newOption)
    // you can also add the new option to the options array if you want
  }
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    email: "",
  })

  useEffect(() => {
    selectedOption ? setFormData(v => ({ ...v, phone: selectedOption })) : null
  }, [selectedOption])

  useEffect(() => {
    const userPlace = localStorage.getItem("authUser")
    loadUmbrellas(userPlace)
    loadElements(userPlace)
    loadCabins(userPlace)
    loadFictionalCustomers()
  }, [])
  useEffect(() => {
    console.log(formData)
    const diffInMs = Math.abs(
      new Date(formData.booked_from) - new Date(formData.booked_to)
    )
    console.log(diffInMs)
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
    setDifferenceInDays(diffInDays + 1)
  }, [formData.booked_from, formData.booked_to])

  useEffect(() => {
    setFormData(v => ({ ...v, price: recommendedPrice }))
  }, [recommendedPrice])

  useEffect(() => {
    checkPricelistConfiguration()
  }, [])
  useEffect(() => {
    setFormData(e => ({
      ...e,
      companyName: "",
      address: "",
      nation: "",
      province: "",
      zip: "",
      vat: "",
      fc: "",
      certfiedEmail: "",
      sdi: "",
    }))
  }, [formData.clientType])
  useEffect(() => {
    setFormData(v => ({ ...v, sponsorName: "" }))
  }, [formData.sponsor])
  useEffect(() => {
    const date = new Date(formData.from)
    date.setDate(date.getDate() + parseInt(differenceInDays) - 1)
    const formattedDate = moment(date).format("YYYY-MM-DD")

    /*     setFormData(v => ({
      ...v,
      to: formattedDate,
    })) */
  }, [differenceInDays])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("from") && urlParams.get("to")) {
      const from = new Date(urlParams.get("from"))
      const to = new Date(urlParams.get("to"))
      setFormData(v => ({
        ...v,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      }))
    }
    const id = urlParams.get("id")
    if (id && umbrellas.length > 0) {
      const isCabin = urlParams.get("isCabin")
      if (isCabin == "true") {
        const index = umbrellas.findIndex(v => id == v.id)
        if (index !== -1) {
          const obj = {
            label: `${umbrellas[index].number} - ${umbrellas[index].sectorName}`,
            value: umbrellas[index].id,
          }
          setFormData(v => ({ ...v, cabins: [obj] }))
        }
      } else {
        const index = umbrellas.findIndex(v => id == v.id)
        if (index !== -1) {
          const obj = {
            label: `${umbrellas[index].number} - ${umbrellas[index].sectorName}`,
            value: umbrellas[index].id,
          }

          setFormData(v => ({ ...v, umbrellaId: obj }))
        }
      }
    }
  }, [hasLoadedUmbrellas])
  useEffect(() => {
    const umbrella = umbrellas.find(v => v.id == formData.fk_umbrellas_id)
    const filtered = umbrella
      ? {
          value: umbrella.id,
          label: `${umbrella.number} - ${umbrella.sectorName}`,
        }
      : null
    setFormData(v => ({ ...v, umbrellaId: filtered }))
  }, [umbrellas])

  return (
    <>
      {/* <Confirmbooking
        onHide={() => {
          setWhatnext(true)
          setConfirmationModal({ email: "", show: false })
        }}
        show={confirmationModal.show}
        email={confirmationModal.email}
      />
      <Whatnext onHide={() => setWhatnext(false)} show={whatnext} /> */}

      <div
        className="position-absolute"
        style={{ top: "300px", right: "0", zIndex: 1200 }}
      >
        {warnings.map((v, k) => (
          <Toast
            key={k}
            isOpen={true}
            className=" rounded overflow-hidden mb-1"
          >
            <ToastBody className="bg-danger text-white">{v}</ToastBody>
          </Toast>
        ))}
      </div>
      <div className="page-content">
        <ToastContainer />
        <Container fluid={true}>
          <Breadcrumbs title="Planner" breadcrumbItem="Modifica prenotazione" />
          <Row className="mb-2">
            <Col>
              <a href="bookinglist" className="text-primary">
                Ritorna indietro
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Card className="h-100">
                <CardBody>
                  <Row>
                    <Col>
                      <label>Da</label>
                      <Input
                        disabled={isSeasonal}
                        type="date"
                        value={formData.booked_from}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            booked_from: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>A</label>
                      <Input
                        disabled={isSeasonal}
                        type="date"
                        min={formData.booked_from}
                        value={formData.booked_to}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            booked_to: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Giorni</label>
                      <Input
                        type="text"
                        readOnly
                        onChange={e => setDifferenceInDays(e.target.value)}
                        value={isSeasonal ? "Stagionale" : differenceInDays}
                      />
                    </Col>
                    {formData.booked_from == formData.booked_to ? (
                      <Col>
                        <>
                          <label>Durata</label>
                          <Select
                            onChange={e =>
                              setFormData(v => ({ ...v, bookingType: e.value }))
                            }
                            defaultValue={{ label: "Intero", value: 3 }}
                            options={[
                              { label: "Mattina", value: 1 },
                              { label: "Pomeriggio", value: 2 },
                              { label: "Intero", value: 3 },
                            ]}
                          />
                        </>
                      </Col>
                    ) : null}
                    <Col className="d-flex flex-column">
                      <label>&nbsp;</label>
                      <div className="d-flex align-items-center h-100">
                        <input
                          type={"checkbox"}
                          className="mx-2"
                          onClick={e => setSeasonal(e.target.checked)}
                        />
                        <span>Stagionale?</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="w-100 mt-2">
                    <Col xs={4}>
                      <label>N. Ombrellone</label>
                      <Select
                        isClearable={true}
                        value={formData.umbrellaId}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            umbrellaId: e,
                          }))
                        }
                        options={filteredUmbrellas
                          .filter(v => v.isUmbrella == 1)
                          .map(v => ({
                            label: `${v.number} - ${v.sectorName}`,
                            value: v.id,
                          }))}
                      ></Select>
                    </Col>
                  </Row>
                  <h5 className="mt-4">Configurazione</h5>
                  <Row className="w-100">
                    <Col xs={3} className="mb-2">
                      <label>Clienti associabili</label>
                      <Input
                        type="number"
                        min={1}
                        value={formData.customers}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            customers: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    {elements.map((v, i) => (
                      <Col key={v.id} xs={3} className="mb-2">
                        <label>{v.name}</label>
                        <Input
                          type="number"
                          pattern="[0-9]*"
                          value={
                            (elementsData[i] && elementsData[i].qty) || "0"
                          }
                          onClick={() => console.log(elementsData[i])}
                          onChange={e => handleInputChange(e, i)}
                        />
                      </Col>
                    ))}
                    <Col xs={3} className="mb-2">
                      <label>Cabina</label>
                      <Select
                        isMulti
                        options={cabins}
                        value={formData.cabins}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            cabins: e,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <h5 className="mt-4">Cliente</h5>
                  <Row className="mb-2">
                    <Col xs={6}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => {}}
                          onClick={() =>
                            setFormData(v => ({
                              ...v,
                              clientType: formData.clientType == 2 ? 1 : 2,
                            }))
                          }
                          checked={formData.clientType == 1}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Azienda
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="position-relative">
                      <label>Prefisso</label>
                      <Select
                        className="w-100"
                        value={{ label: phonePrefix, value: phonePrefix }}
                        onChange={e => {
                          setPhonePrefix(e.value)
                          setFormData(v => ({ ...v, phone: "" }))
                        }}
                        options={phonePrefixes.map(v => ({
                          value: v,
                          label: v,
                        }))}
                        defaultValue={{
                          value: "+39",
                          label: "+39",
                        }}
                      />
                    </Col>
                    <Col className="position-relative">
                      <label>Telefono</label>
                      <CreatableSelect
                        formatCreateLabel={e =>
                          `Aggiungi un numero di telefono: ${e}`
                        }
                        className="w-100"
                        value={formData.phone}
                        onChange={handleChange}
                        onCreateOption={handleCreate}
                        options={
                          fictionalCustomers
                            ? fictionalCustomers
                                .map(v => {
                                  if (v.phone_prefix == phonePrefix) {
                                    return {
                                      label: v.phone.replace(
                                        phonePrefix || "+39",
                                        ""
                                      ),
                                      value: v.phone.replace(
                                        phonePrefix || "+39",
                                        ""
                                      ),
                                    }
                                  } else {
                                    return null
                                  }
                                })
                                .filter(v => v !== null)
                            : null
                        }
                      />
                    </Col>
                    <Col>
                      <label>Nome</label>
                      <Input
                        value={formData.name}
                        onChange={e =>
                          setFormData(v => ({ ...v, name: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Cognome</label>
                      <Input
                        value={formData.surname}
                        onChange={e =>
                          setFormData(v => ({ ...v, surname: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Email</label>
                      <Input
                        value={formData.email}
                        onChange={e =>
                          setFormData(v => ({ ...v, email: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Sponsor</label>
                      <select
                        className="form-select"
                        defaultValue={formData.sponsor}
                        onChange={e =>
                          setFormData(d => ({ ...d, sponsor: e.target.value }))
                        }
                      >
                        <option value={0}>Nessuno</option>
                        <option value={1}>Hotel</option>
                        <option value={2}>Azienda</option>
                      </select>
                    </Col>
                    <Col>
                      {formData.sponsor !== 0 ? (
                        <>
                          <label>
                            Nome{" "}
                            {formData.sponsor &&
                              formData.sponsor.label &&
                              formData.sponsor.label.toLowerCase()}
                          </label>
                          <Input
                            value={formData.sponsorName}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                sponsorName: e.target.value,
                              }))
                            }
                          />
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  {formData.clientType == 1 ? (
                    <>
                      <h5>Azienda</h5>
                      <Row className="mb-2">
                        <Col>
                          <label>Nome</label>
                          <Input
                            value={formData.companyName}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                companyName: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Via</label>
                          <Input
                            value={formData.address}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                address: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Nazione</label>
                          <Input
                            value={formData.nation}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                nation: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Provincia</label>
                          <Input
                            value={formData.province}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                province: e.target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>CAP</label>
                          <Input
                            value={formData.zip}
                            onChange={e =>
                              setFormData(v => ({ ...v, zip: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>P.IVA</label>
                          <Input
                            value={formData.vat}
                            onChange={e =>
                              setFormData(v => ({ ...v, vat: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Codice Fiscale</label>
                          <Input
                            value={formData.fc}
                            onChange={e =>
                              setFormData(v => ({ ...v, fc: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>PEC</label>
                          <Input
                            value={formData.certfiedEmail}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                certfiedEmail: e.target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <label>SDI</label>
                          <Input
                            value={formData.sdi}
                            onChange={e =>
                              setFormData(v => ({ ...v, sdi: e.target.value }))
                            }
                          />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                      </Row>
                    </>
                  ) : null}
                  <Row className="mt-3">
                    <Col>
                      <button
                        onClick={handleSubmit}
                        disabled={pendingSubmit}
                        className="btn btn-primary"
                      >
                        Modifica prenotazione
                      </button>
                      <button
                        onClick={calculatePrices}
                        className="btn btn-secondary mx-2"
                      >
                        Calcola prezzi
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="bg-dark text-light h-100">
                <CardBody>
                  <section className="d-flex flex-column justify-content-around h-100">
                    <div>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Listino</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input
                              type="number"
                              min={0}
                              value={recommendedPrice}
                              disabled
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="w-75 mb-5">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Prezzo</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input
                              type="number"
                              min={0}
                              value={formData.price}
                              onChange={e =>
                                setFormData(v => ({
                                  ...v,
                                  price: parseInt(e.target.value),
                                }))
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div>
                    {/*                     <div>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Acconto</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input type="number" min={0} />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Acconto</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input type="number" min={0} />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div> */}
                    {/*                     <div>
                      <Row className="w-75 mb-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <label className="mx-3 my-0">Da pagare</label>
                        </Col>
                        <Col>
                          <InputGroup>
                            <div className="input-group-text">€</div>
                            <Input type="number" min={0} />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div> */}
                  </section>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Editbooking
