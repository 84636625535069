import { patchFieldTimeslot } from "helpers/fakebackend_helper"
import { useState } from "react"
import React from "react"
import { BsCurrencyEuro } from "react-icons/bs"
import { Button, InputGroup, InputGroupText, Input } from "reactstrap"
const Tablerow = ({ obj, handleUpdate }) => {
  const [data, setData] = useState(obj)
  const patchSlot = async obb => {
    const { status } = await patchFieldTimeslot(obb)
    if (status) {
      handleUpdate()
    }
  }
  return (
    <tr key={data.id}>
      <td>{data.start_slot_time}</td>
      <td>{data.end_slot_time}</td>
    </tr>
  )
}

export default Tablerow
