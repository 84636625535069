import { getPlacePlan } from 'helpers/fakebackend_helper';
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';

const Purchase = () => {
    const [plan, setPlan] = useState("Planner")
    //get id params from url
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        const loadPlacePlan = async () => {
            const plan = await getPlacePlan(id);
            setPlan(plan.name);
        };

        if (id) {
            loadPlacePlan(id);
        } else {

        }
    }, []);

    return (
        <div className="page-content">
            <Container fluid={true} className='w-100 pt-5 d-flex flex-column justify-content-center align-items-center'>
                <h1>Sembra che questo modulo non sia attivo!</h1>
                <h6>Chiama il tuo commerciale di riferimento per attivarlo o manda mail a <a href={`mailto:moduli@sunspotbeach.it?subject=Richiesta modulo ${plan}`}>moduli@sunspotbeach.it</a></h6>
            </Container>
        </div>
    );
}

export default Purchase;