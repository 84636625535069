import React from "react"
import {
  ModalBody,
  ModalHeader,
  Modal,
  Button,
  Row,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"
import Bookingrecord from "./Bookingrecord"
import { BsCurrencyEuro } from "react-icons/bs"
import { useState } from "react"

export default function Legendmodal({ show, onHide, types }) {
  const [number, setNumber] = useState(false)
  const [imageButton, setImageButton] = useState(false)
  const [euroButton, setEuroButton] = useState(false)
  const [elementsButton, setElementsButton] = useState(false)
  const [dateButton, setDateButton] = useState(false)
  const [surnameButton, setSurnameButton] = useState(false)
  console.log(types)
  let umbrella = {}
  if (types) {
    umbrella = types.find(v => v.isUmbrella == 1)
  }
  return (
    <>
      <Modal isOpen={show} toggle={onHide}>
        <ModalHeader>
          <h3>Legenda</h3>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <div
              className="d-flex flex-column p-2 border rounded"
              style={{ width: "300px" }}
            >
              <Row>
                <Col id="myButton">
                  <Popover isOpen={number} target={"myButton"} placement="left">
                    <PopoverHeader>Numero ombrellone</PopoverHeader>
                    <PopoverBody>
                      Questo è il numero con la quale si identifica l'ombrellone
                    </PopoverBody>
                  </Popover>
                  <Button
                    color="transparent"
                    className="border"
                    onMouseEnter={() => setNumber(true)}
                    onMouseLeave={() => setNumber(false)}
                  >
                    3
                  </Button>
                </Col>

                <Col
                  id="elementsButton"
                  className="d-flex justify-content-end align-items-start"
                >
                  <Popover
                    isOpen={elementsButton}
                    target={"elementsButton"}
                    placement="right"
                  >
                    <PopoverHeader>Lista elementi configurati</PopoverHeader>
                    <PopoverBody>
                      La quantità di elementi configurati per l'ombrellone
                      seguita dalla loro iniziale.
                    </PopoverBody>
                  </Popover>
                  <Button
                    color="transparent"
                    className="border rounded"
                    onMouseEnter={() => setElementsButton(true)}
                    onMouseLeave={() => setElementsButton(false)}
                  >
                    3L2S
                  </Button>
                </Col>
              </Row>
              <div className="d-flex align-items-center mt-3">
                <Popover
                  isOpen={imageButton}
                  target={"imageButton"}
                  placement="left"
                >
                  <PopoverHeader>Logo elemento</PopoverHeader>
                  <PopoverBody>
                    Il logo utilizzato per rappresentare l'elemento. <br />{" "}
                    NOTA: L'ombrellone può avere più colori dipendentemente dal
                    suo stato, controlla la legenda sottostante per capirne il
                    significato
                  </PopoverBody>
                </Popover>
                <img
                  onMouseEnter={() => setImageButton(true)}
                  onMouseLeave={() => setImageButton(false)}
                  id="imageButton"
                  className="border rounded p-1"
                  src={umbrella && umbrella.path}
                  style={{ height: "48px", cursor: "pointer" }}
                  alt=""
                />
                <Popover
                  isOpen={euroButton}
                  target={"euroButton"}
                  placement="left"
                >
                  <PopoverHeader>Indicatore pagamento</PopoverHeader>
                  <PopoverBody>
                    Se la prenotazione corrente non è stata pagata, l'indicatore
                    sarà presente.
                  </PopoverBody>
                </Popover>
                <BsCurrencyEuro
                  id="euroButton"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => setEuroButton(true)}
                  onMouseLeave={() => setEuroButton(false)}
                  className="text-danger border rounded p-1 mx-1"
                  size={28}
                />
              </div>
              <Row className="mt-3">
                <Col id="dateButton">
                  <Popover
                    isOpen={dateButton}
                    target={"dateButton"}
                    placement="left"
                  >
                    <PopoverHeader>
                      Data della prossima prenotazione
                    </PopoverHeader>
                    <PopoverBody>
                      Indica la data d'inizio della prenotazione, se la data
                      corrente ricade nel periodo di prenotazione, indicherà la
                      data di fine prenotazione.
                    </PopoverBody>
                  </Popover>
                  <Button
                    color="transparent"
                    className="border rounded"
                    onMouseEnter={() => setDateButton(true)}
                    onMouseLeave={() => setDateButton(false)}
                  >
                    14/07
                  </Button>
                </Col>
                <Col className="d-flex justify-content-end" id="surnameButton">
                  <Popover
                    isOpen={surnameButton}
                    target={"surnameButton"}
                    placement="right"
                  >
                    <PopoverHeader>Iniziali cognome del cliente</PopoverHeader>
                    <PopoverBody>
                      Il cognome dell'utente che ha effettuato la prenotazione.
                    </PopoverBody>
                  </Popover>
                  <Button
                    color="transparent"
                    className="border rounded"
                    onMouseEnter={() => setSurnameButton(true)}
                    onMouseLeave={() => setSurnameButton(false)}
                  >
                    BUG
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          <label className="mt-5">Legenda ombrelloni</label>
          <div className="mt-1">
            <img
              src="https://dev2.dasporto.net/imageEndpoints/ombrellone-02-danger.png"
              style={{ height: "38px" }}
            />
            <span className="mx-2">
              Ombrellone occupato durante il periodo selezionato;
            </span>
          </div>
          <div className="mt-2">
            <img
              src="https://dev2.dasporto.net/imageEndpoints/ombrellone-02-secondary.png"
              style={{ height: "38px" }}
            />
            <span className="mx-2">
              Ombrellone in subaffitto durante il periodo selezionato;
            </span>
          </div>
          <div className="mt-2">
            <img
              src="https://dev2.dasporto.net/imageEndpoints/ombrellone-02.png"
              style={{ height: "38px" }}
            />
            <span className="mx-2">Ombrellone disponibile.</span>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
