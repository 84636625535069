import {
  changeModuleStatus,
  getModules,
  getPrettyURLs,
  getPrintingStatus,
  patchPrintingStatus,
} from "helpers/fakebackend_helper"
import React, { useState } from "react"
import { useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Card,
  Button,
} from "reactstrap"
import { RiRestartLine } from "react-icons/ri"
import { BsTrash } from "react-icons/bs"

export default function Index() {
  const [modules, setModules] = useState([])
  const [printingStatus, setPrintingStatus] = useState(false)
  const loadModules = async () => {
    const data = await getModules()
    const withoutPlanner = data.filter(item => item.id !== 1)
    setModules(withoutPlanner)
  }
  const loadPrintingStatus = async () => {
    const data = await getPrintingStatus()

    setPrintingStatus(data)
  }

  const changeStatus = async id => {
    const conf = confirm("Sei sicuro di voler cambiare lo stato del modulo?")
    if (conf) {
      const data = await changeModuleStatus(id)
      if (data.status) {
        alert("Modulo aggiornato con successo")
        loadModules()
      }
    }
  }

  const handleSubmit = async () => {
    console.log(printingStatus)
    const data = await patchPrintingStatus(printingStatus)
    if (data.status) {
      alert("Impostazioni salvate con successo")
    }
  }
  useEffect(() => {
    loadModules()
    loadPrintingStatus()
  }, [])
  return (
    <div className="page-content">
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="h-100">
              <CardBody>
                <h3>Stato moduli</h3>
                <p className="mb-5">
                  Qui puoi modificare lo stato dei tuoi moduli, puoi
                  disabilitarli a seconda delle necessità dello stabilimento.
                </p>
                {modules.map((item, index) => (
                  <Row
                    key={index}
                    className="mb-3 d-flex justify-content-center align-items-center"
                  >
                    <Col>{item.name}</Col>
                    <Col className="d-flex">
                      <span
                        className={`h-100 bg-${
                          item.enabled ? "success" : "danger"
                        } p-2 rounded text-white mx-1`}
                      >
                        {item.enabled ? "Attivo" : "Disattivo"}
                      </span>
                      <Button onClick={() => changeStatus(item.id)}>
                        <RiRestartLine /> Cambia stato
                      </Button>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <CardBody className="position-relative">
                <h3>Stampa</h3>
                <div className="d-flex w-100">
                  <label>Vuoi prevedere la stampa delle prenotazioni?</label>
                  <input
                    type="checkbox"
                    style={{ marginLeft: "auto" }}
                    checked={printingStatus.to_print == 1}
                    onClick={() => {
                      setPrintingStatus(v => ({
                        ...v,
                        to_print: printingStatus.to_print == 1 ? 0 : 1,
                      }))
                    }}
                    className="form-check-input"
                  />
                </div>
                <hr />
                {printingStatus.to_print == 1 && (
                  <>
                    <Row>
                      <Col>
                        <p>
                          <input
                            type="checkbox"
                            checked={
                              printingStatus.print_events_free_labels == 1
                            }
                            onClick={() => {
                              setPrintingStatus(v => ({
                                ...v,
                                print_events_free_labels:
                                  printingStatus.print_events_free_labels == 1
                                    ? 0
                                    : 1,
                              }))
                            }}
                            className="form-check-input"
                          />
                          <span className="mx-2">Eventi gratis</span>
                        </p>
                      </Col>
                      <Col>
                        <p>
                          <input
                            type="checkbox"
                            checked={
                              printingStatus.print_field_free_labels == 1
                            }
                            onClick={() => {
                              setPrintingStatus(v => ({
                                ...v,
                                print_field_free_labels:
                                  printingStatus.print_field_free_labels == 1
                                    ? 0
                                    : 1,
                              }))
                            }}
                            className="form-check-input"
                          />
                          <span className="mx-2">Campi gratis</span>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={
                              printingStatus.print_events_pay_labels == 1
                            }
                            onClick={() => {
                              setPrintingStatus(v => ({
                                ...v,
                                print_events_pay_labels:
                                  printingStatus.print_events_pay_labels == 1
                                    ? 0
                                    : 1,
                              }))
                            }}
                          />
                          <span className="mx-2">Eventi a pagamento</span>
                        </p>
                      </Col>
                      <Col>
                        <p>
                          <input
                            type="checkbox"
                            checked={printingStatus.print_field_pay_labels == 1}
                            className="form-check-input"
                          />
                          <span className="mx-2">Campi a pagamento</span>
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
                <div
                  className="position-absolute d-flex justify-content-end m-3"
                  style={{ bottom: 0, right: 0, left: 0 }}
                >
                  <Button color="primary" onClick={() => handleSubmit()}>
                    Salva impostazioni
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
