import { Input, Modal, Row, Col } from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { patchType } from "helpers/fakebackend_helper"

const Modify = props => {
  const [imageValue, setImageValue] = useState({})
  const [data, setData] = useState({})

  const handleSelectChange = selectedOption => {
    setImageValue(selectedOption)
    setData(v => ({ ...v, path: selectedOption.value }))
  }
  const handleSubmit = async () => {
    const resp = await patchType(data)
    if (resp.status) {
      await props.updateData()
      props.setVisibility(v => ({ ...v, show: false }))
    }
  }
  useEffect(() => {
    setImageValue({
      label: (
        <img
          src={`https://dev2.dasporto.net/imageEndpoints/${props.data.path}`}
          style={{ height: "48px" }}
        />
      ),
      value: props.data.path,
    })
    setData(props.data)
  }, [props.show])
  useEffect(() => {
    if (data.isUmbrella == 1) {
      const i = props.images.findIndex(v => v.isUmbrella == 1)
      setImageValue({
        label: <img src={props.images[i].path} style={{ height: "48px" }} />,
        value: props.images[i].path,
      })
    }
  }, [data.isUmbrella])
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.setVisibility(val => ({ ...val, show: false }))
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Modifica elemento
        </h5>
        <button
          type="button"
          onClick={() => {
            props.setVisibility(val => ({ ...val, show: false }))
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-2">
          <Col xs={12} md={4} className="mb-2 mb-sm-0">
            <label>Immagine</label>
            <Select
              isDisabled={data.isUmbrella == 1}
              isSearchable={false}
              value={imageValue}
              onChange={handleSelectChange}
              options={props.images.map(v => ({
                label: <img src={v.path} style={{ height: "48px" }} />,
                value: v.name,
              }))}
            />
          </Col>
          <Col xs={12} md={4} className="mb-2 mb-sm-0">
            <label> </label>
            <div className="h-100 d-flex flex-column justify-content-center align-items-start">
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={data.isUmbrella == 1}
                  onChange={() => {}} //just to avoid errors in console log
                  onClick={() =>
                    data.isUmbrella == 1
                      ? setData(v => ({ ...v, isUmbrella: 0 }))
                      : setData(v => ({ ...v, isUmbrella: 1 }))
                  }
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Ombrellone
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={data.isEnvironment == 1}
                  disabled={data.isUmbrella == 1}
                  onChange={() => {}} //just to avoid errors in console log
                  onClick={() =>
                    data.isEnvironment == 1
                      ? setData(v => ({ ...v, isEnvironment: 0 }))
                      : setData(v => ({ ...v, isEnvironment: 1 }))
                  }
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Non prenotabile
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column">
            <label>Nome</label>
            <Input
              type="text"
              value={data.name}
              onChange={e => setData(v => ({ ...v, name: e.target.value }))}
              className="h-100"
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.setVisibility(val => ({ ...val, show: false }))
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary "
        >
          Salva
        </button>
      </div>
    </Modal>
  )
}

export default Modify
