import React from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Container,
  Button,
  Table,
  Modal,
  Badge,
  Toast,
  ToastBody,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useState } from "react"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import { useEffect } from "react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Modals
import {
  getElements,
  getImages,
  getTypes,
  getDefaultDisplacements,
  putDefaultTypes,
  getPlaceDimensions,
  putElements,
  getDefaultElements,
} from "helpers/fakebackend_helper"
import Modify from "components/anagrafiche/types/modify"
import Newtype from "components/anagrafiche/types/new"
import { checkIfUserHasPurchasedModule } from "helpers/functions"

const Types = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])
  const [data, setData] = useState([])
  const [selector, setSelector] = useState(1)
  const [displacementStatus, setDisplacementStatus] = useState([])
  const [modifyData, setModifyData] = useState({ data: {}, show: false })
  const [images, setImages] = useState([])
  const [newDisplacement, setNewDisplacement] = useState(false)
  const retrieveElements = async () => {
    let elems
    switch (selector) {
      case 1:
        elems = await getDefaultDisplacements()
        break
      case 2:
        elems = await getDefaultElements()
        break
    }
    setData(elems)
  }
  const retrieveImages = async () => {
    const images = await getImages()
    const resp = images.map(v => ({
      isUmbrella: v.isUmbrella,
      name: v.path,
      path: `https://dev2.dasporto.net/imageEndpoints/${v.path}`,
    }))
    setImages(resp)
  }
  const handleSubmit = async () => {
    console.log(displacementStatus)
    try {
      const req = displacementStatus.filter(v => v.enabled !== v.default)
      let data
      switch (selector) {
        case 1:
          data = await putDefaultTypes(req)
          break
        case 2:
          data = await putElements(req)
          break
      }
      if (selector) {
      }
      if (data.status) {
        retrieveElements()
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleStatusChange = index => {
    setDisplacementStatus(v => {
      const data = v.map((v, i) => ({
        ...v,
        enabled: i == index ? !v.enabled : v.enabled,
      }))
      return data
    })
  }
  useEffect(() => {
    retrieveElements()
    retrieveImages()
  }, [selector])
  useEffect(() => {
    console.log(data)
    if (data.length > 0) {
      const status = data.map(v => ({
        id: v.id,
        enabled: v.associationExists !== null,
        default: v.associationExists !== null,
      }))
      setDisplacementStatus(status)
    }
  }, [data])

  return (
    <>
      <Newtype
        updateData={retrieveElements}
        images={images}
        show={newDisplacement}
        onHide={() => setNewDisplacement(false)}
      />
      <Modify
        updateData={retrieveElements}
        show={modifyData.show}
        data={modifyData.data}
        setVisibility={setModifyData}
        images={images}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Anagrafiche" breadcrumbItem="Elementi" />
          <div className="d-flex">
            <button
              className="btn btn-primary"
              onClick={() => handleSubmit()}
              disabled={
                displacementStatus.filter(v => v.enabled !== v.default)
                  .length == 0
              }
            >
              Salva
            </button>
            <select
              className="form-select mx-3 w-25"
              value={selector}
              onChange={e => setSelector(parseInt(e.target.value))}
            >
              <option value={1}>Dispiegabili</option>
              <option value={2}>Elementi configurazione</option>
              {/* <option value={3}>Servizi</option> */}
            </select>
          </div>
          <Row>
            <Col>
              <div className="table-responsive">
                <Table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Immagine</th>
                      <th>Nome</th>
                      <th>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0
                      ? data.map((v, i) => (
                          <tr key={i}>
                            <td>
                              {v.path ? (
                                <img
                                  src={`https://dev2.dasporto.net/imageEndpoints/${v.path}`}
                                  style={{ height: "64px" }}
                                  alt="Image not found"
                                />
                              ) : null}
                            </td>
                            <td>{v.name}</td>
                            <td>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    displacementStatus.length > 0 &&
                                    displacementStatus[i] &&
                                    displacementStatus[i].enabled
                                  }
                                  disabled={
                                    (selector == 1 && v.id == 1) ||
                                    (selector == 2 && v.id == 2)
                                  }
                                  onClick={() => handleStatusChange(i)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  Abilitato
                                </label>
                              </div>
                            </td>
                            {/*                         <td>
                          <Button
                            className="btn-sm"
                            onClick={() => {
                              setModifyData({ show: true, data: types[i] })
                            }}
                          >
                            Modifica
                          </Button>
                          <Button className="btn-sm mx-2 btn-danger">
                            Elimina
                          </Button>
                        </td> */}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Types
