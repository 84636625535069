import React from "react"
import { v4 as uuidv4 } from "uuid"
import axios from "axios"
import { Button, Row, Col, Form, Input } from "reactstrap"
import { useEffect, useState } from "react"
import { CheckBox } from "pages/Dashboard/LatestTranactionCol"
import Select from "react-select"
import {
  createCode,
  deleteDisplacement,
  patchDisplacement,
  putDisplacement,
} from "helpers/fakebackend_helper"

const Aside = props => {
  const wrapper = props.show ? null : "wrapperInactive"
  const element = props.data
  const sectors = props.sectors
  const [data, setData] = useState({
    ...element,
  })
  const [selectedType, setSelectedType] = useState({})

  const [image, setImage] = useState()
  const pricelist = props.pricelists
  const types = props.types
  useEffect(() => {
    if (data.type) {
      const index = types.findIndex(v => v.id == data.type)
      setSelectedType(types[index])
    }
  }, [data])
  useEffect(() => {
    const index = types.findIndex(val => data.type == val.id)
    index !== -1 ? setImage(`${types[index].path}`) : null
  }, [data])
  useEffect(() => {
    if (data.id === "new") {
      retrieveCode()
    }
  }, [props.data])
  useEffect(() => {
    const index = pricelist.findIndex(v => v.id == data.pricelist)
    if (index !== -1) {
      const selectedPl = pricelist[index]
      const obj = {
        label: selectedPl.name,
        value: selectedPl.id,
      }
      setData(el => ({ ...el, pricelist: obj }))
    }
    const indexSector = sectors.findIndex(v => v.id == data.sector)
    if (indexSector !== -1) {
      const selectedSec = sectors[indexSector]
      const obj = {
        label: selectedSec.name,
        value: selectedSec.id,
      }
      setData(el => ({ ...el, sector: obj }))
    }
  }, [data.id])

  const retrieveCode = async () => {
    const responseCode = await createCode()
    setData(v => ({ ...v, qrcode: responseCode.data }))
  }
  const handleSubmit = async () => {
    const isEnviornmentIndex = types.findIndex(v => v.id == data.type)
    const isEnvironment = types[isEnviornmentIndex].isEnvironment == 1

    setData(v => ({
      ...v,
      number: !isEnvironment ? parseInt(data.number) : "empty",
    }))
    props.setDefaultProps({
      type: data.type,
      sector: data.sector,
      number: !isEnvironment ? parseInt(data.number) : "empty",
    })
    if (selectedType.isUmbrella == 1 && !data.sector.value) {
      return alert("Inserisci un listino e settore valido")
    }
    let isClear = true
    for (let i = 0; i < data.width; i++) {
      for (let c = 0; c < data.height; c++) {
        let index = props.displacements.findIndex(
          el => el.y == data.y + c && el.x == data.x + i && data.id !== el.id
        )
        if (index !== -1) {
          isClear = false
        }
      }
    }

    if (!isClear) {
      return alert(
        "Ci sono degli elementi che vanno in conflitto con l'elemento, eliminali e riprova"
      )
    } else {
      let response
      if (data.id == "new") {
        response = await putDisplacement(data)
      } else {
        response = await patchDisplacement(data)
      }
      if (response.status) {
        props.onHide()
        props.reloadData()
      }
    }
    /*    props.updateData(val => {
      let isClear = true
      for (let i = 0; i < data.width; i++) {
        for (let c = 0; c < data.height; c++) {
          let index = val.findIndex(
            el => el.y == data.y + c && el.x == data.x + i && data.id !== el.id
          )
          if (index !== -1) {
            isClear = false
          }
        }
      }

      if (!isClear) {
        alert(
          "Ci sono degli elementi che vanno in conflitto con l'elemento, eliminali e riprova"
        )
        setData(element)
        return [...val]
      } else {
        const indexExists = val.findIndex(
          tc => tc.x == data.x && tc.y == data.y
        )
        props.setDifferent()

        if (data.id == "new") {
          props.appendData(v => ({ ...v, append: [...v.append, data] }))
        } else {
          props.appendData(v => ({ ...v, update: [...v.update, data] }))
        }
        if (indexExists !== -1) {
          val[indexExists] = data
          const resp = val.filter(el => el)
          props.onHide()
          return resp
        } else {
          props.onHide()
          let resp = data
          resp.pricelist
            ? (resp.pricelist = resp.pricelist.value)
            : (resp.pricelist = null)
          resp.sector ? (resp.sector = resp.sector.value) : (resp.sector = null)

          return [...val, resp]
        }
      }
    }) */
  }

  const handleRemove = async () => {
    const { status } = await deleteDisplacement(data.id)
    if (status) {
      props.onHide()
      props.reloadData()
    }
  }

  const handleReset = async () => {
    props.setDefaultProps(v => ({
      hasCompiled: false,
      type: 0,
      sector: {
        label: "",
        value: 0,
      },
      number: 12,
    }))
    setData(v => ({ id: "new", x: v.x, y: v.y }))
  }

  useEffect(() => {
    setData(() => {
      let elem = element
      elem = {
        ...elem,
        width: element.width ? element.width : 1,
        height: element.height ? element.height : 1,
        id: element.id ? element.id : "new",
      }
      if (!element.id) {
        elem = {
          ...elem,
          ...props.defaultProps,
          number: parseInt(props.defaultProps.number) + 1,
        }
      }
      console.log(elem)
      return elem
    })
  }, [props.data])
  useEffect(() => {
    if (data.id == "new") {
      const i = types.findIndex(v => v.id == data.type)
      if (i !== -1) {
        const { width, height } = types[i]
        setData(v => ({ ...v, height: height, width: width }))
      }
    }
  }, [data.type])

  let body
  if (data && data.type) {
    body = (
      <>
        <div className="w-100 justify-content-center d-flex">
          <img src={image} className="w-50" />
        </div>
        {selectedType.isEnvironment == 0 ? (
          <>
            <label className="text-dark mt-2">Numero</label>
            <Input
              type="text"
              placeholder="Numero"
              value={data.number}
              onChange={e =>
                setData(val => ({ ...val, number: e.target.value }))
              }
            />
            {/* <label className="text-dark mt-2">Codice</label>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                placeholder="Codice"
                disabled
                value={data.qrcode}
                onChange={e =>
                  setData(val => ({ ...val, qrcode: e.target.value }))
                }
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary"
                  onClick={() => retrieveCode()}
                >
                  Genera
                </button>
              </div>
            </div> */}
          </>
        ) : null}
        <label className="text-dark mt-2">Larghezza</label>
        <Input
          type="number"
          placeholder="Larghezza"
          value={data.width}
          onChange={e =>
            setData(val => ({ ...val, width: parseInt(e.target.value) }))
          }
        />
        <label className="text-dark mt-2">Altezza</label>
        <Input
          type="number"
          placeholder="Altezza"
          value={data.height}
          onChange={e =>
            setData(val => ({ ...val, height: parseInt(e.target.value) }))
          }
        />
        {selectedType.isEnvironment == 0 ? (
          <>
            <label className="text-dark mt-2"></label>
            {/*             <Select
              value={data.pricelist}
              options={
                pricelist
                  ? pricelist.map(val => ({
                      label: val.name,
                      value: val.id,
                    }))
                  : 
                    null
              }
              onChange={e =>
                setData(dat => ({
                  ...dat,
                  pricelist: e,
                }))
              }
            ></Select> */}
            {selectedType.isCabin == 0 ? (
              <>
                {" "}
                <label className="text-dark mt-2">Settore</label>
                <Select
                  /* value={data.pricelist} */
                  value={data.sector}
                  options={
                    sectors
                      ? sectors.map(val => ({
                          label: val.name,
                          value: val.id,
                        }))
                      : /*                   <option key={val.id} value={val.id}>
                    {val.name}
                  </option> */
                        null
                  }
                  onChange={e =>
                    setData(dat => ({
                      ...dat,
                      sector: e,
                    }))
                  }
                ></Select>
              </>
            ) : null}
          </>
        ) : null}
        {selectedType.isUmbrella == 1 ? (
          <>
            {" "}
            <label className="text-dark mt-2">Clienti associabili</label>
            <Input
              type="number"
              value={data.max_customers || 8}
              min={1}
              onChange={e =>
                setData(v => ({ ...v, max_customers: e.target.value }))
              }
            />
          </>
        ) : null}
        {/* <select
          value={data.sector}
          onChange={e =>
            setData(dat => ({
              ...dat,
              sector: parseInt(e.target.value),
            }))
          }
        >
          {sectors
            ? sectors.map(val => (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              ))
            : null}
        </select> */}
        <CheckBox
          checked={data.online}
          onChange={e => setData(val => ({ ...val, online: e.target.checked }))}
          type="switch"
          className="text-dark mt-2"
          label="Online"
        />
      </>
    )
  } else {
    body = (
      <div className="d-flex flex-column align-items-center">
        <span className="text-dark">Inserisci un elemento</span>
        <hr style={{ height: "2px" }} className="w-100 text-dark"></hr>
        <ul className="w-100 p-0">
          {types.map((el, index) => (
            <li
              key={index}
              className={`d-flex align-items-center w-100 p-2 asideElement`}
              onClick={() => setData(val => ({ ...val, type: el.id }))}
            >
              <img src={`${el.path}`} height="50px" className="px-2" />
              <span>{el.name}</span>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <section className={`wrapper ${wrapper}  overflow-auto`}>
      <main className="bodyWrapper">
        <div className="w-75 pt-5 d-flex flex-column h-100">
          {body}
          <div className="d-flex flex-grow-1 justify-content-center align-items-end py-2">
            <Button
              variant="outline-secondary"
              className="mx-1"
              onClick={() => props.onHide()}
            >
              Annulla
            </Button>

            {element && element.type ? (
              <Button variant="danger" className="mx-1" onClick={handleRemove}>
                Rimuovi
              </Button>
            ) : null}
            {data.type ? (
              <Button
                variant="secondary"
                className="mx-1"
                onClick={handleSubmit}
                disabled={JSON.stringify(data) === JSON.stringify(element)}
              >
                Salva
              </Button>
            ) : null}
            {data ? (
              <Button
                variant="secondary"
                className="mx-1"
                onClick={handleReset}
              >
                Ripristina
              </Button>
            ) : null}
          </div>
        </div>
      </main>
    </section>
  )
}

export default Aside
