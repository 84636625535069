/* ANAGRAFICA SPIAGGIE */
export const GET_ANAGRAFICA = "GET_ANAGRAFICA"
export const GET_ANAGRAFICA_SUCCESS = "GET_ANAGRAFICA_SUCCESS"
export const GET_ANAGRAFICA_FAIL = "GET_ANAGRAFICA_FAIL"
export const GET_TAGS = "GET_TAGS"
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS"
export const GET_TAGS_FAIL = "GET_TAGS_FAIL"
export const GET_PAYMENT_OPTIONS = "GET_PAYMENT_OPTIONS"
export const GET_PAYMENT_OPTIONS_SUCCESS = "GET_PAYMENT_OPTIONS_SUCCESS"
export const GET_PAYMENT_OPTIONS_FAIL = "GET_PAYMENT_OPTIONS_FAIL"
