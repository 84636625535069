import {
  getAllergens,
  getIngredients,
  getServiceCategories,
  getUm,
  getVariants,
  getVariantsWithPrice,
  putVariant,
  loadProducts,
  getExtras,
  putorPatchProduct,
  putPhotoToProduct,
  loadSubCategories,
  updateExtrasOrdering,
  updateVariantsOrdering,
  deleteProductVariant,
  appendProductVariant,
  createVariant,
} from "helpers/fakebackend_helper"
import React, { useEffect, useState } from "react"
import CreatableSelect from "react-select/creatable"
import {
  Container,
  Card,
  Row,
  Col,
  Input,
  CardBody,
  InputGroup,
  Button,
  InputGroupText,
} from "reactstrap"
import Select from "react-select"
import ModalVWP from "components/bar/ModalVWP"
import ModalIngredients from "components/bar/ModalIngredients"
import ModalVariant from "components/bar/ModalVariant"
import {
  BsArrowDown,
  BsArrowDownCircle,
  BsArrowUp,
  BsArrowUpCircle,
  BsCurrencyEuro,
  BsPencilSquare,
  BsPlus,
  BsTrash,
  BsX,
  BsXCircle,
} from "react-icons/bs"
import ModalExtra from "components/bar/ModalExtra"
import axios from "axios"
import Modalcategories from "components/bar/Modalcategories"
import Newcategory from "components/bar/Newcategory"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import { useRef } from "react"

const Product = () => {
  const [addVariantDisabled, setAddVariantDisabled] = useState(false)
  const selectVariant = useRef(null)
  useEffect(() => {
    checkIfUserHasPurchasedModule(6)
  }, [])
  const [selectedVariant, setSelectedVariant] = useState({
    label: "",
    value: 0,
  })
  const [photo, setPhoto] = useState(null)
  const [id, setId] = useState(false)
  const [variants, setVariants] = useState([])
  const [extraSelected, setExtraSelected] = useState({
    id: false,
    name: "",
    price: 0,
  })

  const [categories, setCategories] = useState([])
  const [allergens, setAllergens] = useState([])
  const [ingredients, setIngredients] = useState([])
  const [extras, setExtras] = useState([])
  const [ingredientsModal, setIngredientsModal] = useState(false)
  const [product, setProduct] = useState({
    variants_with_price: [],
    extras: [],
    variants: [],
    max_variants_to_choose: 0,
    day_quantity: 99999,
    quantity: 10000000,
    price: 0,
  })

  const [updateCategory, setUpdateCategory] = useState(false)
  const [modalVariants, setModalVariants] = useState(false)
  const [um, setUm] = useState([])
  const [modalExtras, setModalExtras] = useState(false)
  const [modalVariantsWithPrice, setModalVariantsWithPrice] = useState(false)
  const [modalCategories, setModalcategories] = useState(false)
  const [newModalCategory, setNewModalCategory] = useState(false)
  const handleCreateVariant = async name => {
    setAddVariantDisabled(true)
    const data = await createVariant({ name: name })
    console.log(data)
    if (data.status) {
      await loadVariants()
      setSelectedVariant({ label: name, value: data.id })
      setAddVariantDisabled(false)
    } else {
      alert("Errore")
      setAddVariantDisabled(false)
    }
  }
  const handleSubmit = async () => {
    let req = product
    if (!product.subcategories || product.subcategories.length == 0) {
      alert("Inserisci almeno una categoria")
      return
    }

    const data = await putorPatchProduct(req)
    console.log(photo)
    if (data.id) {
      let formData = new FormData()
      formData.append("photo", photo)
      formData.append("id", data.id)
      const resp = await axios.post(
        "https://dev2.dasporto.net/newcalls/putPhotoToProduct.php",
        formData
      )
      if (resp.data.status) {
        alert("Prodotto aggiornato o inserito con successo")
        window.location.href = "menu"
      } else {
        alert("Errore non riconosciuto")
      }
    }
  }
  const appendVariantWithPrice = (id, label, price) => {
    setProduct(v => ({
      ...v,
      variants_with_price: [
        ...v.variants_with_price,
        { value: id, label: label, price: price },
      ],
    }))
  }
  const getProduct = async id => {
    if (id) {
      const data = await loadProducts({ product_id: id })
      if (data.length > 0) {
        let formattedProduct = data[0]
        formattedProduct.subcategories = formattedProduct.subcategories.map(
          v => ({ value: v.id, label: v.name })
        )
        formattedProduct.ingredients = formattedProduct.ingredients.map(v => ({
          value: v.id,
          label: v.name,
        }))
        formattedProduct.variants = formattedProduct.variants.map(v => ({
          association_id: v.association_id,
          value: v.id,
          label: v.name,
        }))
        setProduct(formattedProduct)
      } else {
        alert("ID errato")
      }
    }
  }
  const changeVWPPrice = index => {
    //change variants with price's price based on its index
    const newPrice = prompt("Inserisci il nuovo prezzo")
    if (newPrice) {
      setProduct(v => {
        let variants_with_price = v.variants_with_price
        variants_with_price[index].price = newPrice
        return { ...v, variants_with_price: variants_with_price }
      })
    }
  }
  const loadExtras = async () => {
    const data = await getExtras()
    setExtras(data)
  }
  const loadIngredients = async () => {
    const data = await getIngredients()
    setIngredients(data)
  }
  const loadVariants = async () => {
    const data = await getVariants()
    setVariants(data)
  }
  const loadAllergens = async () => {
    const data = await getAllergens()
    setAllergens(data)
  }
  const loadCategories = async () => {
    const data = await getServiceCategories()
    console.log(data)
    setCategories(data)
  }
  const loadUm = async () => {
    const data = await getUm()
    setUm(data)
  }

  const handleMovement = async (operation, extra_id, product_id) => {
    const data = await updateExtrasOrdering({
      operation: operation,
      id: extra_id,
      product_id: product_id,
    })
    if (data.status) {
      const elementToChangeIndex = product.extras.findIndex(
        v => v.patch_id === extra_id
      )
      if (operation === 1) {
        setProduct(v => {
          const ext1 = v.extras.map(v => v)
          const ext2 = ext1[elementToChangeIndex]
          const prevIndex =
            (elementToChangeIndex - 1 + ext1.length) % ext1.length
          ext1[elementToChangeIndex] = ext1[prevIndex]
          ext1[prevIndex] = ext2
          return { ...v, extras: ext1 }
        })
      } else {
        setProduct(v => {
          const ext1 = v.extras.map(v => v)
          const ext2 = ext1[elementToChangeIndex]
          const nextIndex = (elementToChangeIndex + 1) % ext1.length
          ext1[elementToChangeIndex] = ext1[nextIndex]
          ext1[nextIndex] = ext2
          return { ...v, extras: ext1 }
        })
      }
    }
  }
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get("id")
    setId(id)
  }, [])
  console.log(product)
  useEffect(() => {
    if (id) {
      getProduct(id)
    }
  }, [id])
  const getSubCategories = async () => {
    const data = await loadSubCategories()
    console.log(data)
  }

  const changePrice = async id => {
    const price = await prompt("Inserisci il prezzo")
    console.log(id)
    setProduct(v => {
      const ext1 = v.extras.map(v => {
        if (v.patch_id === id) {
          console.log(v)
          return { ...v, price: price }
        } else {
          return v
        }
      })
      console.log(ext1)

      return { ...v, extras: ext1 }
    })
  }

  useEffect(() => {
    loadUm()
    loadIngredients()
    loadAllergens()

    loadVariants()
    loadExtras()
  }, [])
  useEffect(() => {
    loadCategories()
  }, [modalCategories, newModalCategory])

  const handleVWPMovement = async (operation, index) => {
    //if operation is 1, we need to set the element in index in index - 1
    if (operation == 1) {
      if (index == 0) {
        return
      } else {
        //replace index with index -1 and viceversa
        let newVWP = product.variants_with_price
        const currIndex = newVWP[index]
        const nextIndex = newVWP[index - 1]
        newVWP[index] = nextIndex
        newVWP[index - 1] = currIndex
        setProduct(v => ({ ...v, variants_with_price: newVWP }))
      }
    } else {
      if (index == newVWP.length - 1) {
        return
      } else {
        let newVWP = product.variants_with_price
        const currIndex = newVWP[index]
        const nextIndex = newVWP[index + 1]
        newVWP[index] = nextIndex
        newVWP[index + 1] = currIndex
        setProduct(v => ({ ...v, variants_with_price: newVWP }))
      }
    }
  }
  const handleVariantMovement = async (
    variant_id,
    product_id,
    operation,
    index
  ) => {
    if (product_id) {
      const response = await updateVariantsOrdering({
        operation: operation,
        id: variant_id,
        product_id: product_id,
      })

      if (response.status) {
        getProduct(id)
      }
    } else {
      //handle it locally, without calling the backend
      if (operation == 1) {
        if (index == 0) {
          return
        } else {
          //replace index with index -1 and viceversa
          let newVWP = product.variants
          const currIndex = newVWP[index]
          const nextIndex = newVWP[index - 1]
          newVWP[index] = nextIndex
          newVWP[index - 1] = currIndex
          setProduct(v => ({ ...v, variants: newVWP }))
        }
      } else {
        let newVWP = product.variants
        if (index == newVWP.length - 1) {
          return
        } else {
          let newVWP = product.variants
          const currIndex = newVWP[index]
          const nextIndex = newVWP[index + 1]
          newVWP[index] = nextIndex
          newVWP[index + 1] = currIndex
          setProduct(v => ({ ...v, variants: newVWP }))
        }
      }
    }
  }
  const deleteVariant = async (product_id, index) => {
    if (product.id) {
      const data = await deleteProductVariant({ id: product_id })
      if (data.status) {
        getProduct(id)
      }
    } else {
      //handle it locally, without calling the backend
      console.log(product.variants)
      setProduct(v => ({
        ...v,
        variants: v.variants.filter((v, i) => i !== index),
      }))
    }
  }

  const handleAppendVariant = async () => {
    //append variant to current data
    if (selectedVariant.value == 0) {
      return alert(
        "Seleziona dal menù a tendina una variante prima di continuare"
      )
    }
    setProduct(v => ({
      ...v,
      variants: [...v.variants, { value: selectedVariant.value }],
    }))

    /* const data = await appendProductVariant({
      product_id: id,
      variant_id: selectedVariant.value,
    })
    if (data.status) {
      getProduct(id)
    } */
  }
  return (
    <>
      <Newcategory
        triggerUpdateCategory={setUpdateCategory}
        show={newModalCategory}
        onHide={() => setNewModalCategory(false)}
      />
      <Modalcategories
        updateCategory={updateCategory}
        newcategory={() => setNewModalCategory(true)}
        onHide={() => setModalcategories(false)}
        show={modalCategories}
        refresh={loadCategories}
      />
      <ModalExtra
        onHide={() => setModalExtras(false)}
        show={modalExtras}
        refresh={loadExtras}
      />
      <ModalVariant
        onHide={() => setModalVariants(false)}
        show={modalVariants}
        refresh={loadVariants}
      />
      <ModalIngredients
        onHide={() => setIngredientsModal(false)}
        show={ingredientsModal}
        refresh={loadIngredients}
      />
      <ModalVWP
        onHide={() => setModalVariantsWithPrice(false)}
        show={modalVariantsWithPrice}
        append={appendVariantWithPrice}
      />
      <ModalVariant onHide={() => setModalExtras(false)} />
      <div className="page-content">
        <Container fluid={true}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <label>Prodotto</label>
                  <Input
                    value={product.name}
                    onChange={e =>
                      setProduct(v => ({ ...v, name: e.target.value }))
                    }
                  />
                </Col>
                <Col>
                  <label>Descrizione</label>
                  <Input
                    value={product.description}
                    onChange={e =>
                      setProduct(v => ({ ...v, description: e.target.value }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label>Prezzo</label>
                  <InputGroup>
                    <InputGroupText>€</InputGroupText>
                    <Input
                      value={product.price}
                      type="number"
                      onChange={e =>
                        setProduct(v => ({ ...v, price: e.target.value }))
                      }
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <div className="d-flex justify-content-between">
                    <label>Quantità tot.</label>
                    <div className="mx-2">
                      <input
                        type="checkbox"
                        checked={
                          product.quantity == 10000000 ||
                          product.quantity == "NON DEFINITA"
                        }
                        onClick={e =>
                          !e.target.checked
                            ? setProduct(v => ({
                                ...v,
                                quantity: 10000000,
                              }))
                            : setProduct(v => ({ ...v, quantity: 1 }))
                        }
                        className="form-check-input"
                      />
                      <label className="mb-0 mx-1">Non definita</label>
                    </div>
                  </div>
                  <Input
                    type="number"
                    disabled={
                      product.quantity == 10000000 ||
                      product.quantity == "NON DEFINITA"
                    }
                    value={
                      product.quantity == 10000000 ||
                      product.quantity == "NON DEFINITA"
                        ? ""
                        : product.quantity
                    }
                    onChange={e =>
                      setProduct(v => ({ ...v, quantity: e.target.value }))
                    }
                  />
                </Col>
                <Col>
                  <div className="d-flex justify-content-between">
                    <label>Quantità giorn.</label>
                    <div className="mx-2">
                      <input
                        type="checkbox"
                        checked={product.day_quantity == 99999}
                        onClick={e =>
                          !e.target.checked
                            ? setProduct(v => ({
                                ...v,
                                day_quantity: 99999,
                              }))
                            : setProduct(v => ({ ...v, day_quantity: 1 }))
                        }
                        className="form-check-input"
                      />
                      <label className="mb-0 mx-1">Non definita</label>
                    </div>
                  </div>
                  <Input
                    type="number"
                    disabled={product.day_quantity == 99999}
                    value={
                      product.day_quantity == 99999 ? "" : product.day_quantity
                    }
                    onChange={e =>
                      setProduct(v => ({ ...v, day_quantity: e.target.value }))
                    }
                  />
                </Col>
                <Col>
                  <div className="d-flex justify-content-between">
                    <label>Foto</label>
                  </div>
                  {typeof product.photo == "string" ? (
                    <InputGroup>
                      <Button
                        onClick={() => {
                          console.log("caca")
                          setPhoto(null)
                          setProduct(v => ({ ...v, photo: null }))
                        }}
                      >
                        <BsTrash />
                      </Button>
                      <Input value={product.photo} disabled />
                    </InputGroup>
                  ) : (
                    <Input
                      type="file"
                      onChange={e => setPhoto(e.target.files[0])}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label>Unità di misura</label>
                  <select
                    className="form-select"
                    value={product.um}
                    onChange={e =>
                      setProduct(v => ({ ...v, um: e.target.value }))
                    }
                  >
                    {um.map((v, i) => (
                      <option key={i} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col>
                  <label>
                    Parti che il cliente può rimuovere a piacimento (es.
                    ingredienti)
                  </label>
                  <div className="d-flex w-100">
                    <Select
                      isMulti
                      className="w-100"
                      value={product.ingredients}
                      onChange={e =>
                        setProduct(v => ({ ...v, ingredients: e }))
                      }
                      options={ingredients.map(v => ({
                        label: v.name,
                        value: v.id,
                      }))}
                    />
                    <Button
                      className="mx-1 w-50"
                      onClick={() => setIngredientsModal(true)}
                    >
                      Nuovo
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={6}>
                  <label>Varianti</label>
                  <div className="">
                    <div className="d-flex w-100 mb-3">
                      <CreatableSelect
                        onCreateOption={handleCreateVariant}
                        isClearable
                        formatCreateLabel={inputValue => `Crea "${inputValue}"`}
                        value={selectedVariant}
                        onChange={e => setSelectedVariant(e)}
                        className="w-50"
                        options={variants.map(v => {
                          return {
                            value: v.id,
                            label: v.name,
                          }
                        })}
                      />
                      <Button
                        className="mx-1"
                        disabled={addVariantDisabled}
                        onClick={() => handleAppendVariant()}
                      >
                        Aggiungi
                      </Button>
                    </div>
                    <ul>
                      {product &&
                        product.variants &&
                        product.variants.map((v, i) => {
                          const variant = variants.find(el => v.value == el.id)

                          return (
                            <li key={v.id}>
                              {variant.name}
                              <BsTrash
                                className="mx-1 text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  deleteVariant(v.association_id, i)
                                }
                                size={16}
                              />
                              <BsArrowUpCircle
                                className="mx-1 text-success"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleVariantMovement(
                                    v.association_id,
                                    product.id,
                                    1,
                                    i
                                  )
                                }
                                size={16}
                              />
                              <BsArrowDownCircle
                                className="mx-1 text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleVariantMovement(
                                    v.association_id,
                                    product.id,
                                    0,
                                    i
                                  )
                                }
                                size={16}
                              />
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </Col>
                <Col>
                  <label>
                    Numero massimo di varianti selezionabili dal cliente
                  </label>
                  <div className="d-flex">
                    <Input
                      type="number"
                      value={product.max_variants_to_choose}
                      disabled={product.max_variants_to_choose == 999}
                      className="w-75"
                      onChange={e =>
                        setProduct(v => ({
                          ...v,
                          max_variants_to_choose: e.target.value,
                        }))
                      }
                    />

                    <div className="mx-1">
                      <div>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={product.max_variants_to_choose == 999}
                          onClick={() => {
                            product.max_variants_to_choose == 999
                              ? setProduct(v => ({
                                  ...v,
                                  max_variants_to_choose: 0,
                                }))
                              : setProduct(v => ({
                                  ...v,
                                  max_variants_to_choose: 999,
                                }))
                          }}
                        />
                        <label className="mb-0 mx-1">Illimitate</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          disabled={product.max_variants_to_choose == 999}
                          checked={
                            product.variants_mandatory == 1 &&
                            product.max_variants_to_choose !== 999
                          }
                          onClick={() => {
                            product.variants_mandatory == 1
                              ? setProduct(v => ({
                                  ...v,
                                  variants_mandatory: 0,
                                }))
                              : setProduct(v => ({
                                  ...v,
                                  variants_mandatory: 1,
                                }))
                          }}
                        />
                        <label className="mb-0 mx-1">Obbligatorie</label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label>Varianti con prezzo</label>
                  <div className="d-flex w-100">
                    <div>
                      <Button onClick={() => setModalVariantsWithPrice(true)}>
                        Nuovo
                      </Button>
                    </div>
                    <div className="d-flex flex-column">
                      <ul>
                        {product.variants_with_price &&
                          product.variants_with_price.map((v, i) => {
                            return (
                              <li key={i}>
                                {v.label} €{v.price}
                                <BsTrash
                                  size={15}
                                  className="text-primary mx-3"
                                  onClick={() =>
                                    setProduct(v => {
                                      const variants_with_price =
                                        v.variants_with_price.filter(
                                          (e, c) => c !== i
                                        )
                                      return { ...v, variants_with_price }
                                    })
                                  }
                                />
                                <BsPencilSquare
                                  size={15}
                                  className="text-warning"
                                  onClick={() => changeVWPPrice(i)}
                                />
                                <BsArrowUpCircle
                                  className="mx-1 text-success"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleVWPMovement(1, i)}
                                  size={16}
                                />
                                <BsArrowDownCircle
                                  className="mx-1 text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleVWPMovement(0, i)}
                                  size={16}
                                />
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col>
                  <label>Categoria prodotto</label>
                  <div className="d-flex  w-100">
                    <Select
                      isMulti
                      className="w-100"
                      value={product.subcategories}
                      onChange={e =>
                        setProduct(v => ({ ...v, subcategories: e }))
                      }
                      options={categories.map(v => ({
                        label: v.name,
                        value: v.id,
                      }))}
                    />
                    <Button
                      className="mx-1 w-50"
                      onClick={() => setModalcategories(true)}
                    >
                      Gestisci categorie
                    </Button>
                  </div>
                  <small>
                    Nota: le carte di credito devono essere abilitate
                    contattandoci
                  </small>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label>Extra</label>
                  <div className="d-flex  w-100">
                    <Select
                      ref={selectVariant}
                      onChange={e =>
                        setExtraSelected(v => ({
                          ...v,
                          id: e.value,
                          name: e.label,
                        }))
                      }
                      className="w-100 mx-1"
                      options={extras.map(v => ({
                        value: v.id,
                        label: v.name,
                      }))}
                      value={{
                        label: extraSelected.name,
                        value: extraSelected.id,
                      }}
                    />
                    <InputGroup className="w-50">
                      <InputGroupText>€</InputGroupText>
                      <Input
                        type="number"
                        value={extraSelected.price}
                        onChange={e =>
                          setExtraSelected(v => ({
                            ...v,
                            price: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>

                    <Button
                      color="primary"
                      className="mx-1 "
                      onClick={() => {
                        setProduct(v => ({
                          ...v,
                          extras: [...v.extras, extraSelected],
                        }))
                        //clear extraSelected
                        setExtraSelected(v => ({
                          ...v,
                          id: false,
                          name: "",
                          price: 0,
                        }))
                      }}
                    >
                      Aggiungi
                    </Button>
                    <Button onClick={() => setModalExtras(true)}>Nuovo</Button>
                  </div>
                  <ul className="flex flex-col my-1">
                    {product.extras &&
                      product.extras.map((v, i) => (
                        <li key={i}>
                          <BsTrash
                            size={15}
                            style={{ cursor: "pointer" }}
                            className="text-primary mx-1"
                            onClick={() =>
                              setProduct(v => {
                                const extras = v.extras.filter(
                                  (e, c) => c !== i
                                )
                                return { ...v, extras }
                              })
                            }
                          />
                          <BsArrowUpCircle
                            className="mx-1 text-success"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleMovement(1, v.patch_id, product.id)
                            }
                            size={16}
                          />
                          <BsArrowDownCircle
                            className="mx-1 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleMovement(0, v.patch_id, product.id)
                            }
                            size={16}
                          />
                          <BsPencilSquare
                            size={16}
                            onClick={() => changePrice(v.patch_id)}
                            className="text-warning m-1"
                          />
                          {v.name} €{v.price}
                        </li>
                      ))}
                  </ul>
                </Col>
                <Col>
                  <label>Allergeni</label>
                  <div className="d-flex  w-100">
                    <Select
                      isMulti
                      className="w-100"
                      value={product.allergens}
                      options={allergens.map(v => ({
                        value: v.id,
                        label: v.name,
                      }))}
                      onChange={e => setProduct(v => ({ ...v, allergens: e }))}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="d-flex justify-content-end w-100">
            <Button onClick={handleSubmit} color="primary">
              Salva
            </Button>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Product
