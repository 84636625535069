import React, { useState, useEffect } from "react"
import { CardSubtitle, Col, Row } from "reactstrap"
import Chart from "react-apexcharts"
import {
  fetchBookedFields,
  mostBookedField,
  userWithMostBookings,
  getServiceBookingsPerTime,
  getMostBookedServices,
  userWithMostBookingsServices,
} from "helpers/fakebackend_helper"
import {
  Card,
  Button,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap"
import { checkIfUserHasPurchasedModule } from "helpers/functions"


export default function Dashboard() {
  const [bookedFields, setBookedFields] = useState([])
  const [mostBookedFields, setMostBookedFields] = useState([])
  const [times, setTimes] = useState([])
  const [userWithMostBookingsValue, setUserWithMostBookings] = useState([])
  useEffect(() => {
    checkIfUserHasPurchasedModule(5)
  }, [])
  useEffect(() => {
    const fetchMaxBookedFields = async () => {
      const bookedFieldsFromServer = await getServiceBookingsPerTime()
      setTimes(bookedFieldsFromServer.map(v => v.time))
      let ids = []
      bookedFieldsFromServer.forEach(v => {
        v.fields.forEach(c => {
          const foundId = ids.findIndex(e => c.id === e.id)
          if (foundId === -1) {
            ids.push({ id: c.id, name: c.name, times: [] })
          }
        })
      })

      bookedFieldsFromServer.forEach((v, coun) => {
        const rawIds = ids.map(v => v.id)
        v.fields.forEach(c => {
          ids.forEach((v, i) => {
            if (v.id == c.id) {
              ids[i].times[coun] = c.total_bookings
              rawIds.splice(rawIds.indexOf(c.id), 1)
            }
          })
        })
        rawIds.forEach(v => {
          ids.forEach((v, i) => {
            ids[i].times[coun] = 0
          })
        })
      })

      setMostBookedFields(ids)
    }
    const getBookedFields = async () => {
      const bookedFieldsFromServer = await getMostBookedServices()
      setBookedFields(bookedFieldsFromServer)
    }
    const fetchUserWithMostBookings = async () => {
      const response = await userWithMostBookingsServices()
      //order it from the biggest "conta" and limit it to the first 10
      response.sort((a, b) => b.conta - a.conta)
      response.splice(30)

      setUserWithMostBookings(response)
    }
    getBookedFields()
    fetchUserWithMostBookings()
    fetchMaxBookedFields()
  }, [])

  const data = {
    options: {},
    series: bookedFields.map(v => v.conta),
    labels: bookedFields.map(v => v.field_name),
  }

  const mostBookedData = {
    options: {},
    series: mostBookedFields.map(v => v.conta),
    labels: mostBookedFields.map(v => v.field_name),
  }

  console.log(mostBookedFields)

  return (
    <div className="page-content">
      <Row>
        <Col xs={6}>
          <Card className="h-100">
            <CardBody>
              <CardTitle>Totale prenotazioni</CardTitle>
              <CardSubtitle>
                La percentuale e numero totale di prenotazioni per campo
              </CardSubtitle>

              <Chart
                options={{
                  labels: data.labels,
                }}
                series={data.series}
                type="donut"
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={6}>
          <Card className="h-100">
            <CardBody>
              <CardTitle>Orari più prenotati</CardTitle>
              <CardSubtitle>Le ore più popolari per ogni campo</CardSubtitle>

              <Chart
                options={{
                  xaxis: {
                    categories: times,
                  },
                  stroke: {
                    curve: "smooth",
                  },
                }}
                series={mostBookedFields.map(v => ({
                  name: v.name,
                  data: v.times,
                }))}
                type="line"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col xs={12}>
          <Card className="h-100 ">
            <CardBody>
              <CardTitle>Utenti con più prenotazioni</CardTitle>
              <CardSubtitle>
                I 30 utenti con più prenotazioni in questo stabilimenti.
              </CardSubtitle>

              <Chart
                options={{
                  xaxis: {
                    categories: userWithMostBookingsValue.map(v => v.name),
                  },
                }}
                series={[{ data: userWithMostBookingsValue.map(v => v.conta) }]}
                type="bar"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}