import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  retrieveCategories,
  retrieveSportImages,
  retrieveSports,
  updateField,
  updateService,
} from "helpers/fakebackend_helper"
import { CheckBox } from "pages/Dashboard/LatestTranactionCol"
import {
  BsCheck,
  BsCheck2Circle,
  BsCheckCircleFill,
  BsCurrencyEuro,
} from "react-icons/bs"

const Serviceedit = ({ show, onHide, data, handleUpdate }) => {
  const [enabled, setEnabled] = useState(false)
  const [slots, setSlots] = useState([])
  const [sports, setSports] = useState([])
  const [formData, setFormData] = useState({})
  const [sportImages, setSportImages] = useState([])
  const [pages, setPages] = useState({
    amount: 1,
    current: 1,
  })
  console.log(data)
  console.log(sports)
  useEffect(() => {
    const category = sports.find(v => v.id == data.sports)
    if (category) {
      const obj = { value: category.id, label: category.name }
      let state = { ...data, sports: obj }
      if (state.consecutive == 1) {
        let arr = []
        console.log(state.max_consecutive)
        for (let c = 0; c < state.max_consecutive; c++) {
          arr.push(state[`diurnal_price${c == 0 ? "" : `_${c + 1}`}`])
        }
        setSlots(arr)
      }
      setFormData({ ...data, sports: obj })
    } else {
      setFormData({ ...data, sports: {} })
    }
  }, [data])
  const loadFields = async () => {
    const data = await retrieveCategories()
    setSports(data)
  }
  useEffect(() => {
    loadFields()
  }, [])

  useEffect(() => {
    setPages(v => ({ ...v, amount: Math.ceil(sportImages.length / 3) }))
  }, [sportImages.length])

  const handleSubmit = async () => {
    let request = formData
    request.sports = request.sports.value
    if (formData.consecutive == 1) {
      slots.forEach(
        (v, i) => (request[`diurnal_price${i == 0 ? "" : `_${i + 1}`}`] = v)
      )
    }
    const response = await updateService(request)
    if (response.status) {
      await handleUpdate()
      setFormData({})
      onHide()
      alert("Campo modificato")
    }
  }

  const pageButtons = []
  for (let c = 1; c <= pages.amount; c++) {
    pageButtons.push(
      <Button
        onClick={() => setPages(el => ({ ...el, current: c }))}
        color={pages.current == c ? "primary" : "outline-primary"}
      >
        {c}
      </Button>
    )
  }

  return (
    <Modal isOpen={show} toggle={onHide} size="lg">
      <ModalHeader>Gestione servizio</ModalHeader>
      {formData && (
        <ModalBody>
          <Container>
            <h1 className="mt-1">Informazioni generali</h1>
            <label>Nome servizio</label>
            <Input
              value={formData.name}
              onChange={e => setFormData(v => ({ ...v, name: e.target.value }))}
            />
            {/*  <label>Descrizione campo</label>
              <Input
                value={formData.description}
                onChange={e =>
                  setFormData(v => ({ ...v, description: e.target.value }))
                }
              /> */}
            {/*             <label>Foto</label>
            <div className="p-2 rounded bg-light justify-content-around d-flex position-relative">
              {sportImages
                .filter(
                  (v, i) =>
                    i >= (pages.current - 1) * 3 && i < pages.current * 3
                )
                .map((v, i) => (
                  <div
                    key={i}
                    className="position-relative justify-content-center align-items-center d-flex mx-1 overflow-hidden rounded"
                  >
                    <img
                      src={v.path}
                      height={"150px"}
                      onClick={() =>
                        setFormData(f => ({ ...f, photo: v.path }))
                      }
                    />
                    {formData.photo == v.path ? (
                      <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center backdropBlur">
                        <BsCheckCircleFill size={28} className="text-white " />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
            <InputGroup className="my-2">{pageButtons}</InputGroup> */}
            <Row className="mt-2 mb-2">
              <Col>
                <label>Categoria</label>
                <Select
                  value={formData.sports}
                  onChange={e => setFormData(v => ({ ...v, sports: e }))}
                  options={sports.map(v => ({ value: v.id, label: v.name }))}
                />
              </Col>
              <Col>
                <label>Apertura prenotazioni giornaliero</label>
                <Input
                  type="time"
                  value={formData.opening_time_booking}
                  onChange={e =>
                    setFormData(v => ({
                      ...v,
                      opening_time_booking: e.target.value,
                    }))
                  }
                />
              </Col>
            </Row>
            {/* <h1 className="mt-1">Slot</h1> */}
            <Row>
              <Col>
                <label>Prenotazione per:</label>
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      className="mx-1"
                      onClick={e =>
                        e.target.checked
                          ? setFormData(v => ({ ...v, booking_order: 2 }))
                          : setFormData(v => ({ ...v, booking_order: 1 }))
                      }
                      checked={formData.booking_order == 1}
                    />
                    <label>Ombrellone</label>
                  </div>
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      className="mx-1"
                      onClick={e =>
                        e.target.checked
                          ? setFormData(v => ({ ...v, booking_order: 1 }))
                          : setFormData(v => ({ ...v, booking_order: 2 }))
                      }
                      checked={formData.booking_order == 2}
                    />
                    <label>Utente</label>
                  </div>
                </div>
              </Col>
              <Col>
                <label>Massimo prenotazioni (0 se illimitato)</label>
                <InputGroup>
                  <Input
                    type="number"
                    min={0}
                    onChange={e =>
                      setFormData(v => ({
                        ...v,
                        max_bookings_per_umbrella: e.target.value,
                      }))
                    }
                    value={formData.max_bookings_per_umbrella}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <label> </label>
                <div className="d-flex mt-3">
                  <Input
                    type="checkbox"
                    className="mx-"
                    onClick={() =>
                      formData.consecutive == 1
                        ? setFormData(el => ({ ...el, consecutive: 0 }))
                        : setFormData(el => ({ ...el, consecutive: 1 }))
                    }
                    checked={formData.consecutive == 1}
                  />
                  <label className="mb-0"> Gestisci slot multipli</label>
                </div>
              </Col>
              <Col>
                <label>Numero slot</label>

                <select
                  value={formData.max_consecutive}
                  className={`form-select`}
                  disabled={!formData.consecutive == 1}
                  onChange={e => {
                    setFormData(v => ({
                      ...v,
                      max_consecutive: e.target.value,
                    }))
                    let arr = []
                    for (let i = 0; i < e.target.value; i++) {
                      if (slots[i]) {
                        arr.push(slots[i])
                      } else arr.push(0)
                    }
                    setSlots(arr)
                  }}
                >
                  <option>2</option>
                  <option>3</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <label>Durata slot</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={formData.duration}
                    onChange={e =>
                      setFormData(v => ({ ...v, duration: e.target.value }))
                    }
                  />
                  <InputGroupText>Min.</InputGroupText>
                </InputGroup>
              </Col>
              <Col className="mb-2">
                <label>Costo a slot</label>
                <InputGroup>
                  <Input
                    disabled={formData.consecutive == 1}
                    type="number"
                    value={formData.diurnal_price}
                    onChange={e =>
                      setFormData(v => ({
                        ...v,
                        diurnal_price: e.target.value,
                      }))
                    }
                  />
                  <InputGroupText>
                    <BsCurrencyEuro />
                  </InputGroupText>
                </InputGroup>
              </Col>
            </Row>
            {formData.consecutive == 1 && (
              <Row className="mt-1">
                {slots.map((v, i) => (
                  <Col key={i}>
                    <label>Slot {i + 1}</label>
                    <InputGroup>
                      <Input
                        type="number"
                        value={slots[i]}
                        onChange={e =>
                          setSlots(v => {
                            let el = v
                            el[i] = e.target.value
                            const newArr = el.filter(v => true)
                            return newArr
                          })
                        }
                      />
                      <InputGroupText>€</InputGroupText>
                    </InputGroup>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </ModalBody>
      )}
      <ModalFooter>
        <Button color="secondary" onClick={() => onHide()}>
          Annulla
        </Button>
        <Button color="primary" onClick={() => handleSubmit()}>
          Salva
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Serviceedit
