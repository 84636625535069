import { Input, Modal, Row, Col } from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { patchType, putSeason } from "helpers/fakebackend_helper"

const NewSeason = props => {
  const [userData, setUserData] = useState({
    name: "",
    from: "",
    to: "",
    fk_pricelist_id: false,
  })
  const handleSubmit = async () => {
    const fromDay = new Date(userData.from).getDate()
    const fromMonthIndex = new Date(userData.from).getMonth() + 1
    const toDay = new Date(userData.to).getDate()
    const toMonthIndex = new Date(userData.to).getMonth() + 1
    const data = {
      name: userData.name,
      from_day: fromDay,
      from_month: fromMonthIndex,
      to_day: toDay,
      to_month: toMonthIndex,
      fk_pricelist_id: userData.fk_pricelist_id,
    }
    const resp = await putSeason(data)
    if (resp.status) {
      await props.updateData()
      props.onHide()
      setUserData({ name: "", from: "", to: "", fk_pricelist_id: false })
    }
  }
  useEffect(() => {
    if (props.pricelistKey) {
      setUserData(v => ({ ...v, fk_pricelist_id: props.pricelistKey }))
    }
  }, [props.pricelistKey])
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Nuova stagione
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <label>Nome stagione</label>
            <Input
              value={userData.name}
              onChange={e => setUserData(v => ({ ...v, name: e.target.value }))}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <label>Da</label>
            <Input
              type="date"
              value={userData.from}
              onChange={e => setUserData(v => ({ ...v, from: e.target.value }))}
            />
          </Col>
          <Col>
            <label>A</label>
            <Input
              type="date"
              value={userData.to}
              onChange={e => setUserData(v => ({ ...v, to: e.target.value }))}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary "
        >
          Salva
        </button>
      </div>
    </Modal>
  )
}

export default NewSeason
