import { patchFieldTimeslot } from "helpers/fakebackend_helper"
import { useEffect, useState } from "react"
import React from "react"
import { BsCurrencyEuro } from "react-icons/bs"
import { Button, InputGroup, InputGroupText, Input } from "reactstrap"
const Tablerow = ({
  obj,
  handleUpdate,
  index,
  spliceIndex,
  id,
  day,
}) => {
  const [updateAvailable, setUpdateAvailable] = useState(true)
  const [data, setData] = useState(obj)
  const patchSlot = async obb => {
    obb.field_id = id
    obb.day = day
    const { status } = await patchFieldTimeslot(obb)
    if (status) {
      alert("Timeslot aggiornato")
      handleUpdate()
      setUpdateAvailable(true)
    } else {
      alert("Ci sono timeslots che si sovrappongono")
    }
  }
  useEffect(() => {
    if (data !== obj) {
      setUpdateAvailable(false)
    }
  }, [data])
  return (
    <tr>
      <td>
        <Input
          type="time"
          value={data.start_slot_time}
          onChange={e =>
            setData(v => ({ ...v, start_slot_time: e.target.value }))
          }
        />
      </td>
      <td>
        <Input
          type="time"
          value={data.end_slot_time}
          onChange={e =>
            setData(v => ({ ...v, end_slot_time: e.target.value }))
          }
        />
      </td>
      <td>
        <select
          className="form-select"
          value={data.type}
          onChange={e => setData(v => ({ ...v, type: e.target.value }))}
        >
          <option value={"diurno"}>diurno</option>
          <option value={"notturno"}>notturno</option>
        </select>
      </td>
      <td className="w-25">
        <InputGroup>
          <InputGroupText>
            <BsCurrencyEuro />
          </InputGroupText>
          <input
            className="form-control"
            type="number"
            value={data.price}
            onChange={e => setData(v => ({ ...v, price: e.target.value }))}
          ></input>
        </InputGroup>
      </td>
      <td>
        {obj.id == null ? (
          <Button onClick={() => patchSlot(data)} color="primary">
            Salva
          </Button>
        ) : (
          <Button
            color="warning"
            onClick={() => patchSlot(data)}
            disabled={updateAvailable}
          >
            Aggiorna
          </Button>
        )}

        <Button onClick={() => spliceIndex(data.id, index)} className="mx-1" color="danger">
          Elimina
        </Button>
      </td>
    </tr>
  )
}

export default Tablerow
