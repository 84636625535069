import {
  createVariantWithPrice,
  putIngredient,
} from "helpers/fakebackend_helper"
import React, { useEffect } from "react"
import { useState } from "react"
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

export default function ModalIngredients({ show, onHide, refresh }) {
  const HandleSubmit = async () => {
    const request = await putIngredient(data.name)
    if (request.id) {
      onHide()
      refresh()
    }
  }
  useEffect(() => {
    setData({ name: "" })
  }, [show])
  const [data, setData] = useState({ name: "" })
  return (
    <Modal isOpen={show} toggle={onHide}>
      <ModalHeader>Aggiungi un ingrediente</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <label>Nome</label>
            <Input
              type="text"
              value={data.name}
              onChange={e => setData(v => ({ ...v, name: e.target.value }))}
            />
          </Col>
        </Row>
      </ModalBody>

      {/* <ModalFooter>loipiò0poiòoiòpoiòopòopbnòo-p0v8i</ModalFooter>  <-- il contributo di giacomo*/}
      <ModalFooter>
        <Button onClick={() => onHide()}>Annulla</Button>
        <Button color="primary" onClick={HandleSubmit}>
          Salva
        </Button>
      </ModalFooter>
    </Modal>
  )
}
