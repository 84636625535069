import Popup from "components/bar/Popup"
import {
  changeProductStatus,
  deleteProduct,
  getAnagrafica,
  loadProducts,
  patchMenu,
  patchMenuVisibility,
} from "helpers/fakebackend_helper"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import React, { useEffect, useState } from "react"
import { FaCopy } from "react-icons/fa"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
} from "reactstrap"

const Menu = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(6)
  }, [])
  const [products, setProducts] = useState([])
  const [anagrafica, setAnagrafica] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [dots, setDots] = useState(".")
  const [filter, setFilter] = useState("")
  const [popupState, setPopupState] = useState(false)
  const loadAnagrafica = async () => {
    const data = await getAnagrafica()
    setAnagrafica(data)
  }

  const handleVisibilityChange = async (target, value, product_id) => {
    const response = await patchMenuVisibility({ target, value, product_id })

    if (response.status) {
      alert("Prodotto aggiornato")
      /* getProducts() */
      //instead of fetching the api, lets change the state of the product
      const newProducts = products.map(v => {
        if (v.id == product_id) {
          return {
            ...v,
            [target]: value,
          }
        } else {
          return v
        }
      })
      setProducts(newProducts)
    }
  }

  const showPopup = () => {
    console.log("ciao")
  }

  const updateMenu = async () => {
    const data = await patchMenu(anagrafica)
    if (data.status) {
      alert("Politiche di consegna aggiornate")
    }
  }
  const getProducts = async () => {
    setProducts([])
    setLoading(true)
    const data = await loadProducts()
    setLoading(false)
    setProducts(data)
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(prevDots => {
        if (prevDots.length >= 3) {
          return "."
        } else {
          return prevDots + "."
        }
      })
    }, 500)

    return () => clearInterval(intervalId)
  }, [])
  const handleDelete = async id => {
    const conf = confirm("Sei sicuro di voler eliminare il prodotto?")
    if (!conf) return
    const data = await deleteProduct(id)
    if (data == "ok") {
      alert("Prodotto eliminato")
      getProducts()
    }
  }
  const handleChangeStatus = async id => {
    const response = await changeProductStatus(id)
    if (response.status) {
      alert("Prodotto aggiornato")
      //change the products within the product state of given id
      const newProducts = products.map(v => {
        if (v.id == id) {
          return {
            ...v,
            active: v.active == 1 ? 0 : 1,
          }
        } else {
          return v
        }

        /*       getProducts() */
      })
      setProducts(newProducts)
    }
  }
  useEffect(() => {
    getProducts()
    loadAnagrafica()
  }, [])
  useEffect(() => {
    setFilteredProducts(products)
  }, [products])

  useEffect(() => {
    setFilteredProducts(
      products.filter(
        v =>
          v.name.toLowerCase().includes(filter.toLowerCase()) ||
          v.name.toLowerCase().includes(filter.toLowerCase()) ||
          v.subcategories.filter(v =>
            v.name.toLowerCase().includes(filter.toLowerCase())
          ).length > 0 ||
          v.description.toLowerCase().includes(filter.toLowerCase())
      )
    )
  }, [filter])

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Popup show={popupState} toggle={() => setPopupState(false)} />
        <Card>
          <CardBody>
            <h3>Impostazioni generiche</h3>
            <Row className="mb-2">
              <Col>
                <label>Costo servizio</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={anagrafica.service_charge}
                    onChange={e =>
                      setAnagrafica(v => ({
                        ...v,
                        service_charge: e.target.value,
                      }))
                    }
                  />
                  <InputGroupText>€</InputGroupText>
                </InputGroup>
              </Col>
              <Col>
                <label>Importo minimo per consegna</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={anagrafica.delivery_min_order}
                    onChange={e =>
                      setAnagrafica(v => ({
                        ...v,
                        delivery_min_order: e.target.value,
                      }))
                    }
                  />
                  <InputGroupText>€</InputGroupText>
                </InputGroup>
              </Col>
              <Col>
                <label>Importo minimo per consegna gratuita</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={anagrafica.limit_free_delivery}
                    onChange={e =>
                      setAnagrafica(v => ({
                        ...v,
                        limit_free_delivery: e.target.value,
                      }))
                    }
                  />
                  <InputGroupText>€</InputGroupText>
                </InputGroup>
              </Col>
              <Col className="d-flex flex-column">
                <label>&nbsp;</label>
                <Button color="primary" onClick={updateMenu}>
                  Aggiorna
                </Button>
              </Col>
              <Col className="d-flex flex-column">
                <label>&nbsp;</label>
                <Button color="secondary" onClick={() => setPopupState(true)}>
                  Configura popup
                </Button>
              </Col>
            </Row>
            <hr />
            <h3>Menù</h3>
            <Row className="w-50">
              <Col>
                <Input
                  placeholder="Cerca"
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                />{" "}
                {/* non funzia ancora */}
              </Col>
              <Col>
                <a href="/bar/manage">
                  <Button color="dark">Aggiungi</Button>
                </a>
              </Col>
            </Row>
            <Table>
              <thead>
                <tr>
                  <th>Prodotto</th>
                  <th>Prezzo</th>
                  <th>U.M.</th>
                  <th>Descrizione</th>
                  <th>Categoria</th>
                  <th>Quantità</th>
                  <th>Azioni</th>
                  <th>Visibilità</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((v, i) => (
                  <tr key={i}>
                    <td>{v.name}</td>
                    <td>€{v.price}</td>
                    <td>{v.um}</td>
                    <td>{v.description}</td>
                    <td>{v.subcategory}</td>
                    <td>{v.quantity}</td>
                    <td>
                      {v.active == 1 ? (
                        <Button
                          onClick={() => {
                            handleChangeStatus(v.id)
                          }}
                          color="warning"
                          className="m-1"
                        >
                          Disattiva
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            handleChangeStatus(v.id)
                          }}
                          color="success"
                          className="m-1"
                        >
                          Attiva
                        </Button>
                      )}

                      <a href={"manage?id=" + v.id}>
                        <Button color="light" className="m-1">
                          Gestisci
                        </Button>
                      </a>
                      <Button
                        color="danger"
                        className="m-1"
                        onClick={() => handleDelete(v.id)}
                      >
                        Elimina
                      </Button>
                    </td>
                    <td>
                      <div className="d-flex">
                        <Input
                          type="checkbox"
                          checked={v.at_umbrella}
                          onClick={e =>
                            handleVisibilityChange(
                              "at_umbrella",
                              !e.target.checked,
                              v.id
                            )
                          }
                        />
                        <label className="mx-2"> Ombrellone</label>
                      </div>
                      <div className="d-flex">
                        <Input
                          type="checkbox"
                          checked={v.at_table}
                          onClick={e =>
                            handleVisibilityChange(
                              "at_table",
                              !e.target.checked,
                              v.id
                            )
                          }
                        />
                        <label className="mx-2"> Tavolo</label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {loading && <p>Caricamento{dots}</p>}
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Menu
