import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  putField,
  putService,
  retrieveCategories,
  retrieveSportImages,
  retrieveSports,
  updateField,
} from "helpers/fakebackend_helper"
import { CheckBox } from "pages/Dashboard/LatestTranactionCol"
import {
  BsCheck,
  BsCheck2Circle,
  BsCheckCircleFill,
  BsCurrencyEuro,
} from "react-icons/bs"

const Createservice = ({ show, onHide, data, handleUpdate }) => {
  const [sports, setSports] = useState([])
  const [formData, setFormData] = useState({
    booking_order: 1,
    duration: 30,
    from: "07:00",
    to: "23:00",
    opening_time_booking: "07:00",
    nocturnal_time: "21:00",
    diurnal_price: 0,
    max_consecutive: 2,
    nocturnal_price: 0,
    max_bookings_per_umbrella: 0,
    sports: false,
    weeksday: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  })

  const [sportImages, setSportImages] = useState([])
  const [pages, setPages] = useState({
    amount: 1,
    current: 1,
  })
  const [slots, setSlots] = useState([])
  const loadFields = async () => {
    const data = await retrieveCategories()
    setSports(data)
  }
  const loadImages = async () => {
    const data = await retrieveSportImages()
    setSportImages(data)
  }
  useEffect(() => {
    loadFields()
    loadImages()
  }, [])

  useEffect(() => {
    setFormData({
      name: `Servizio ${data}`,
      booking_order: 1,
      duration: 30,
      max_consecutive: 2,
      from: "07:00",
      to: "23:00",
      opening_time_booking: "07:00",
      nocturnal_time: "21:00",
      diurnal_price: 0,
      nocturnal_price: 0,
      max_bookings_per_umbrella: 0,
      sports: false,
      weeksday: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    })
  }, [show])
  useEffect(() => {
    setPages(v => ({ ...v, amount: Math.ceil(sportImages.length / 3) }))
  }, [sportImages.length])

  const handleSubmit = async () => {
    let request = formData
    if (!request.sports) {
      return alert("Inserire almeno uno sport praticabile")
    }
    request.sports = request.sports.value
    request.weeksday = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ]
    slots.forEach(
      (v, i) => (request[`diurnal_price${i == 0 ? "" : `_${i + 1}`}`] = v)
    )
    const response = await putService(request)
    if (response.status) {
      await handleUpdate()
      setFormData({})
      onHide()
      alert("Servizio inserito")
    }
  }
  useEffect(() => {
    console.log("ciao")
    if (formData.consecutive == 1) {
      let arr = []
      for (let i = 0; i < formData.max_consecutive; i++) {
        if (slots[i]) {
          arr.push(slots[i])
        } else arr.push(0)
      }
      setSlots(arr)
    }
  }, [formData.consecutive, formData.max_consecutive])

  const pageButtons = []
  return (
    <Modal isOpen={show} toggle={onHide} size="lg">
      <ModalHeader>Gestione servizio</ModalHeader>
      {true && (
        <ModalBody>
          <Container>
            <h1 className="mt-1">Informazioni generali</h1>
            <label>Nome servizio</label>
            <Input
              value={formData.name}
              onChange={e => setFormData(v => ({ ...v, name: e.target.value }))}
            />
            <Row className="mt-2 mb-2">
              <Col>
                <label>Categoria</label>
                <Select
                  value={formData.sports}
                  onChange={e => setFormData(v => ({ ...v, sports: e }))}
                  options={sports.map(v => ({ value: v.id, label: v.name }))}
                />
              </Col>
              <Col>
                <label>Apertura prenotazioni giornaliero</label>
                <Input
                  type="time"
                  value={formData.opening_time_booking}
                  onChange={e =>
                    setFormData(v => ({
                      ...v,
                      opening_time_booking: e.target.value,
                    }))
                  }
                />
              </Col>
            </Row>
            {/* <h1 className="mt-1">Slot</h1> */}
            <Row>
              <Col>
                <label>Prenotazione per:</label>
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      className="mx-1"
                      onClick={e =>
                        e.target.checked
                          ? setFormData(v => ({ ...v, booking_order: 2 }))
                          : setFormData(v => ({ ...v, booking_order: 1 }))
                      }
                      checked={formData.booking_order == 1}
                    />
                    <label>Ombrellone</label>
                  </div>
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      className="mx-1"
                      onClick={e =>
                        e.target.checked
                          ? setFormData(v => ({ ...v, booking_order: 1 }))
                          : setFormData(v => ({ ...v, booking_order: 2 }))
                      }
                      checked={formData.booking_order == 2}
                    />
                    <label>Utente</label>
                  </div>
                </div>
              </Col>
              <Col>
                <label>Massimo prenotazioni (0 se illimitato)</label>
                <InputGroup>
                  <Input
                    type="number"
                    min={0}
                    onChange={e =>
                      setFormData(v => ({
                        ...v,
                        max_bookings_per_umbrella: e.target.value,
                      }))
                    }
                    value={formData.max_bookings_per_umbrella}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <label> </label>
                <div className="d-flex mt-3">
                  <Input
                    type="checkbox"
                    className="mx-1"
                    onClick={() =>
                      formData.consecutive == 1
                        ? setFormData(el => ({ ...el, consecutive: 0 }))
                        : setFormData(el => ({ ...el, consecutive: 1 }))
                    }
                    checked={formData.consecutive == 1}
                  />
                  <label className="mb-0"> Gestisci slot multipli</label>
                </div>
              </Col>
              <Col>
                <label>Numero slot</label>

                <select
                  value={formData.max_consecutive}
                  className={`form-select`}
                  disabled={!formData.consecutive == 1}
                  onChange={e => {
                    setFormData(v => ({
                      ...v,
                      max_consecutive: parseInt(e.target.value),
                    }))
                  }}
                >
                  <option>2</option>
                  <option>3</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <label>Durata slot</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={formData.duration}
                    onChange={e =>
                      setFormData(v => ({ ...v, duration: e.target.value }))
                    }
                  />
                  <InputGroupText>Min.</InputGroupText>
                </InputGroup>
              </Col>
              <Col className="mb-2">
                <label>Costo a slot</label>
                <InputGroup>
                  <Input
                    disabled={formData.consecutive == 1}
                    type="number"
                    value={formData.diurnal_price}
                    onChange={e =>
                      setFormData(v => ({
                        ...v,
                        diurnal_price: e.target.value,
                      }))
                    }
                  />
                  <InputGroupText>
                    <BsCurrencyEuro />
                  </InputGroupText>
                </InputGroup>
              </Col>
            </Row>
            {formData.consecutive == 1 && (
              <Row className="mt-1">
                {slots.map((v, i) => (
                  <Col key={i}>
                    <label>
                      Prezzo dello slot {i + 1} ({formData.duration * (i + 1)}{" "}
                      minuti){" "}
                    </label>
                    <InputGroup>
                      <Input
                        type="number"
                        min={0}
                        value={slots[i]}
                        onChange={e =>
                          setSlots(v => {
                            let el = v
                            el[i] = e.target.value
                            const newArr = el.filter(v => true)
                            return newArr
                          })
                        }
                      />
                      <InputGroupText>€</InputGroupText>
                    </InputGroup>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </ModalBody>
      )}
      <ModalFooter>
        <Button color="secondary" onClick={() => onHide()}>
          Annulla
        </Button>
        <Button color="primary" onClick={() => handleSubmit()}>
          Salva
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Createservice
