import NewSector from "components/anagrafiche/sectors/newSector"
import {
  deleteSeason,
  deleteSector,
  getSeasons,
  getSeasonsNew,
  getSectors,
} from "helpers/fakebackend_helper"
import Newseason from "components/anagrafiche/seasons/NewSeason"
import { React, useEffect, useState } from "react"
import { Button, Container, Table } from "reactstrap"
import EditSeason from "components/anagrafiche/seasons/EditSeason"
import EditSeasonNew from "components/anagrafiche/seasons/EditSeasonNew"
import NewSeasonNew from "components/anagrafiche/seasons/NewSeasonNew"
import { checkIfUserHasPurchasedModule } from "helpers/functions"

const Sectors = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])
  const [newModal, setNewModal] = useState(false)
  const [editModal, setEditModal] = useState({
    show: false,
    data: {
      name: "",
      date_from: "",
      date_to: "",
      id: false,
    },
  })
  const [seasons, setSeasons] = useState([])
  const loadSeasons = async () => {
    const data = await getSeasonsNew()
    setSeasons(data)
  }
  useEffect(() => {
    loadSeasons()
  }, [])
  const callDeleteSector = async id => {
    const conf = confirm("Sei sicuro di eliminare questo record?")
    if (conf) {
      const data = await deleteSeason(id)
      if (data.status) {
        loadSeasons()
      }
    }
  }
  return (
    <>
      <NewSeasonNew
        show={newModal}
        onHide={() => setNewModal(false)}
        updateData={() => loadSeasons()}
      />
      <EditSeasonNew
        show={editModal.show}
        onHide={async () => {
          const data = await getSeasonsNew()
          setEditModal(v => ({ ...v, show: false }))
          setTimeout(() => {
            setSeasons(data)
          }, 100)
        }}
        data={editModal.data}
        updateData={() => loadSeasons()}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Button onClick={() => setNewModal(true)}>Nuovo periodo</Button>
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Da</th>
                <th>A</th>
                <th>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {seasons.length > 0
                ? seasons.map((v, i) => (
                    <tr key={i}>
                      <td>{v.name}</td>
                      <td>
                        {v.periods.map((el, k) => (
                          <div key={k}>
                            {new Date(el.date_from).toLocaleDateString("it-IT")}
                          </div>
                        ))}
                      </td>
                      <td>
                        {v.periods.map((el, k) => (
                          <div key={k}>
                            {new Date(el.date_to).toLocaleDateString("it-IT")}
                          </div>
                        ))}
                      </td>
                      <td>
                        <button
                          className="btn btn-primary m-1"
                          onClick={e => {
                            const data = seasons.filter(el => el.id == v.id)
                            setEditModal({ show: true, data: data[0] })
                          }}
                        >
                          Modifica
                        </button>
                        <button
                          className="btn btn-danger m-1"
                          onClick={() => callDeleteSector(v.id)}
                        >
                          Elimina
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  )
}

export default Sectors
//webhook prova terzo
