import { Input, Modal, Row, Col } from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { patchSeason } from "helpers/fakebackend_helper"

const EditSeason = props => {
  const [maxDays, setMaxDays] = useState({
    from: 30,
    to: 30,
  })
  const [userData, setUserData] = useState({
    id: false,
    name: "",
    from_day: "",
    from_month: "",
    to_day: "",
    to_month: "",
    fk_pricelist_id: false,
  })
  const handleSubmit = async () => {
    const resp = await patchSeason(userData)
    console.log(resp)
    if (resp.status) {
      await props.updateData()
      props.onHide()
      setUserData({
        id: false,
        name: "",
        from_day: "",
        from_month: "",
        to_day: "",
        to_month: "",
        fk_pricelist_id: false,
      })
    }
  }
  useEffect(() => {
    var date = new Date()
    date.setMonth(userData.from_month - 1)
    var y = date.getFullYear()
    var m = date.getMonth()
    var lastDay = new Date(y, m + 1, 0)
    setMaxDays(v => ({ ...v, from: lastDay.getDate() }))
    setUserData(v => ({ ...v, from_day: 1 }))
  }, [userData.from_month])

  useEffect(() => {
    var date = new Date()
    date.setMonth(userData.to_month - 1)
    var y = date.getFullYear()
    var m = date.getMonth()
    var lastDay = new Date(y, m + 1, 0)
    setMaxDays(v => ({ ...v, to: lastDay.getDate() }))
    setUserData(v => ({ ...v, to_day: 1 }))
  }, [userData.to_month])
  
  useEffect(() => {
    if (props.data) {
      setUserData(props.data)
    }
  }, [props.data])
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Modifica stagione
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <label>Nome stagione</label>
            <Input
              value={userData.name}
              onChange={e => setUserData(v => ({ ...v, name: e.target.value }))}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <label className="h5">Da</label>
            <Row>
              <Col>
                <label>Giorno</label>
                <Input
                  type="number"
                  min={1}
                  max={maxDays.from}
                  value={userData.from_day}
                  onChange={e =>
                    setUserData(v => ({ ...v, from_day: e.target.value }))
                  }
                />
              </Col>
              <Col>
                <label>Mese</label>
                <Input
                  type="number"
                  min={1}
                  max={12}
                  value={userData.from_month}
                  onChange={e =>
                    setUserData(v => ({ ...v, from_month: e.target.value }))
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <label className="h5">A</label>
            <Row>
              <Col>
                <label>Giorno</label>
                <Input
                  type="number"
                  min={1}
                  max={maxDays.to}
                  value={userData.to_day}
                  onChange={e =>
                    setUserData(v => ({ ...v, to_day: e.target.value }))
                  }
                />
              </Col>
              <Col>
                <label>Mese</label>
                <Input
                  type="number"
                  min={1}
                  max={12}
                  value={userData.to_month}
                  onChange={e =>
                    setUserData(v => ({ ...v, to_month: e.target.value }))
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary "
        >
          Salva
        </button>
      </div>
    </Modal>
  )
}

export default EditSeason
