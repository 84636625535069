import Select from "react-select"
import React from "react"
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap"
import {
  getFieldPaymentMethods,
  getServicePaymentMethods,
  patchFieldPayments,
  patchServicePayments,
} from "helpers/fakebackend_helper"
import { useState } from "react"
import { useEffect } from "react"

export default function paymentServices() {
  const [payments, setPayments] = useState([])

  const loadData = async () => {
    const data = await getServicePaymentMethods()
    console.log(data)
    setPayments(data)
  }

  const handleSubmit = async () => {
    const data = await patchServicePayments({ payments: payments })
    if (data.status) {
      alert("Pagamenti aggiornati")
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h3>Pagamento servizi</h3>
                <p>
                  NOTA: per gestire pagamento con le carte di credito è
                  necessario contattare l'amministrazione.
                </p>
                <div className="d-flex w-100">
                  <Select
                    className="w-100"
                    isMulti
                    onChange={e => {
                      setPayments(e)
                    }}
                    value={payments}
                    options={[
                      { value: "cash", label: "Contanti" },
                      { value: "prep", label: "Prepagato" },
                    ]}
                  />
                  <Button
                    color="primary"
                    className="mx-1"
                    onClick={() => handleSubmit()}
                  >
                    Salva
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  )
}
