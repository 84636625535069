import React, { useState, useEffect, useRef } from "react"
import "../../assets/css/shore.css"
import { BsPlus } from "react-icons/bs"
import { AiOutlineMinus } from "react-icons/ai"
import axios from "axios"
import { Button } from "reactstrap"
/* import Aside from "@/components/Aside"; */
import { FaBroom } from "react-icons/fa"
import { useSelector, useDispatch } from "react-redux"
import Aside from "components/planner/Aside"
import UnsavedPopup from "components/planner/UnsavedPopup"
import { getPlaceDimensions, getTypes } from "helpers/fakebackend_helper"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
/* import {
    show,
    hide,
    setData,
    setPricelist,
    setSectors,
} from "../redux/aside/asideSlice"; */
/* import { setTypes } from "../redux/types/typesSlice"; */
/* import UnsavedPopup from "@/components/UnsavedPopup"; */

const MINCOLS = 20
const MINROWS = 15

const Grid = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])
  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const scrollableDivRef = useRef(null)
  const handleMouseDown = e => {
    setIsDown(true)
    setStartX(e.pageX - scrollableDivRef.current.offsetLeft)
    setStartY(e.pageY - scrollableDivRef.current.offsetTop)
    setScrollLeft(scrollableDivRef.current.scrollLeft)
    setScrollTop(scrollableDivRef.current.scrollTop)
  }

  const handleMouseLeave = () => {
    setIsDown(false)
  }

  const handleMouseUp = () => {
    setIsDown(false)
  }

  const handleMouseMove = e => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - scrollableDivRef.current.offsetLeft
    const y = e.pageY - scrollableDivRef.current.offsetTop
    const walkX = (x - startX) * 1
    const walkY = (y - startY) * 1
    scrollableDivRef.current.scrollLeft = scrollLeft - walkX
    scrollableDivRef.current.scrollTop = scrollTop - walkY
  }

  const targetElement = document.querySelector(".vertical-menu")
  const targetElementWidth = targetElement ? targetElement.clientWidth : 0
  const [cols, SetCols] = useState(MINCOLS) //cols and rows need to be taken from a configuration somewhere
  const [rows, SetRows] = useState(MINROWS)
  const [toUpdate, setToUpdate] = useState({
    append: [],
    delete: [],
    update: [],
  })
  const [layout, SetLayout] = useState([])
  const [displacedElements, SetDisplacedElements] = useState([])
  const [pricelists, setPricelist] = useState([])
  const [sectors, setSectors] = useState([])
  const [types, setTypes] = useState([])
  const [aside, setAside] = useState({ show: false, data: {} })
  const [different, setDifferent] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)

  const handleZoomIn = () => {
    setZoomLevel(prevZoomLevel => prevZoomLevel + 0.3)
  }

  const handleZoomOut = () => {
    setZoomLevel(prevZoomLevel => prevZoomLevel - 0.3)
  }

  const zoomStyle = {
    zoom: zoomLevel,
  }

  const [defaultProps, setDefaultProps] = useState({
    hasCompiled: false,
    type: 0,
    sector: {
      label: "",
      value: 0,
    },
    number: 14,
  })

  useEffect(() => {
    let max = 0
    displacedElements.forEach(v =>
      parseInt(v.number) > max ? (max = parseInt(v.number)) : null
    )
    setDefaultProps(v => ({ ...v, number: max }))
  }, [displacedElements])

  const loadRowsAndCols = async () => {
    const data = await getPlaceDimensions()
    SetCols(data.num_cols)
    SetRows(data.num_rows)
  }
  const loadData = async () => {
    const umbrellas = await axios.post(
      "https://dev2.dasporto.net/newcalls/planner/getUmbrellasByPlace.php",
      {
        place_id: localStorage.getItem("authUser"),
      }
    )
    SetDisplacedElements(umbrellas.data)
    const pricelists = await axios.post(
      "https://dev2.dasporto.net/newcalls/planner/getPricelistsByPlaceId.php",
      {
        place_id: localStorage.getItem("authUser"),
      }
    )
    setPricelist(pricelists.data)
    const sectors = await axios.post(
      "https://dev2.dasporto.net/newcalls/planner/getSectors.php",
      {
        place_id: localStorage.getItem("authUser"),
      }
    )
    setSectors(sectors.data)
  }

  const loadTypes = async () => {
    const data = await getTypes()
    setTypes(data)
  }
  useEffect(() => {
    loadRowsAndCols()
    loadTypes()
    loadData()
  }, [])

  useEffect(() => {
    console.log(toUpdate)
  }, [toUpdate])

  //restore DisplacementElements
  const restoreElements = async () => {
    const umbrellas = await axios.post(
      "https://dev2.dasporto.net/newcalls/planner/getUmbrellasByPlace.php",
      {
        place_id: localStorage.getItem("authUser"),
      }
    )
    setToUpdate({ append: [], delete: [], update: [] })
    SetDisplacedElements(umbrellas.data)
    setDifferent(false)
  }

  //handle logic to save modifications
  const saveModifications = async () => {
    const { data } = await axios.post(
      "https://dev2.dasporto.net/newcalls/planner/saveModifications.php",
      { data: toUpdate, place_id: localStorage.getItem("authUser") }
    )
    if (data.status) {
      loadData()
      setDifferent(false)
      setToUpdate({ append: [], delete: [], update: [] })
    }
  }

  // handle click on grid element
  const handleSlotClick = (x, y) => {
    let i = displacedElements.findIndex(val => val.x === x && val.y === y)
    setAside(v => ({
      show: true,
      data: i !== -1 ? displacedElements[i] : { x: x, y: y },
    }))
  }

  // generate the initial layout
  const generateLayout = () => {
    const gridItems = []
    let maxX = 0
    let maxY = 0
    for (let c = 0; c <= rows; c++) {
      let columns = []
      let skipFirst = false
      for (let i = skipFirst ? 1 : 0; i <= cols; i++) {
        if (i == 1 && c == 0) {
          columns.push(
            <div
              className=" h-100 w-100 overflow-hidden d-flex justify-content-center align-items-center bg-secondary text-white"
              key={[i, c]}
              style={{
                gridColumn: `${1}/${2}`,
                gridRow: `${c + 1}/${c + 1}`,
              }}
            ></div>
          )
        }
        if (i == 0) {
          columns.push(
            <div
              className=" h-100 w-100 overflow-hidden d-flex justify-content-center align-items-center bg-secondary text-white"
              key={[i, c]}
              style={{
                gridColumn: `${1}/${2}`,
                gridRow: `${c + 1}/${c + 1}`,
              }}
            >
              {c}
            </div>
          )
        } else if (c == 0) {
          columns.push(
            <div
              className=" h-100 w-100 overflow-hidden d-flex justify-content-center align-items-center bg-secondary text-white"
              key={[i, c]}
              style={{
                gridColumn: `${i + 1}/${i + 1}`,
                gridRow: `${1}/${2}`,
              }}
            >
              {i}
            </div>
          )
        } else {
          const index = displacedElements.findIndex(
            val => val.x == i && val.y == c
          )

          let umbrella

          if (index !== -1) {
            if (
              maxX <
              displacedElements[index].x + displacedElements[index].width
            ) {
              maxX = displacedElements[index].x + displacedElements[index].width
            }
            if (
              maxY <
              displacedElements[index].y + displacedElements[index].height
            ) {
              maxY =
                displacedElements[index].y + displacedElements[index].height
            }
            let src
            let env
            // qua devo fare in modo che venga presa dal reducer
            types.forEach(val => {
              if (val.id == displacedElements[index].type) {
                src = `${val.path}`
                env = val.isEnvironment
              }
            })
            console.log(env, displacedElements[index].number)
            umbrella = (
              <div className=" h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                <img src={src} height="60%" />
                {displacedElements[index].number !== "empty" && env == 0 ? (
                  <small>{displacedElements[index].number}</small>
                ) : null}
              </div>
            )
          }
          columns.push(
            <div
              onClick={() => handleSlotClick(i, c)}
              key={[i, c]}
              style={{
                zIndex: umbrella ? 2 : 1,
                gridColumn: `${i + 1}/${
                  index !== -1 ? displacedElements[index].width + i + 1 : i + 1
                }`,
                gridRow: `${c + 1}/${
                  index !== -1 ? displacedElements[index].height + c + 1 : c + 1
                }`,
                backgroundColor: "#e1d9b3",
                cursor: "pointer",
              }}
              className={`gridItem h-100 w-100 overflow-hidden d-flex justify-content-center align-items-center`}
            >
              {umbrella}
            </div>
          )
        }
      }
      gridItems.push(columns)

      SetLayout(gridItems)
    }
    SetCols(maxX + 4 < MINCOLS ? MINCOLS : maxX + 4)
    SetRows(maxY + 4 < MINROWS ? MINROWS : maxY + 4)
  }

  useEffect(() => {
    generateLayout()
  }, [rows, cols, JSON.stringify(displacedElements), types])

  useEffect(() => {}, [displacedElements])

  return (
    <>
      <Aside
        defaultProps={defaultProps}
        setDefaultProps={setDefaultProps}
        displacements={displacedElements}
        updateData={SetDisplacedElements}
        setDifferent={() => setDifferent(true)}
        show={aside.show}
        onHide={() => setAside(v => ({ ...v, show: false }))}
        sectors={sectors}
        types={types}
        pricelists={pricelists}
        data={aside.data}
        appendData={setToUpdate}
        reloadData={loadData}
      />
      <div
        className="position-fixed p-5 d-flex"
        style={{ right: 0, bottom: 0, zIndex: 1003 }}
      >
        <div className="flex-column d-flex">
          <Button color="dark" className="mb-1" onClick={() => handleZoomIn()}>
            +
          </Button>
          <Button color="dark" onClick={() => handleZoomOut()}>
            -
          </Button>
        </div>
      </div>
      <div
        ref={scrollableDivRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={{
          zIndex: 400,
          cursor: "grab",
          overflowX: "scroll",
          height: "100vh",
          position: "fixed",
          left: `${targetElementWidth}px`,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#e1d9b3",
        }}
      >
        <img src="/spiaggia.png" style={{ height: "300px", width: "100%" }} />

        <div
          className="m-5 bg-secondary border border-secondary "
          style={{
            position: "relative",
            backgroundSize: "cover",
            display: "inline-grid",
            zoom: zoomLevel,
            gridTemplateRows: `repeat(${rows + 1}, 50px)`,
            gridTemplateColumns: `repeat(${cols + 1}, 50px)`,
            gridGap: "1px",
            zIndex: 3,
          }}
        >
          {layout}
        </div>
      </div>
    </>
  )
}

export default Grid
