import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Row, Table } from "reactstrap"
import { downloadExcel } from "react-export-table-to-excel"
import {
    deleteEventSignUp,
    getEventSignUps,
    retrieveEventAnswers,
    retrieveEvents,
} from "helpers/fakebackend_helper"
import ModalEventAnswers from "components/events/ModalEventAnswers"

const Signups = () => {
    const [signups, setSignups] = useState([])
    const [events, setEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [answers, setAnswers] = useState([])
    const [isAnswerOpen, setIsAnswerOpen] = useState(false)
    const viewAnswer = async (id) => {
        const { data } = await retrieveEventAnswers(selectedEvent)
        const record = data.find((item) => item.id === id)
        setAnswers(record.answers)
        setIsAnswerOpen(true)
    }

    const fetchEvents = async () => {
        const data = await retrieveEvents()

        setEvents(data)
    }

    const fetchSignups = async () => {

        const data = await getEventSignUps(selectedEvent)

        console.log(data)
        setSignups(data)
    }

    useEffect(() => {
        fetchEvents()
    }, [])

    useEffect(() => {
        if (selectedEvent) {
            fetchSignups()
        }
    }, [selectedEvent])

    const deleteRegistration = async (id) => {
        const conf = window.confirm("Sei sicuro di voler eliminare la registrazione? Non sarà più possibile recuperarla.")
        if (conf) {
            const data = await deleteEventSignUp(id)
            if (data.status){
                fetchSignups()
            }
            else {
                alert(data.message)
            }
        }
    }

    return (
        <div className="page-content">
            <ModalEventAnswers isOpen={isAnswerOpen} toggle={() => setIsAnswerOpen(false)} answers={answers} />
            <h1>Signups</h1>
            <Row>
                <Col md={4}>
                    <select
                        className="form-select"
                        onChange={(e) => setSelectedEvent(e.target.value)}

                    >
                        <option value="">Seleziona un evento</option>
                        {events.map((event) => (
                            <option key={event.id} value={event.id}>
                                {event.description}
                            </option>
                        ))}
                    </select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Nome ticket</th>
                                <th>Stato</th>
                                <th>Azioni</th>
                            </tr>
                        </thead>
                        <tbody>

                            {signups.map((signup) => (
                                <tr key={signup.id}>
                                    <td>{signup.name}</td>
                                    <td>{signup.ticket_name}</td>
                                    <td>{signup.paid ? "Pagato" : "Non pagato"}</td>
                                    <td><Button onClick={() => deleteRegistration(signup.id)}>Elimina</Button> <Button color="primary" onClick={() => viewAnswer(signup.id)}>Visualizza risposte</Button></td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>


        </div>
    )
}

export default Signups
