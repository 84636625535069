import { Input, Modal, Row, Col } from "reactstrap"
import React, { useState } from "react"
import { createMultipleQR } from "helpers/fakebackend_helper"

const MultipleQR = props => {
  const [formData, setFormData] = useState({
    from: false,
    to: false,
    clients: 8,
  })

  const handleSubmit = async () => {
    const data = await createMultipleQR(
      formData.from,
      formData.to,
      formData.clients,
      props.table ? 1 : 0
    )
    if (data.status) {
      props.updateData()
      props.setVisibility(val => ({ ...val, show: false }))
      alert("QR generati con successo")
    } else {
      alert("Errore: alcuni numeri sono già stati assegnati")
    }
  }
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.setVisibility(val => ({ ...val, show: false }))
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Inserimento QR
        </h5>
        <button
          type="button"
          onClick={() => {
            props.setVisibility(val => ({ ...val, show: false }))
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-2">
          <Col>
            <label>Da</label>
            <Input
              type="number"
              value={formData.from || props.min}
              onChange={e => setFormData(v => ({ ...v, from: e.target.value }))}
              min={0}
            />
          </Col>
          <Col>
            <label>A</label>
            <Input
              type="number"
              value={formData.to || props.min}
              onChange={e => setFormData(v => ({ ...v, to: e.target.value }))}
              min={0}
            />
          </Col>
          <Col>
            <label>Clienti associabili</label>
            <Input
              type="number"
              value={formData.clients}
              onChange={e =>
                setFormData(v => ({ ...v, clients: e.target.value }))
              }
              defaultValue={props.min + 1}
              min={props.min}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.setVisibility(val => ({ ...val, show: false }))
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          type="button"
          className="btn btn-primary "
          onClick={handleSubmit}
        >
          Inserisci
        </button>
      </div>
    </Modal>
  )
}

export default MultipleQR
