import { Input, Modal, Row, Col } from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { patchType, putPricelist } from "helpers/fakebackend_helper"

const NewPricelist = props => {
  const [name, setName] = useState("")
  const handleSubmit = async () => {
    const resp = await putPricelist(name)
    if (resp.status) {
      await props.updateData()
      props.onHide()
    }
  }
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.onHide()
        setName("")
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Nuovo listino
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onHide()
            setName("")
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <label>Nome</label>
            <Input value={name} onChange={e => setName(e.target.value)} />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.onHide()
            setName("")
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary "
        >
          Inserisci
        </button>
      </div>
    </Modal>
  )
}

export default NewPricelist
