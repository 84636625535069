import { calculateAvailability, getSectors } from "helpers/fakebackend_helper"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

export default function Autosearch({ show, onHide }) {
  const [steps, setSteps] = useState([])
  const [data, setData] = useState({ from: "", to: "", sector: "0" })
  const [sectors, setSectors] = useState([])
  useEffect(() => {
    if (show) {
      setData({ from: "", to: "" })
      setSteps([])
    }
  }, [show])

  const loadSectors = async () => {
    const res = await getSectors()
    setSectors(res)
  }
  useEffect(() => {
    loadSectors()
  }, [])
  const calculate = async () => {
    if (data.from > data.to) {
      alert("La data di inizio non può essere maggiore della data di fine")
      return
    }
    const res = await calculateAvailability(data)
    setSteps(res)
  }
  return (
    <Modal isOpen={show} toggle={onHide}>
      <ModalHeader toggle={onHide}>Ricerca automatica</ModalHeader>
      <ModalBody>
        <label>Periodo di prenotazione</label>
        <Row>
          <Col>
            <label>Da</label>
            <input
              className="form-control"
              type="date"
              value={data.from}
              onChange={e => setData(v => ({ ...v, from: e.target.value }))}
            />
          </Col>
          <Col>
            <label>A</label>
            <input
              className="form-control"
              type="date"
              value={data.to}
              onChange={e => setData(v => ({ ...v, to: e.target.value }))}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <label>Settore</label>
            <select
              className="form-select"
              value={data.sector}
              onChange={e => setData(v => ({ ...v, sector: e.target.value }))}
            >
              <option value="0">Qualsiasi</option>
              {sectors.map((sector, i) => (
                <option key={i} value={sector.id}>
                  {sector.name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <div className="position-relative mt-4">
          <div
            style={{
              marginLeft: "16px",
              width: "8px",
              height: "100%",
              borderRadius: "10px",
            }}
            className="bg-primary position-absolute"
          ></div>
          {steps.map((step, i) => (
            <div className="d-flex mb-4" key={i}>
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "6px solid #556ee6",
                  zIndex: "3",
                }}
                className="bg-white"
              ></div>
              <div className="mx-3 d-flex flex-column">
                <span>
                  Ombrellone <strong>{step.number}</strong>
                </span>
                <span>
                  {new Date(step.from).toLocaleDateString()} -{" "}
                  {new Date(step.to).toLocaleDateString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={calculate}>
          Calcola
        </Button>
        <Button color="secondary" onClick={onHide}>
          Annulla
        </Button>
      </ModalFooter>
    </Modal>
  )
}
