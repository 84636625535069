import axios from "axios"
import { getPopupInfos, patchPopup } from "helpers/fakebackend_helper"
import React, { useState, useEffect, useRef } from "react"
import { BsCloudUploadFill } from "react-icons/bs"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap"

const Popup = ({ show, toggle }) => {
  const fileInputRef = useRef(null)
  const [image, setImage] = useState(false)
  const [imageName, setImageName] = useState(false)
  const [formData, setFormData] = useState({
    from: "",
    to: "",
  })

  useEffect(() => {
    loadPopup()
  }, [show])
  const loadPopup = async () => {
    const data = await getPopupInfos()
    console.log(data)
    setFormData({
      from: data.date_from,
      to: data.date_to,
    })
    setImage(data.url)
  }
  const handleButtonClick = () => {
    fileInputRef.current && fileInputRef.current.click()
  }

  const handleSubmit = async () => {
    let fmd = new FormData()
    fmd.append("image", fileInputRef.current.files[0])
    fmd.append("from", formData.from)
    fmd.append("to", formData.to)
    fmd.append("place_id", localStorage.getItem("authUser"))

    const { data } = await axios.post(
      "https://dev2.dasporto.net/newcalls/patchPopup.php",
      fmd
    )
    if (data.status){
      alert("Popup aggiornato")
      toggle()
    }
  }
  const handleFileChange = event => {
    const file = event.target.files[0]

    //set a temporary URL and place is in the image state
    setImage(URL.createObjectURL(file))
    setImageName(file.name)
  }

  return (
    <Modal isOpen={show} toggle={toggle}>
      <ModalBody>
        <h3>Popup</h3>
        <Row>
          <Col>
            <label>Da</label>
            <Input
              type="datetime-local"
              value={formData.from}
              onChange={e => {
                setFormData({ ...formData, from: e.target.value })
              }}
            />
          </Col>
          <Col>
            <label>A</label>
            <Input
              type="datetime-local"
              value={formData.to}
              onChange={e => {
                setFormData({ ...formData, to: e.target.value })
              }}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col className="d-flex justify-content-center">
            <img src={image} alt="" className="w-75 rounded overflow-hidden" />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Button onClick={handleButtonClick}>
              <BsCloudUploadFill size={15} className="mx-1" />{" "}
              {imageName ? imageName : "Carica file"}
            </Button>
            <Input
              type="file"
              className="d-none"
              innerRef={fileInputRef}
              accept="image/*,.pdf"
              onChange={handleFileChange}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Chiudi</Button>
        <Button color="primary" onClick={handleSubmit}>
          Salva
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Popup
