import {
  GET_ANAGRAFICA_FAIL,
  GET_ANAGRAFICA_SUCCESS,
  GET_PAYMENT_OPTIONS_SUCCESS,
  GET_PAYMENT_OPTIONS_FAIL,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: {},
  error: {},
  payments: [],
  tags: [],
}

const Anagrafica = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANAGRAFICA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ANAGRAFICA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_PAYMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      }
    case GET_PAYMENT_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TAGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      }
    default:
      return state
  }
}

export default Anagrafica
