import { patchLoanTimes } from "helpers/fakebackend_helper"
import { set } from "lodash"
import React, { useEffect } from "react"
import { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap"

const Modalmodify = ({ show, onHide, data, id, handleUpdate }) => {
  const [formData, setFormData] = useState(data)
  console.log(id)
  useEffect(() => {
    setFormData(data)
  }, [JSON.stringify(data)])

  //check if any date is colliding with another
  const checkCollisions = data => {
    let arr = []
    let collision = false
    data.forEach(item => {
      console.log(item)

      let date_from = new Date(item.date_from)
      while (date_from <= new Date(item.date_to)) {
        //append the date as a string to the array
        let new_date = date_from.toISOString().slice(0, 10)
        arr.push(new_date)
        date_from = new Date(date_from.setDate(date_from.getDate() + 1))
      }
    })

    //check if any date is repeated
    arr.forEach((item, index) => {
      if (arr.indexOf(item) !== index) {
        collision = true
      }
    })

    return collision
  }

  const handleSubmit = async () => {
    //check if any date is colliding with another
    if (checkCollisions(formData)) {
      return toast.error("Le date non possono sovrapporsi")
    }

    const response = await patchLoanTimes({ data: formData, id: id })
    if (response.status === true) {
      await handleUpdate()
      onHide()
    } else if (response.status == "date_to_loan_major_than_date_to_booking") {
      return toast.error(
        "La data di fine del prestito non può essere maggiore della data di fine della prenotazione"
      )
    }
    else {
      return toast.error("Errore")
    }
  }
  useEffect(() => {
    //if formdata has no data, add an empty object
    if (formData.length === 0) {
      setFormData([{ date_from: "", date_to: "" }])
    }
  }, [formData])
  return (
    <>
      <ToastContainer />
      <Modal isOpen={show} toggle={onHide}>
        <ModalHeader>Modifica periodo subaffitabile</ModalHeader>
        <ModalBody>
          {formData.map((item, index) => {
            return (
              <>
                <Row key={index}>
                  <Col xs={4}>
                    <label>Da</label>
                    <Input
                      type="date"
                      value={item.date_from}
                      onChange={({ target }) => {
                        setFormData(v => {
                          v[index].date_from = target.value
                          return [...v]
                        })
                      }}
                    />
                  </Col>
                  <Col xs={4}>
                    <label>A</label>
                    <Input
                      type="date"
                      value={item.date_to}
                      onChange={({ target }) => {
                        setFormData(v => {
                          v[index].date_to = target.value
                          return [...v]
                        })
                      }}
                    />
                  </Col>
                  <Col xs={2} className="d-flex flex-column">
                    <label>&nbsp;</label>
                    <Button
                      color="danger"
                      onClick={() =>
                        setFormData(v => {
                          // only delete it if there area more than one element, else empty out the last record
                          if (v.length > 1) {
                            v.splice(index, 1)
                          } else {
                            v[0].date_from = ""
                            v[0].date_to = ""
                          }
                          return [...v]
                        })
                      }
                      className="mt-auto"
                    >
                      -
                    </Button>
                  </Col>

                  <Col xs={2} className="d-flex flex-column">
                    <label>&nbsp;</label>
                    <Button
                      color="success"
                      onClick={() =>
                        setFormData(v => {
                          const obj = {
                            date_from: "",
                            date_to: "",
                            price: "",
                            clause: 0,
                          }
                          const newFormData = [...v, obj]

                          return newFormData
                        })
                      }
                      className="mt-auto"
                    >
                      +
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Prezzo</label>
                    <InputGroup>
                      <InputGroupText>€</InputGroupText>
                      <Input
                        type="number"
                        value={item.price}
                        onChange={({ target }) => {
                          setFormData(v => {
                            v[index].price = target.value
                            return [...v]
                          })
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label>Prezzo per:</label>
                    <select
                      className="form-select"
                      value={item.clause}
                      onChange={e =>
                        setFormData(v => {
                          v[index].clause = parseInt(e.target.value)
                          return [...v]
                        })
                      }
                    >
                      <option value={0}>Seleziona...</option>
                      <option value={1}>Periodo</option>
                      <option value={2}>Giornaliero</option>
                    </select>
                  </Col>
                </Row>
                {formData.length !== index + 1 && (
                  <hr style={{ height: "1px", backgroundColor: "grey" }} />
                )}
              </>
            )
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onHide}>
            Annulla
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Modifica
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Modalmodify
