import { Input, Modal, Row, Col, ModalBody, Button } from "reactstrap"
import React, { useState, useEffect, useRef } from "react"
import { putDeposit } from "helpers/fakebackend_helper"
import moment from "moment"

const Modaldeposits = props => {
  const [addValue, setAddValue] = useState({ account: 0, date: moment(new Date()).format("YYYY-MM-DD") })
  const [price, setPrice] = useState({
    price: 0,
    paid: 0,
    accounts: [],
  })
  const handleHide = async () => {
    props.updateData()
    props.onHide()
  }
  const handleSubmit = async () => {
    if (price.price - price.paid < addValue) {
      return alert("L'importo inserito è più di quello richiesto, riprova.")
    }
    const value = parseInt(addValue.account)
    const date = (addValue.date)
    const { bookingId } = props.data
    const data = await putDeposit({ amount: value, booking: bookingId, date: date })
    if (data.status) {
      console.log(price)
      setPrice(v =>
      ({
        paid: v.paid + value,
        price: v.price,
        accounts: [...v.accounts, {amount: value, date: date}],
      })
      )
    }
  }
  useEffect(() => {
    if (props.data) {
      setPrice({
        price: props.data.price,
        paid: props.data.amount,
        accounts: props.data.bookings,
      })
    }
  }, [props.data])
  useEffect(() => {
    console.log(price)
  }, [JSON.stringify(price)])
  return (
    <Modal
      centered={true}
      isOpen={props.show}
      toggle={() => {
        handleHide()
      }}
    >
      <div className="modal-header d-flex w-100 align-items-center justify-content-center">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Acconti
        </h5>
        <button
          type="button"
          onClick={() => {
            handleHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body w-100 h-100 d-flex flex-column align-items-center justify-content-center p-3">
        <span>Totale da pagare : €{price.price}</span>
        <span className="mb-2">Rimanente : €{price.price - price.paid}</span>
        <div className="w-100">
          {price.accounts && price.accounts.length > 0
            ? price.accounts.map((v, i) => (
              <div key={i} className="d-flex mb-3">
                <Input disabled type="date" value={moment(new Date(v.date)).format("YYYY-MM-DD")} className="mx-1" />
                <div className="input-group  w-100 flex">

                  <span className="input-group-text" id="basic-addon1">
                    €
                  </span>
                  <input
                    key={i}
                    type={"text"}
                    className="form-control"
                    disabled
                    value={`${v.amount}`}
                  />
                </div>
              </div>
            ))
            : null}
          <div className="d-flex mx-1">
            <Input type="date" value={addValue.date} onChange={(e) => setAddValue((v) => ({ ...v, date: e.target.value }))} />
            <div className="input-group w-100 mx-2">
              <span className="input-group-text" id="basic-addon1">
                €
              </span>
              <input
                type="number"
                className="form-control"
                value={addValue.account}
                onChange={e => setAddValue(v => ({ ...v, account: e.target.value }))}
                max={price.price - price.paid}
                min={0}
              />
            </div>
            <button
              className="btn btn-primary"
              style={{ marginLeft: "5px" }}
              onClick={handleSubmit}
            >
              Aggiungi
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Modaldeposits
