import { Button, Col, Container, Row } from "reactstrap"
import React, { useState } from "react"
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react"
import "gantt-task-react/dist/index.css"
import { useEffect } from "react"
import { getUmbrellasForGantt } from "helpers/fakebackend_helper"
import Autosearch from "components/Gantt/Autosearch"
import { checkIfUserHasPurchasedModule } from "helpers/functions"

export default function ReactGantt() {
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])
  const [search, setSearch] = useState(false)
  const [tasks, setTasks] = useState([])
  useEffect(() => {
    const load = async () => {
      const response = await getUmbrellasForGantt()
      setTasks(response)
    }
    load()
  }, [])

  let earliestStartDate = new Date("9999-12-31")
  // Initialize the latest end date as the minimum possible date
  let latestEndDate = new Date("0000-01-01")

  if (tasks) {
    tasks.forEach(task => {
      task.elements.forEach(element => {
        const from = new Date(element.from)
        const to = new Date(element.to)

        // Compare and update the earliest start date
        if (from < earliestStartDate) {
          earliestStartDate = from
        }

        // Compare and update the latest end date
        if (to > latestEndDate) {
          latestEndDate = to
        }
      })
    })
  }

  console.log("Earliest Start Date:", earliestStartDate)
  console.log("Latest End Date:", latestEndDate)

  const numberOfDays = Math.floor(
    (latestEndDate - earliestStartDate) / (1000 * 60 * 60 * 24) + 1
  )

  // Generate an array of column labels representing each day
  const columnLabels = Array.from({ length: numberOfDays }).map((_, index) => {
    const currentDate = new Date(earliestStartDate)
    currentDate.setDate(currentDate.getDate() + index)
    return currentDate.toISOString().slice(0, 10) // Format the date as YYYY-MM-DD
  })

  return (
    <>
      <Autosearch show={search} onHide={() => setSearch(false)} />
      <div className="page-content">
        <Container fluid={true}>
          <Row className="mb-2">
            <Col>
              <Button onClick={() => setSearch(true)}>
                Ricerca automatica
              </Button>
            </Col>
            <Col>
            </Col>
          </Row>
          <div className="grid-container">
            <div className="grid-header">
              <div className="grid-column" style={{ gridRow: 1 }}>
                Ombrellone
              </div>
              {columnLabels.map((label, index) => (
                <div key={index} className="grid-column" style={{ gridRow: 1 }}>
                  {label}
                </div>
              ))}
            </div>

            {tasks.map((task, i) => (
              <div key={task.id} className="grid-row" style={{ gridRow: i + 2 }}>
                <div className="grid-column">Ombrellone {task.number}</div>
                {columnLabels.map((label, index) => {
                  const columnDate = new Date(label)
                  const matchingElement = task.elements.find(
                    element =>
                      columnDate >= new Date(element.from) &&
                      columnDate <= new Date(element.to)
                  )

                  const isWithinRange = !!matchingElement // Check if a matching element is found

                  // Use the matchingElement to access its ID or other properties

                  return (
                    <div
                      key={index}
                      style={{ gridRow: 1 }}
                      className={`grid-column ${isWithinRange ? "filled" : ""}`}
                    >
                      {matchingElement && matchingElement.name}
                      <br />
                      {matchingElement && <small>{matchingElement.phone}</small>}
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  )
}
