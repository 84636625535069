import { isUserOwningModule } from "./fakebackend_helper"

export const checkIfUserHasPurchasedModule = async (module) => {
    const data = await (isUserOwningModule(module))
    if (data === true) {
        return true
    }
    else {

        window.location.href = "/purchase?id=" + module
        return false
    }
}