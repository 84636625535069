import React, { useState, useEffect } from "react"
/* import styles from "../../styles/prenotazione.module.css" */
import {
  BsCurrencyEuro,
  BsFillBookmarkFill,
  BsFillBookmarkPlusFill,
  BsInfo,
  BsPlus,
} from "react-icons/bs"
import {
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from "react-icons/ai"
import axios from "axios"
import { Button, Row, Col, Input, InputGroup, InputGroupText } from "reactstrap"
import { FaBroom } from "react-icons/fa"
import { AiOutlineEuro } from "react-icons/ai"
import moment from "moment"
import AsideBooking from "../../components/planner/AsideBooking"
import Modaldeposits from "components/planner/Modaldeposits"
import Bookingrecord from "components/planner/Bookingrecord"
import { getTypes } from "helpers/fakebackend_helper"
import { useRef } from "react"
import Legendmodal from "components/planner/Legendmodal"
import { checkIfUserHasPurchasedModule } from "helpers/functions"

const MINCOLS = 40
const MINROWS = 20

const Grid = props => {

  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])

  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [modalLegend, setModalLegend] = useState(false)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const scrollableDivRef = useRef(null)
  const handleMouseDown = e => {
    setIsDown(true)
    setStartX(e.pageX - scrollableDivRef.current.offsetLeft)
    setStartY(e.pageY - scrollableDivRef.current.offsetTop)
    setScrollLeft(scrollableDivRef.current.scrollLeft)
    setScrollTop(scrollableDivRef.current.scrollTop)
  }

  const handleMouseLeave = () => {
    setIsDown(false)
  }

  const handleMouseUp = () => {
    setIsDown(false)
  }

  const handleMouseMove = e => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - scrollableDivRef.current.offsetLeft
    const y = e.pageY - scrollableDivRef.current.offsetTop
    const walkX = (x - startX) * 1
    const walkY = (y - startY) * 1
    scrollableDivRef.current.scrollLeft = scrollLeft - walkX
    scrollableDivRef.current.scrollTop = scrollTop - walkY
  }

  const [modalDeposits, setModalDeposits] = useState({
    data: false,
    show: false,
  })
  const targetElement = document.querySelector(".vertical-menu")
  const targetElementWidth = targetElement ? targetElement.clientWidth : 0
  const startOfToday = new Date()
  startOfToday.setHours(0, 0, 0, 0) // Set the time to midnight

  const endOfToday = new Date()
  endOfToday.setHours(23, 59, 59, 999) // Set the time to 23:59:59.999

  const stringStartOfToday = moment(startOfToday).format("YYYY-MM-DD")
  const stringEndOfToday = moment(endOfToday).format("YYYY-MM-DD")

  const [cols, SetCols] = useState(MINCOLS)
  const [rows, SetRows] = useState(MINROWS)
  const [layout, SetLayout] = useState([])
  const [activityDates, setActivityDates] = useState({})
  const [aside, setAside] = useState({
    show: false,
    data: {},
  })
  const [displacedElements, SetDisplacedElements] = useState([])
  const [types, setTypes] = useState([])
  const [date, setDate] = useState({
    from: stringStartOfToday,
    to: stringEndOfToday,
    type: 3,
  })
  const loadData = async () => {
    const activityDates = await axios.post(
      "https://dev2.dasporto.net/newcalls/planner/getSeasonActivityByPlace.php",
      {
        place_id: localStorage.getItem("authUser"),
      }
    )
    setActivityDates(activityDates.data)
    const typesRaw = await getTypes()
    setTypes(typesRaw)
    const umbrellas = await axios.post(
      "https://dev2.dasporto.net/newcalls/planner/getUmbrellasByPlace.php",
      {
        place_id: localStorage.getItem("authUser"),
        onlyActive: true,
      }
    )
    SetDisplacedElements(umbrellas.data)
  }
  useEffect(() => {
    loadData()
  }, [])
  useEffect(() => {
    generateLayout()
  }, [date, displacedElements])

  const [zoomLevel, setZoomLevel] = useState(1)

  const handleZoomIn = () => {
    //get document body style zoom and increase it by 10%

    const currentZoom = document.body.style.zoom
    if (currentZoom) {
      /*       const currentZoomNumber = parseInt(currentZoom.replace("%", ""))
      document.body.style.zoom = `${currentZoomNumber + 10}%` */
    } else {
      /* document.body.style.zoom = "110%" */
    }
  }

  const handleZoomOut = () => {
    //get document body style zoom and decrease it by 10%
    const currentZoom = document.body.style.zoom
    if (currentZoom) {
      /*       const currentZoomNumber = parseInt(currentZoom.replace("%", ""))
      document.body.style.zoom = `${currentZoomNumber - 10}%` */
    } else {
      /* document.body.style.zoom = "90%" */
    }
  }
  function isDateRangeOverlap(start1, end1, start2, end2) {
    return start1 <= end2 && end1 >= start2
  }

  // handle click on grid element
  const handleSlotClick = (x, y) => {
    let i = displacedElements.findIndex(val => val.x === x && val.y === y)
    setAside(v => ({
      data: i !== -1 ? displacedElements[i] : { x: x, y: y },
      show: true,
    }))
  }
  const generateDates = () => {
    const year = new Date().getFullYear()
    const from = moment(
      new Date(
        `${year}-${activityDates.activityFrom_month}-${activityDates.activityFrom_day}`
      )
    ).format("YYYY-MM-DD")
    const to = moment(
      new Date(
        `${year}-${activityDates.activityTo_month}-${activityDates.activityTo_day}`
      )
    ).format("YYYY-MM-DD")
    return { from, to }
  }
  // generate the initial layout
  const generateLayout = () => {
    const { from, to } = generateDates()
    let maxX = 0
    let maxY = 0
    console.log(from, to)
    const gridItems = []
    for (let c = 0; c < rows; c++) {
      let season = 0
      let columns = []
      let skipFirst = false
      if (c == rows - 1) {
        skipFirst = true
      }
      for (let i = skipFirst ? 1 : 0; i < cols; i++) {
        if (i == 0) {
          columns.push(
            <div
              className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
              key={[i, c]}
              style={{
                gridColumn: `${1}/${2}`,
                gridRow: `${c + 1}/${c + 1}`,
              }}
            ></div>
          )
        } else {
          const index = displacedElements.findIndex(
            val => val.x == i && val.y == c
          )
          let umbrella
          let elements = []
          let bookedSurname = ""
          let bookedDay = ""
          let checkIfAnyOrderHasntBeenPaid = true
          let season

          if (index !== -1) {
            if (
              maxX <
              displacedElements[index].x + displacedElements[index].width
            ) {
              maxX = displacedElements[index].x + displacedElements[index].width
            }
            if (
              maxY <
              displacedElements[index].y + displacedElements[index].height
            ) {
              maxY =
                displacedElements[index].y + displacedElements[index].height
            }
            let src
            let isOccupied = -1
            if (date.to === date.from) {
              isOccupied = displacedElements[index].bookings.findIndex(
                v =>
                  new Date(date.from) >= new Date(v.booked_to) ||
                  new Date(date.to) <= new Date(v.booked_from)
              )
            } else {
              isOccupied = displacedElements[index].bookings.findIndex(v =>
                isDateRangeOverlap(
                  date.from,
                  date.to,
                  v.booked_from,
                  v.booked_to
                )
              )
            }

            types.forEach(val => {
              const splittedPath = val.path.split(".")
              if (val.id == displacedElements[index].type) {
                if (val.isUmbrella == 1) {
                  const currentBookings = displacedElements[
                    index
                  ].bookings.filter(
                    v =>
                      new Date(v.booked_from) <= new Date(date.to) &&
                      new Date(v.booked_to) >= new Date(date.from)
                  )
                  if (currentBookings.length == 0) {
                    const bookedAfterGivenDate = displacedElements[
                      index
                    ].bookings.filter(
                      v => new Date(v.booked_from) >= new Date(date.to)
                    )
                    if (bookedAfterGivenDate.length > 0) {
                      bookedDay = moment(
                        new Date(bookedAfterGivenDate[0].booked_from)
                      ).format("DD/MM")
                    }

                    src = val.path
                  } else {
                    src = `${
                      splittedPath[splittedPath.length - 4] +
                      "." +
                      splittedPath[splittedPath.length - 3] +
                      "." +
                      splittedPath[splittedPath.length - 2]
                    }-${
                      displacedElements[index].bookings[0].loaned == 1
                        ? "secondary"
                        : "danger"
                    }.${splittedPath[splittedPath.length - 1]}`
                    elements = currentBookings[0].elements.map(
                      v => v.qty + v.initial
                    )
                    console.log(elements)
                    bookedDay = moment(
                      new Date(currentBookings[0].booked_to)
                    ).format("DD/MM")
                    bookedSurname =
                      displacedElements[index].bookings[0].customer_surname
                    currentBookings.forEach(v =>
                      v.paid == v.price
                        ? null
                        : (checkIfAnyOrderHasntBeenPaid = false)
                    )
                  }
                } else {
                  src = val.path
                }
              }
            })
            const typeIndex = types.findIndex(
              v => displacedElements[index].type == v.id
            )
            const displacementType = types[typeIndex]
            if (isOccupied !== -1) {
              displacedElements[index].bookings[isOccupied].booked_from ==
                from &&
              displacedElements[index].bookings[isOccupied].booked_to == to
                ? (season = true)
                : (season = false)
            }
            columns.push(
              <Bookingrecord
                isSeasonal={season}
                id={displacedElements[index].id}
                elements={elements}
                x={i}
                y={c}
                src={src}
                w={displacedElements[index].width}
                surname={bookedSurname}
                day={bookedDay}
                h={displacedElements[index].height}
                env={typeIndex !== -1 ? displacementType.isEnvironment : true}
                paid={checkIfAnyOrderHasntBeenPaid}
                handleClick={() => handleSlotClick(i, c)}
                number={
                  displacedElements[index].number !== "empty"
                    ? displacedElements[index].number
                    : ""
                }
              />
            )
          } else {
            columns.push(<Bookingrecord x={i} y={c} env={true} />)
          }
        }
      }
      gridItems.push(columns)
    }
    console.log(maxX, maxY)

    SetLayout(gridItems)
    console.log(maxX, maxY)
    SetCols(maxX + 4 < MINCOLS ? MINCOLS : maxX + 3)
    SetRows(maxY + 4 < MINROWS ? MINROWS : maxY + 3)
  }

  useEffect(() => {
    generateLayout()
  }, [rows, cols, JSON.stringify(displacedElements), types])

  return (
    <div style={{ zIndex: 1006, position: "relative" }}>
      <Legendmodal
        show={modalLegend}
        onHide={() => setModalLegend(false)}
        types={types}
      />
      <Modaldeposits
        updateData={loadData}
        data={modalDeposits.data}
        show={modalDeposits.show}
        onHide={() => setModalDeposits({ data: {}, show: false })}
      />
      <AsideBooking
        types={types}
        dates={date}
        data={aside.data}
        refreshData={loadData}
        show={aside.show}
        viewPayments={setModalDeposits}
        onHide={() => setAside(v => ({ ...v, show: false }))}
        activityDates={activityDates}
      />

      <div
        id="print-container"
        ref={scrollableDivRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onClick={() => console.log("click")}
        style={{
          zIndex: 400,
          cursor: "grab",
          overflowX: "scroll",
          height: "100%",

          position: "fixed",
          left: `${targetElementWidth}px`,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#e1d9b3",
        }}
      >
        <div
          className="position-relative"
          style={{ display: "inline-block", left: 0 }}
        >
          <Row
            className="position-fixed w-100 d-flex justify-content-center "
            style={{ right: 0, left: 0, top: "20px", zIndex: 1000 }}
          >
            <Row
              className="p-3 bg-light rounded"
              style={{ height: "110px", width: "600px" }}
            >
              {/*               <Col xs={2}>
                <div className="d-flex flex-column">
                  <Button color="dark" onClick={handleZoomIn} className="mb-1">
                    +
                  </Button>
                  <Button color="dark" onClick={handleZoomOut}>
                    -
                  </Button>
                </div>
              </Col> */}
              <Col>
                <label>Da</label>
                <Input
                  type="date"
                  value={date.from}
                  onChange={e => setDate(v => ({ ...v, from: e.target.value }))}
                />
              </Col>
              <Col>
                <label>A</label>
                <Input
                  type="date"
                  value={date.to}
                  onChange={e => setDate(v => ({ ...v, to: e.target.value }))}
                />
              </Col>

            </Row>
          </Row>
          <img
            src="/spiaggia.png"
            style={{ height: "300px", width: "100%" }}
          ></img>
          <div
            className="p-2"
            style={{
              /* zIndex: 500, */
              position: "relative",
              zoom: zoomLevel,
              /*             maxWidth: "100%", */
              backgroundSize: "cover",
              display: "inline-grid",
              gridTemplateRows: `repeat(${rows}, 60px)`,
              gridTemplateColumns: `repeat(${cols}, 90px)`,
              gridGap: "0",
              minWidth: "100%",
              minHeight: "100%",
              backgroundColor: "#e1d9b3",
              touchAction: "none",
            }}
          >
            {layout}
          </div>
          <div
            className="w-100 position-fixed d-flex justify-content-end"
            style={{ bottom: 0, right: 0, zIndex: 6 }}
          ></div>
          <div
            className="position-fixed p-5 d-flex"
            style={{ right: 0, bottom: 0, zIndex: 1003 }}
          >
            <Button
              color="info"
              className="mx-1"
              onClick={() => setModalLegend(true)}
            >
              <BsInfo size={26} className="" />
            </Button>
            <div className="flex-column d-flex">
              <Button
                color="dark"
                className="mb-1"
                onClick={() => setZoomLevel(e => e + 0.1)}
              >
                +
              </Button>
              <Button color="dark" onClick={() => setZoomLevel(e => e - 0.1)}>
                -
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Grid
