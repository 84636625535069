/* import styles from "../styles/AsideBooking.module.css" */
import React from "react"
import axios from "axios"
import { Button, Row, Col, Form, InputGroup, Badge, Input } from "reactstrap"
import { useEffect, useState } from "react"
import {
  assignCustomerToBooking,
  deleteBookingAssociation,
  getCustomersToAssociate,
  getDeposit,
  getFictionalCustomers,
} from "helpers/fakebackend_helper"
import Select from "react-select"

const AsideBooking = props => {
  const wrapper = props.show ? null : "wrapperInactive"
  const recordToCheck = props.data
  const [diffDate, setDiffDate] = useState(0)
  const [isCabin, setIsCabin] = useState(false)
  const [typeName, setTypeName] = useState(null)
  const [bookingData, setBookingData] = useState({})
  const [selectedBookingIndex, setSelectedBookingIndex] = useState(false)
  const [selectedBookingRecord, setSelectedBookingRecord] = useState(false)
  const [isSeasonalBooking, setIsSeasonalBooking] = useState(false)
  const [isUserSelected, setIsUserSelected] = useState(true)
  const [fictionalCustomers, setFictionalCustomers] = useState([])
  const [selectedFictionalCustomer, setSelectedFictionalCustomer] =
    useState(false)
  let durata
  console.log(selectedBookingRecord)
  useEffect(() => {
    if (props.types && props.data) {
      const type = props.types.find(v => v.id == props.data.type)
      if (type) {
        setTypeName(type.name)
      }
    }
  }, [JSON.stringify(props.data), JSON.stringify(props.types)])
  switch (selectedBookingRecord.bookingType) {
    case 1:
      durata = "Mattina"
      break
    case 2:
      durata = "Pomeriggio"
      break
    case 3:
      durata = "Intero"
      break
  }

  const viewPayments = async () => {
    const data = await getDeposit(selectedBookingRecord.bookingId)
    props.viewPayments({
      data: { ...data, bookingId: selectedBookingRecord.bookingId },
      show: true,
    })
  }

  const assignCustomer = async (bookingId, customer_id) => {
    if (
      selectedBookingRecord.customers ==
      selectedBookingRecord.associatedCustomers.length
    ) {
      const conf = confirm(
        "Sforerai il limite di utenti associati, sei sicuro di continuare?"
      )
      if (!conf) {
        return
      }
    }
    if (!customer_id)
      return alert("Inserisci un utente prima di inviare la richiesta")
    const data = await assignCustomerToBooking(bookingId, customer_id)
    if (data.status) {
      setSelectedBookingRecord(v => {
        let array = { ...v }
        const selectedFictionalIndex = fictionalCustomers.findIndex(
          v => v.id == selectedFictionalCustomer
        )
        console.log(selectedFictionalIndex)
        array.associatedCustomers.push({
          associationid: data.id,
          name: fictionalCustomers[selectedFictionalIndex].name,
          surname: fictionalCustomers[selectedFictionalIndex].surname,
        })
        return array
      })
      props.refreshData()
    }
  }

  const deleteAssociation = async associationId => {
    const data = await deleteBookingAssociation(associationId)
    if (data.status) {
      setSelectedBookingRecord(v => {
        let array = { ...v }
        array.associatedCustomers = array.associatedCustomers.filter(
          el => el.associationid !== associationId
        )
        return array
      })
      props.refreshData()
    }
  }

  const loadCustomers = async () => {
    const data = await getCustomersToAssociate()
    console.log(data)
    setFictionalCustomers(data)
  }

  const handleBookingRecordChange = index => {
    if (index !== "false") {
      setSelectedBookingIndex(index)
      /* setSelectedBookingRecord(bookingData.bookings[index]); */
    } else {
      setSelectedBookingIndex(false)
      setSelectedBookingRecord(false)
      /* setSelectedBookingRecord(false); */
    }
  }

  useEffect(() => {
    loadCustomers()
    setSelectedBookingIndex(false)
    setBookingData(recordToCheck)
  }, [props.data])

  useEffect(() => {
    if (selectedBookingRecord) {
      // first date
      const date1 = new Date(selectedBookingRecord.booked_from)

      // second date
      const date2 = new Date(selectedBookingRecord.booked_to)

      // calculate the difference between the two dates
      const diffTime = Math.abs(date2 - date1)

      // convert the difference to days
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      // print the number of days between the two dates
      setDiffDate(diffDays + 1)
    }
  }, [selectedBookingRecord])

  useEffect(() => {
    if (selectedBookingIndex !== false) {
      setSelectedBookingRecord(bookingData.bookings[selectedBookingIndex])
    } else {
      setSelectedBookingRecord(false)
    }
  }, [selectedBookingIndex, recordToCheck])
  /*   useEffect(() => {
    if (recordToCheck.bookings && recordToCheck.bookings.length > 0) {
      setSelectedBookingIndex(0)
    } else {
      setSelectedBookingIndex(false)
    }
  }, [recordToCheck]) */

  useEffect(() => {
    const { activityDates } = props
    if (selectedBookingRecord) {
      const fromDate = new Date(selectedBookingRecord.booked_from)
      const toDate = new Date(selectedBookingRecord.booked_to)
      if (
        fromDate.getMonth() + 1 === activityDates.activityFrom_month &&
        fromDate.getDate() == activityDates.activityFrom_day &&
        toDate.getDate() == activityDates.activityTo_day &&
        toDate.getMonth() + 1 == activityDates.activityTo_month
      ) {
        setIsSeasonalBooking(true)
      } else {
        setIsSeasonalBooking(false)
      }
    }
  }, [selectedBookingRecord])

  /*   useEffect(() => {
    if (props.types.length > 0) {
      const typeCabinIndex = props.types.findIndex(v => v.isCabin == 1)
      if (typeCabinIndex !== -1)
        recordToCheck.type == props.types[typeCabinIndex].id
          ? setIsCabin(true)
          : setIsCabin(false)
    }
  }, [props]) */

  let paid = 0
  selectedBookingRecord &&
    selectedBookingRecord.deposits &&
    selectedBookingRecord.deposits.forEach(v => {
      paid += v.amount
    })

  useEffect(() => {
    setIsUserSelected(true)
  }, [selectedBookingRecord])

  return (
    <section
      className={`wrapper overflow-scroll ${wrapper}`}
      style={{ zIndex: 1005 }}
    >
      <main className="bodyWrapper">
        <div className="w-100 p-5 justify-content-between align-items-center d-flex flex-column">
          <div className="d-flex flex-column">
            <h5 className=" d-flex text-center">
              {typeName || "Elemento"} selezionato
            </h5>
            <span className="text-center h5 text-light bg-primary p-3 rounded-4">
              {bookingData.number !== "empty" ? bookingData.number : null}
            </span>
          </div>
          <div className="mt-3 w-100">
            <label>Prenotazioni</label>
            <InputGroup>
              <select
                className="form-select"
                value={selectedBookingIndex}
                onChange={e => handleBookingRecordChange(e.target.value)}
              >
                <option value={false}>Seleziona una prenotazione</option>
                {bookingData.bookings
                  ? bookingData.bookings.map((val, index) => (
                      <option key={index} value={index}>{`${new Date(
                        val.booked_from
                      ).toLocaleDateString()} - ${new Date(
                        val.booked_to
                      ).toLocaleDateString()}`}</option>
                    ))
                  : null}
              </select>

              <a
                className="text-light  text-decoration-none d-flex "
                href={`new?id=${bookingData.id}&from=${props.dates.from}&to=${props.dates.to}&isCabin=${isCabin}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="d-flex rounded-0">+</Button>
              </a>
            </InputGroup>

            <div className="w-100">
              <div className="w-100 d-flex mt-2">
                {selectedBookingRecord.loaned === 1 ? (
                  <span className="badge badge-third ">Subaffitto</span>
                ) : null}

                {selectedBookingRecord && isSeasonalBooking ? (
                  <Badge bg="primary">Stagionale</Badge>
                ) : null}
              </div>
              {selectedBookingRecord.id ? (
                <>
                  <div className="my-3 d-flex flex-column">
                    <strong>Dati anagrafici</strong>

                    <Row>
                      <Col>
                        <span>Nome</span>
                        <input
                          className="form-control"
                          disabled
                          value={selectedBookingRecord.customer_name}
                        />
                      </Col>
                      <Col>
                        <span>Cognome</span>
                        <input
                          className="form-control"
                          disabled
                          value={selectedBookingRecord.customer_surname}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2in ">
                      <Col>
                        <span>Email</span>
                        <input
                          className="form-control"
                          disabled
                          value={selectedBookingRecord.email}
                        />
                      </Col>
                      <Col>
                        <span>Telefono</span>
                        <input
                          className="form-control"
                          disabled
                          value={selectedBookingRecord.phone}
                        />
                      </Col>
                    </Row>
                  </div>
                  <strong>Durata</strong>
                  <Row>
                    <Col>
                      <span>Dal giorno</span>
                      <Input
                        type="date"
                        value={selectedBookingRecord.booked_from}
                        readOnly={true}
                        onChange={e =>
                          setSelectedBookingRecord(val => ({
                            ...val,
                            booked_from: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <span>Al giorno</span>
                      <Input
                        type="date"
                        value={selectedBookingRecord.booked_to}
                        readOnly={true}
                        onChange={e =>
                          setSelectedBookingRecord(val => ({
                            ...val,
                            booked_to: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <span>Giorni</span>
                      <Input type="text" value={diffDate} disabled={true} />
                    </Col>
                  </Row>
                  {selectedBookingRecord.booked_to ==
                  selectedBookingRecord.booked_from ? (
                    <div className="mt-2">
                      <label>Tempistiche</label>
                      <Input type="text" value={durata} disabled={true} />
                    </div>
                  ) : null}
                  <div>
                    <label className="mt-3">Configurazione</label>
                    {selectedBookingRecord.elements.length > 0 ? (
                      selectedBookingRecord.elements.map((val, k) => (
                        <Row key={k} className="mb-1">
                          <Col xs={9}>
                            <Input value={val.name} readOnly={true} />
                          </Col>
                          <Col>
                            <Input value={val.qty} readOnly={true} />
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <p className="text-muted">
                        <em>Nessun elemento nella configurazione</em>
                      </p>
                    )}
                  </div>
                  <div className="d-flex w-100 justify-content-around">
                    <div className="d-flex flex-column">
                      <label className="mt-3">Totale</label>
                      <span>€{selectedBookingRecord.price}</span>
                    </div>
                    <div className="d-flex flex-column">
                      <label className="mt-3">Pagato</label>
                      <span>€{paid}</span>
                    </div>
                    <div className="d-flex flex-column">
                      <label className="mt-3">Da pagare</label>
                      <span>€{selectedBookingRecord.price - paid}</span>
                    </div>
                  </div>
                  <div className="mt-3 position-relative">
                    <div
                      className="position-absolute top-0"
                      style={{ right: 0 }}
                    >
                      <button
                        className="btn btn-primary py-0"
                        disabled={isUserSelected}
                        onClick={() =>
                          assignCustomer(
                            selectedBookingRecord.bookingId,
                            selectedFictionalCustomer
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                    <label> Utenti associati</label>
                    <Badge className="mx-3">
                      {selectedBookingRecord.associatedCustomers.length}/
                      {selectedBookingRecord.customers}
                    </Badge>
                    {selectedBookingRecord.associatedCustomers.map((val, k) => (
                      <div key={k} className="input-group my-2">
                        <Input key={k} disabled value={val.name} />
                        <button
                          onClick={() => deleteAssociation(val.associationid)}
                          className="btn btn-danger"
                        >
                          X
                        </button>
                      </div>
                    ))}
                    <Select
                      onChange={e => {
                        e.value
                          ? setIsUserSelected(false)
                          : setIsUserSelected(true)
                        setSelectedFictionalCustomer(e.value)
                      }}
                      options={fictionalCustomers.map(v => ({
                        value: v.id,
                        label: v.name,
                      }))}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <Row>
            <Col className="my-2">
              <Button variant="secondary" onClick={() => props.onHide()}>
                Chiudi
              </Button>
            </Col>
            {selectedBookingIndex ? (
              <>
                <Col className="my-2">
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => viewPayments()}
                    >
                      Acconti
                    </button>
                  </>
                </Col>
                <Col className="my-2">
                  <button
                    className="btn btn-info"
                    onClick={() =>
                      (window.location.href = `/planner/edit?id=${selectedBookingRecord.bookingId}`)
                    }
                  >
                    Modifica
                  </button>
                </Col>
              </>
            ) : null}
          </Row>
        </div>
      </main>
    </section>
  )
}

export default AsideBooking
