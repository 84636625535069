import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap"
import Select from "react-select"
import {
  deleteSeason,
  getPricelists,
  getSeasons,
} from "helpers/fakebackend_helper"
import { useEffect } from "react"
import { useState } from "react"
import NewSeason from "components/anagrafiche/pricelist/newSeason"
import NewPricelist from "components/anagrafiche/pricelist/newPricelist"
import EditSeason from "components/anagrafiche/pricelist/editSeason"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
const Pricelist = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])
  const [pricelist, setPricelist] = useState([])
  const [editSeason, setEditSeason] = useState({
    show: false,
    data: {},
  })
  const [selectedPricelist, setSelectedPricelist] = useState(false)
  const [seasons, setSeasons] = useState([])
  const [newPricelist, setNewPricelist] = useState({ show: false })
  const [newSeason, setNewSeason] = useState({ show: false })
  const loadPricelists = async () => {
    const data = await getPricelists()
    setPricelist(data)
  }
  const handleModify = async index => {
    setEditSeason(v => ({ show: true, data: seasons[index] }))
  }
  const loadSeasons = async id => {
    const data = await getSeasons(id)
    setSeasons(data)
  }
  const deleteRecord = async id => {
    if (confirm("Sei sicuro di eliminare questo listino?")) {
      const data = await deleteSeason(id)
      loadSeasons(selectedPricelist)
    }
  }
  useEffect(() => {
    loadPricelists()
  }, [])
  const getMonthName = monthNumber => {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    return date.toLocaleString("it-IT", { month: "long" })
  }
  useEffect(() => {
    console.log(selectedPricelist)
    pricelist ? loadSeasons(selectedPricelist) : null
  }, [selectedPricelist])
  return (
    <>
      <EditSeason
        updateData={() => loadSeasons(selectedPricelist)}
        data={editSeason.data}
        show={editSeason.show}
        onHide={() => setEditSeason(v => ({ ...v, show: false }))}
      />
      <NewPricelist
        show={newPricelist.show}
        onHide={() => setNewPricelist(v => ({ ...v, show: false }))}
        updateData={() => loadPricelists()}
      />
      <NewSeason
        pricelistKey={selectedPricelist}
        show={newSeason.show}
        onHide={() => setNewSeason(v => ({ ...v, show: false }))}
        updateData={() => loadSeasons(selectedPricelist)}
      />

      <div className="page-content">
        <Breadcrumb title="Anagrafiche" breadcrumbItem="Listini" />
        <Container fluid={true}>
          <Row>
            <Col xs="4">
              <Select
                onChange={e => setSelectedPricelist(e.value)}
                options={pricelist.map(v => ({ value: v.id, label: v.name }))}
              />
            </Col>
            <Col xs={4}>
              <button
                className="btn btn-primary"
                onClick={() => setNewPricelist(v => ({ ...v, show: true }))}
              >
                Nuovo listino
              </button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col xs={6}>
                      {selectedPricelist ? (
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            setNewSeason(v => ({ ...v, show: true }))
                          }
                        >
                          Nuova stagione
                        </button>
                      ) : null}
                    </Col>
                  </Row>
                  <Table>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Da</th>
                        <th>a</th>
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      {seasons.map((v, i) => (
                        <tr key={v.id}>
                          <td>{v.name}</td>
                          <td>
                            {v.from_day} {getMonthName(v.from_month)}
                          </td>
                          <td>
                            {v.to_day} {getMonthName(v.to_month)}
                          </td>
                          <td>
                            <Button onClick={() => handleModify(i)}>
                              Modifica
                            </Button>
                            <button
                              className="btn btn-danger mx-2"
                              onClick={() => deleteRecord(v.id)}
                            >
                              Elimina
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Pricelist
