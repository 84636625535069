import React from "react"
import { BsCurrencyEuro } from "react-icons/bs"
export default function Bookingrecord(props) {
  const {
    x,
    y,
    w,
    h,
    isSeasonal,
    number,
    elements,
    env,
    paid,
    src,
    surname,
    day,
  } = props
  return (
    <div
      className={` m-1 position-relative rounded-1 overflow-hidden  d-flex flex-column justify-content-start ${
        !env ? "bg-white gridItem" : "justify-content-center align-items-center"
      }  align-items-start  ${
        isSeasonal ? null /* "border border-primary" */ : null
      }`}
      data-id={props.id}
      style={{
        padding: "2px",
        gridColumn: `${x + 1}/${w + x + 1}`,
        gridRow: `${y + 1}/${h + y + 1}`,
      }}
      onClick={() => (env ? null : props.handleClick())}
    >
      <div className="d-flex w-100">
        <small>{number !== "empty" ? number : " "}</small>
        {!env ? (
          <small style={{ marginLeft: "auto" }}>
            {elements && elements.length > 0 ? elements.join("") : null}
          </small>
        ) : null}
      </div>
      <div className="d-flex ">
        <img
          src={src}
          style={{
            bottom: 2,
            right: 0,

            aspectRatio: !env ? "1/1" : null,
            height: !env ? `20px` : "100%",
            maxWidth: "100%",
          }}
          className=" "
        />
        {!paid && !env ? (
          <span className="h-50 d-flex  text-danger">
            <BsCurrencyEuro size={15} />
          </span>
        ) : null}
      </div>
      <footer className="d-flex w-100 justify-content-between">
        <small>{day}</small>
        <small>{surname ? surname.substring(0, 3) : null}</small>
      </footer>
    </div>
  )
}
