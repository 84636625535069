import { createVariantWithPrice } from "helpers/fakebackend_helper"
import React, { useEffect } from "react"
import { useState } from "react"
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

export default function ModalVWP({ show, onHide, append }) {
  const [disabled, setDisabled] = useState(false)
  const HandleSubmit = async () => {
    setDisabled(true)
    const request = await createVariantWithPrice(data)
    if (request.id) {
      setDisabled(false)
      onHide()
      append(request.id, data.name, data.price)
    }
  }
  useEffect(() => {
    setData({ name: "", price: 0 })
  }, [show])
  const [data, setData] = useState({ name: "", price: 0 })
  return (
    <Modal isOpen={show} toggle={onHide}>
      <ModalHeader>Aggiungi una variante con prezzo</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={8}>
            <label>Nome</label>
            <Input
              type="text"
              value={data.name}
              onChange={e => setData(v => ({ ...v, name: e.target.value }))}
            />
          </Col>
          <Col>
            <label>Prezzo</label>
            <InputGroup>
              <InputGroupText>€</InputGroupText>
              <Input
                value={data.price}
                onChange={e => setData(v => ({ ...v, price: e.target.value }))}
                type="number"
                defaultValue={0}
              />
            </InputGroup>
          </Col>
        </Row>
      </ModalBody>

      {/* <ModalFooter>loipiò0poiòoiòpoiòopòopbnòo-p0v8i</ModalFooter>  <-- il contributo di giacomo*/}
      <ModalFooter>
        <Button onClick={() => onHide()}>Annulla</Button>
        <Button color="primary" onClick={HandleSubmit} disabled={disabled}>
          Salva
        </Button>
      </ModalFooter>
    </Modal>
  )
}
