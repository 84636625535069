import React, { useEffect, useState } from "react"
import {
  retrieveEvents,
  subscriptionsInEvent,
  ticketsOfEvent,
} from "helpers/fakebackend_helper"
import { Col, Row, Card, CardBody, CardHeader } from "reactstrap"
import Chart from "react-apexcharts"

export default function Dashboard() {
  const [signups, setSignups] = useState([])
  const [tickets, setTickets] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [events, setEvents] = useState([])

  const fetchEvents = async () => {
    const data = await retrieveEvents()
    setEvents(data)
  }

  const retrieveEventSignups = async () => {
    const data = await subscriptionsInEvent()

    setSignups(data)
  }
  const fetchTickets = async () => {
    const data = await ticketsOfEvent(selectedEvent)
    console.log(data)
    setTickets(data)
  }

  useEffect(() => {
    fetchEvents()
    retrieveEventSignups()
  }, [])

  useEffect(() => {
    if (selectedEvent !== null && selectedEvent !== "") {
      fetchTickets()
    }
  }, [selectedEvent])

  return (
    <div className="page-content">
      <Row>
        <Col xs={7}>
          <Card>
            <CardBody>
              <h3>Registrazioni egli eventi</h3>

              <Chart
                type="bar"
                series={
                  signups.length > 0
                    ? [
                        {
                          name: "Registrazioni",
                          data: signups.map(v => v.conta),
                        },
                      ]
                    : []
                }
                options={{
                  xaxis: {
                    categories: signups.map(v => v.title),
                  },
                }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={5}>
          <Card>
            <CardBody>
              <h3>Ticket acquistati per evento</h3>
              <label>Seleziona un evento</label>
              <select
                className="form-select mb-3"
                value={selectedEvent}
                onChange={e => setSelectedEvent(e.target.value)}
              >
                <option value="">Seleziona un evento</option>
                {events.map(event => (
                  <option key={event.id} value={event.id}>
                    {event.title}
                  </option>
                ))}
              </select>
              {selectedEvent == "" || selectedEvent === null ? <p>Seleziona un evento</p> :
                <Chart
                type="pie"
                series={
                  tickets.length > 0
                    ? tickets.map(v => v.conta)
                    : [{ data: [] }]
                }
                options={{
                  labels: tickets.map(v => v.name),
                }}

              />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
