import { Input, Modal, Row, Col } from "reactstrap"
import React from "react"
import { useEffect, useState } from "react"
import {
  createCode,
  createUmbrellaFromBackend,
  patchUmbrellaFromBackend,
} from "helpers/fakebackend_helper"

const UmbrellaModal = props => {
  const [pending, setPending] = useState(false)
  const [numbertaken, setNumbertaken] = useState(false)
  const [insertedData, setInsertedData] = useState({
    qrcode: "",
    number: 0,
    clients: 0,
  })
  const [id, setId] = useState(false)
  const getCode = async () => {
    const { data } = await createCode()
    console.log(data)
    setInsertedData(v => ({ ...v, qrcode: data }))
  }
  useEffect(() => {
    if (!props.data) {
      setId(false)
      setInsertedData({ qrcode: "", number: 0, clients: 0 })
    } else {
      setId(props.data.id)
    }
  }, [props.data])

  useEffect(() => {
    if (props.show) {
      getCode()
    }
  }, [props.show])
  useEffect(() => {
    if (!props.data) {
      getCode()
    } else {
      setInsertedData({
        qrcode: props.data.qrcode,
        number: props.data.number,
        clients: props.data.max_customers,
      })
    }
  }, [props.data])
  const handleSubmit = async () => {
    setPending(true)
    if (!id) {
      const data = await createUmbrellaFromBackend(
        insertedData.number,
        insertedData.qrcode,
        insertedData.clients,
        props.table ? 1 : 0
      )
      if (data.status) {
        setPending(false)
        setInsertedData({
          number: 0,
          qrcode: 0,
          clients: 0,
        })
        props.updateData()
        props.onHide()
      }
    } else {
      const data = await patchUmbrellaFromBackend(
        insertedData.number,
        insertedData.qrcode,
        insertedData.clients,
        id
      )
      if (data.status) {
        setPending(false)
        setInsertedData({
          number: 0,
          qrcode: 0,
          clients: 0,
        })
        props.updateData()
        props.onHide()
      }
    }
  }
  useEffect(() => {
    let updatedNumbers = []
    const numberToRemove = props.data ? parseInt(props.data.number) : 0 // Convert the number to remove to an integer
    updatedNumbers = props.numbers.filter(v => parseInt(v) !== numberToRemove) // Use filter() to remove all instances of the number
    const hasFound = updatedNumbers.findIndex(
      v => parseInt(v) == parseInt(insertedData.number)
    )
    console.log(hasFound)
    if (hasFound !== -1) {
      setNumbertaken(true)
    } else {
      setNumbertaken(false)
    }
  }, [insertedData.number])
  return (
    <Modal
      className="modal-lg"
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Gestione {props.table ? "tavoli" : "ombrelloni"}
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-2 align-items-end">
          {/*           <Col xs={12} md={4} className="mb-2 mb-sm-0">
            <label>Codice QR</label>
            <div className="input-group">
              <button className="btn btn-primary" onClick={() => getCode()}>
                Genera
              </button>

              <Input
                type="text"
                value={insertedData.qrcode}
                onChange={e =>
                  setInsertedData(v => ({ ...v, qrcode: e.target.value }))
                }
              />
            </div>
          </Col> */}
          <Col xs={12} md={6} className="mb-2 mb-sm-0">
            <label>Numero</label>
            <Input
              value={insertedData.number}
              onChange={e =>
                setInsertedData(v => ({
                  ...v,
                  number: e.target.value,
                }))
              }
            />
            <label
              className={`position-absolute text-danger ${
                !numbertaken ? "d-none" : false
              }`}
            >
              Numero già preso
            </label>
          </Col>
          <Col xs={12} md={6}>
            <label>Massimo clienti associabili (0 infiniti)</label>
            <Input
              type="number"
              min={1}
              value={insertedData.clients}
              onChange={e =>
                setInsertedData(v => ({
                  ...v,
                  clients: parseInt(e.target.value),
                }))
              }
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          type="button"
          className="btn btn-primary "
          onClick={handleSubmit}
          disabled={pending}
        >
          Salva
        </button>
      </div>
    </Modal>
  )
}

export default UmbrellaModal
