import {
  loadSubCategories,
  patchOrPutSubCategory,
} from "helpers/fakebackend_helper"
import { useEffect, useState } from "react"
import React from "react"
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  Container,
  Card,
  CardBody,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { v4 } from "uuid"
import { BiDrink } from "react-icons/bi"
import { GiKnifeFork } from "react-icons/gi"
import { BsPlus } from "react-icons/bs"
import Modalrow from "../../components/bar/Modalrow"
import Newcategory from "components/bar/Newcategory"
import { Prompt } from "react-router-dom"

const Categories = () => {
  const [categories, setCategories] = useState([])
  const [selectedRecord, setSelectedRecord] = useState({ timeslots: [] })
  const [selectedTimeslot, setSelectedTimeslot] = useState(false)
  const [toUpdateCategory, setToUpdateCategory] = useState(false)
  const [category, setCategory] = useState(false)
  const [toUpdate, setToUpdate] = useState(false)

  const getCategories = async () => {
    const data = await loadSubCategories()

    setCategories(data)
  }
  useEffect(() => {
    getCategories()
  }, [toUpdate, toUpdateCategory])

  const handleSubmit = async () => {
    setSelectedRecord(v => {
      const arr = v.timeslots
      arr.push(selectedTimeslot)
      return { ...v, timeslots: arr }
    })
    //put the selected timeslot in the request object, which starts from selectedRecord
    const request = {
      ...selectedRecord,
      timeslots: selectedRecord.timeslots.map(v => {
        if (v.id === selectedTimeslot.id) {
          return selectedTimeslot
        }
        return v
      }),
    }
    const data = await patchOrPutSubCategory(request)
    if (data.status) {
      alert("Categoria aggiornata correttamente")
      setSelectedRecord({ timeslots: [] })
      /* onHide() */
      getCategories()
    }
  }
  useEffect(() => {
    //reset timeslot when change category
    setSelectedTimeslot(false)
  }, [selectedRecord.id])
  useEffect(() => {
    if (selectedRecord.timeslots.length > 0) {
      setSelectedTimeslot(
        selectedRecord.timeslots[selectedRecord.timeslots.length - 1]
      )
    }
  }, [JSON.stringify(selectedRecord.timeslots)])
  return (
    <div className="page-content">
      <Newcategory
        show={category}
        onHide={() => setCategory(false)}
        triggerUpdateCategory={setToUpdateCategory}
      />
      <Container fluid={true}>
        <h3>Categorie</h3>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col>
                    <Button color="dark" onClick={() => setCategory(true)}>
                      Aggiungi
                    </Button>
                  </Col>
                </Row>
                <div>
                  {selectedRecord.id && (
                    <>
                      <Row>
                        <Col>
                          <label>Nome categoria</label>
                          <Input
                            value={selectedRecord.name}
                            onChange={e =>
                              setSelectedRecord(v => ({
                                ...v,
                                name: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Tipo</label>
                          <select
                            className="form-select"
                            value={selectedRecord.food_or_drink}
                            onChange={e =>
                              setSelectedRecord(v => ({
                                ...v,
                                food_or_drink: e.target.value,
                              }))
                            }
                          >
                            <option value={"drink"}>Drink</option>
                            <option value={"food"}>Cibo</option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <label>Tempo di preparazione</label>
                          <InputGroup>
                            <Input
                              type="number"
                              value={selectedRecord.preparation_time}
                              onChange={({ target }) =>
                                setSelectedRecord(v => ({
                                  ...v,
                                  preparation_time: target.value,
                                }))
                              }
                            />
                            <InputGroupText>Minuti</InputGroupText>
                          </InputGroup>
                        </Col>
                        <Col>
                          <label>Qta. giornaliera (0 se NON DEFINITA)</label>
                          <Input
                            type="number"
                            value={selectedRecord.day_quantity || 0}
                            onChange={({ target }) =>
                              setSelectedRecord(v => ({
                                ...v,
                                day_quantity: target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Ordine stampa</label>

                          <Input
                            type="number"
                            value={selectedRecord.print_ordering || 0}
                            onChange={({ target }) =>
                              setSelectedRecord(v => ({
                                ...v,
                                print_ordering: target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <h4 className="mt-3 mb-3">Orari apertura categoria</h4>
                      <InputGroup>
                        <select
                          className="form-select"
                          value={selectedTimeslot && selectedTimeslot.id}
                          onChange={e => {
                            if (selectedTimeslot.id) {
                              setSelectedRecord(v => {
                                let timeslots = v.timeslots
                                const index = timeslots.findIndex(
                                  v => v.id == selectedTimeslot.id
                                )
                                if (index !== -1) {
                                  timeslots[index] = selectedTimeslot
                                } else {
                                  timeslots.push(selectedTimeslot)
                                }
                                return { ...v, timeslots }
                              })
                            }
                            setSelectedTimeslot(
                              selectedRecord.timeslots.find(
                                v => v.id == e.target.value
                              )
                            )
                          }}
                        >
                          <option value={false}>Seleziona un timeslot</option>
                          {selectedRecord.timeslots.map((timeslot, i) => (
                            <option key={i} value={timeslot.id}>
                              {timeslot.time_from} - {timeslot.time_to} -{" "}
                              {getDay(timeslot.day)}
                            </option>
                          ))}
                        </select>
                        <Button
                          onClick={() =>
                            setSelectedRecord(v => {
                              const timeslots = v.timeslots
                              timeslots.push({
                                toAdd: true,
                                id: v4(),
                                time_from: "00:00",
                                time_to: "00:00",
                                day: 1,
                              })
                              return { ...v, timeslots }
                            })
                          }
                        >
                          Aggiungi
                        </Button>
                      </InputGroup>

                      {selectedTimeslot && (
                        <>
                          <Row className="mt-2">
                            <Col>
                              <label>Da</label>

                              <Input
                                type="time"
                                value={
                                  selectedTimeslot
                                    ? selectedTimeslot.time_from
                                    : "00:00"
                                }
                                onChange={({ target }) =>
                                  setSelectedTimeslot(v => ({
                                    ...v,
                                    time_from: target.value,
                                  }))
                                }
                              />
                            </Col>
                            <Col>
                              <label>Da</label>

                              <Input
                                type="time"
                                value={
                                  selectedTimeslot
                                    ? selectedTimeslot.time_to
                                    : "00:00"
                                }
                                onChange={({ target }) =>
                                  setSelectedTimeslot(v => ({
                                    ...v,
                                    time_to: target.value,
                                  }))
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <label>Giorno</label>

                              <select
                                className="form-select"
                                value={
                                  selectedTimeslot ? selectedTimeslot.day : 1
                                }
                                onChange={e =>
                                  setSelectedTimeslot(v => ({
                                    ...v,
                                    day: e.target.value,
                                  }))
                                }
                              >
                                <option value={1}>Lunedì</option>
                                <option value={2}>Martedì</option>
                                <option value={3}>Mercoledì</option>
                                <option value={4}>Giovedì</option>
                                <option value={5}>Venerdì</option>
                                <option value={6}>Sabato</option>
                                <option value={7}>Domenica</option>
                              </select>
                            </Col>
                          </Row>{" "}
                        </>
                      )}
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={handleSubmit}
                          className="mt-2"
                          color="primary"
                        >
                          Salva
                        </Button>
                      </div>
                    </>
                  )}
                  {!selectedRecord && (
                    <p className="text-muted fst-italic">
                      la categoria selezionata comparirà qui
                    </p>
                  )}
                </div>
                <Row>
                  <Col xs={4}>
                    <strong>Categoria</strong>
                  </Col>
                  <Col xs={1}>
                    <strong>Tipo</strong>
                  </Col>
                  <Col xs={2}>
                    <strong>Tempo preparazione</strong>
                  </Col>
                  <Col xs={2}>
                    <strong>Quantità giornaliera</strong>
                  </Col>
                  <Col xs={3}>
                    <strong>Azioni</strong>
                  </Col>
                </Row>
                {categories.map(category => (
                  <Modalrow
                    refresh={() => setToUpdate(v => !v)}
                    key={category.id}
                    category={category}
                  />
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Categories
