import Modalmodify from "components/loans/Modalmodify"
import { patchLoanBooking, retrieveLoans } from "helpers/fakebackend_helper"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

const Loans = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(2)
  }, [])
  const [modalModify, setModalModify] = useState({
    show: false,
    data: [],
    id: 0,
  })
  const [object, setObject] = useState([])
  const getLoans = async () => {
    const data = await retrieveLoans()
    setObject(data)
  }
  useEffect(() => {
    getLoans()
  }, [])
  const handleLoanChange = async id => {
    const conf = window.confirm("Sei sicuro di voler rimuovere il subaffitto?")
    if (!conf) return

    const data = await patchLoanBooking({
      id: id,
      loaned: 0,
    })
    if (data.status) {
      alert("Stato prenotazione modificato")
      getLoans()
    }
  }
  return (
    <>
      <Modalmodify
        handleUpdate={getLoans}
        show={modalModify.show}
        data={modalModify.data}
        onHide={() => setModalModify(v => ({ ...v, show: false }))}
        id={modalModify.id}
      />
      <div className="page-content">
        <h3>Subaffitto</h3>
        <Table>
          <thead>
            <tr>
              <th>Ombrellone</th>
              <th>Prenotazione Da</th>
              <th>Prenotazione A</th>
              <th>Periodo subaffittabile</th>
              <th>Subaffitti</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {object.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.number}</td>
                  <td>{new Date(item.booked_from).toLocaleDateString()}</td>
                  <td>{new Date(item.booked_to).toLocaleDateString()}</td>
                  <td>
                    {item.loaning_times.map(v => (
                      <div key={v.id}>
                        Dal{" "}
                        {new Date(v.date_from).toLocaleDateString() +
                          " Al " +
                          new Date(v.date_to).toLocaleDateString()}
                      </div>
                    ))}
                  </td>
                  <td>
                    {item.ext_bookings.map(v => (
                      <div key={v.id}>
                        {new Date(v.booked_from).toLocaleDateString() +
                          " - " +
                          new Date(v.booked_to).toLocaleDateString()}
                      </div>
                    ))}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() =>
                        setModalModify(v => ({
                          id: item.id,
                          data: item.loaning_times,
                          show: true,
                        }))
                      }
                    >
                      Modifica periodi subaffitto
                    </button>
                    <button
                      className="btn btn-danger m-1"
                      onClick={() => handleLoanChange(item.id)}
                    >
                      Rimuovi subaffittabile
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default Loans
