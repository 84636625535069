import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import Tablerow from "./Tablerow"
import { BsClock, BsCurrencyEuro, BsTrash } from "react-icons/bs"
import Select from "react-select"
import {
  addTimeslotFunction,
  deleteCategory,
  deleteSingleTimeslot,
  deleteTimeslot,
} from "helpers/fakebackend_helper"
export default function Slotmodal({
  show,
  data,
  onHide,
  handleUpdate,
  toUpdate,
  updateData,
}) {
  const [timeslotsData, setTimeslotsData] = useState(null)
  useEffect(() => {
    if (data && data.slots) {
      setTimeslotsData(data)
    }
  }, [JSON.stringify(data)])
  const [selectedDay, setSelectedDay] = useState({ value: false, index: false })
  const [values, setValues] = useState({
    places_fields_id: false,
    from: false,
    to: false,
    weeksday: "",
    duration: 60,
    diurnal_price: 0,
    nocturnal_price: 0,
  })
  useEffect(() => {
    if (data) {
      setValues({
        places_fields_id: data.id,
        from: data.opening_time_booking,
        to: data.nocturnal_time,
        weeksday: "",
        duration: 60,
        diurnal_price: 0,
        nocturnal_price: 0,
      })
    }
  }, [data])
  const appendTimeslot = async () => {
    const dayIndex = timeslotsData.slots.findIndex(
      v => v.key == selectedDay.value
    )
    console.log(dayIndex)
    if (dayIndex !== -1) {
      let day = timeslotsData.slots[dayIndex].slots
      day.push({
        end_slot_time: "00:00",
        start_slot_time: "00:00",
        id: null,
        price: "0.00",
        type: "diurno",
      })
      updateData(v => {
        let data = v.data
        console.log(v)
        console.log(data.slots)
        data.slots[dayIndex].slots = day
        return { data: data, show: v.show }
      })
    }
  }
  useEffect(() => {
    if (timeslotsData)
      setValues(v => ({
        ...v,
        places_fields_id: timeslotsData.id,
        from: timeslotsData.opening_time_booking,
        to: timeslotsData.nocturnal_time,
        duration: timeslotsData.duration,
        nocturnal_price: timeslotsData.nocturnal_price,
        diurnal_price: timeslotsData.diurnal_price,
      }))
  }, [timeslotsData])
  const dayKeys = [
    { italian: "Lunedì", key: "Monday" },
    { italian: "Martedì", key: "Tuesday" },
    { italian: "Mercoledì", key: "Wednesday" },
    { italian: "Giovedì", key: "Thursday" },
    { italian: "Venerdì", key: "Friday" },
    { italian: "Sabato", key: "Saturday" },
    { italian: "Domenica", key: "Sunday" },
  ]
  let freeDays = [...dayKeys]
  data &&
    data.slots &&
    data.slots.forEach(v => {
      const index = freeDays.findIndex(el => el.key == v.key)
      if (index !== -1) {
        freeDays.splice(index, 1)
      }
    })
  const handleDeleteTimeslot = async (id, key) => {
    const data = await deleteTimeslot({ id: id, key: key })

    if (data.status) {
      await handleUpdate()
      toUpdate(v => !v)
    }
  }
  const spliceIndex = async (id, index) => {
    const conf = confirm("Sei sicuro di voler eliminare questo timeslot?")
    if (conf == false) {
      return
    }

    if (id) {
      const data = await deleteSingleTimeslot(id)
      if (data.status) {
        alert("Timeslot eliminato con successo")
        await handleUpdate()
        toUpdate(v => !v)
      }
      return
    } else {
      const dayIndex = timeslotsData.slots.findIndex(
        v => v.key == selectedDay.value
      )
      let day = timeslotsData.slots[dayIndex].slots
      day = day.filter((v, i) => i !== index)
      updateData(v => {
        let data = v.data
        data.slots[dayIndex].slots = day
        alert("Timeslot eliminato con successo")
        return { data: data, show: v.show }
      })
    }
  }
  const handleAddTimeslot = async () => {
    if (values.weeksday.length == 0) {
      return alert("Inserisci prima uno o più giorni")
    }
    const formattedWeekdays = values.weeksday.map(v => v.value)
    const data = await addTimeslotFunction({
      ...values,
      weeksday: formattedWeekdays,
    })
    if (data == "ok") {
      await handleUpdate()
      toUpdate(v => !v)
      setValues(v => ({ ...v, weeksday: [] }))
    }
  }

  //order timeslotsData.slots by day of the week
  if (timeslotsData && timeslotsData.slots) {
    timeslotsData.slots.sort((a, b) => {
      const aIndex = dayKeys.findIndex(v => v.key == a.key)
      const bIndex = dayKeys.findIndex(v => v.key == b.key)
      return aIndex - bIndex
    })
  }
  
  return (
    <Modal isOpen={show} toggle={() => onHide()} size="xl">
      <ModalHeader toggle={() => onHide()}>Slots</ModalHeader>
      <ModalBody>
        {selectedDay.index !== false ? (
          <>
            <Card className=" mt-3">
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Dalle</th>
                      <th>Alle</th>
                      <th>Tipo</th>
                      <th>Prezzo</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeslotsData &&
                      timeslotsData.slots &&
                      timeslotsData.slots[selectedDay.index].slots.map(
                        (v, c) => {
                          return (
                            <Tablerow
                              spliceIndex={spliceIndex}
                              handleUpdate={handleUpdate}
                              obj={v}
                              key={v.id}
                              index={c}
                              id={timeslotsData.id}
                              day={selectedDay.value}
                            />
                          )
                        }
                      )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            {timeslotsData &&
              timeslotsData.slots &&
              timeslotsData.slots.map((v, i) => {
                const italianIndex = dayKeys.findIndex(el => el.key == v.key)
                let value = ""
                if (italianIndex != -1) {
                  value = dayKeys[italianIndex].italian
                }
                return (
                  <Card className="bg-light " key={i}>
                    <CardBody className="d-flex w-100 align-items-center">
                      <a
                        href="#"
                        onClick={() =>
                          setSelectedDay({ value: v.key, index: i })
                        }
                      >
                        {value}
                      </a>
                      <BsTrash
                        onClick={() => handleDeleteTimeslot(data.id, v.key)}
                        style={{ marginLeft: "auto", cursor: "pointer" }}
                        size={20}
                        className="text-danger"
                      />
                    </CardBody>
                  </Card>
                )
              })}
            {freeDays.length > 0 ? (
              <section className="mt-5">
                <Row>
                  <Col>
                    <label>Dalle</label>
                    <InputGroup>
                      <Input
                        type="time"
                        value={values.from}
                        onChange={e =>
                          setValues(v => ({ ...v, from: e.target.value }))
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label>Alle</label>
                    <InputGroup>
                      <Input
                        type="time"
                        value={values.to}
                        onChange={e =>
                          setValues(v => ({ ...v, to: e.target.value }))
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label>Giorno</label>
                    <Select
                      isMulti
                      value={values.weeksday}
                      onChange={e => setValues(v => ({ ...v, weeksday: e }))}
                      options={freeDays.map(v => ({
                        value: v.key,
                        label: v.italian,
                      }))}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <label>Durata singolo slot prenotabile</label>
                    <InputGroup className="m-1">
                      <Input
                        type="number"
                        value={values.duration}
                        onChange={e =>
                          setValues(v => ({ ...v, duration: e.target.value }))
                        }
                      />
                      <InputGroupText>Min.</InputGroupText>
                    </InputGroup>
                  </Col>
                  <Col>
                    <label>Costo diurno a slot €</label>
                    <InputGroup className="m-1">
                      <Input
                        type="number"
                        value={values.diurnal_price}
                        onChange={e =>
                          setValues(v => ({
                            ...v,
                            diurnal_price: e.target.value,
                          }))
                        }
                      />
                      <InputGroupText>
                        <BsCurrencyEuro />
                      </InputGroupText>
                    </InputGroup>
                  </Col>
                  <Col>
                    <label>Costo notturno a slot €</label>
                    <InputGroup className="m-1">
                      <Input
                        type="number"
                        value={values.nocturnal_price}
                        onChange={e =>
                          setValues(v => ({
                            ...v,
                            nocturnal_price: e.target.value,
                          }))
                        }
                      />
                      <InputGroupText>
                        <BsCurrencyEuro />
                      </InputGroupText>
                    </InputGroup>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {
          /* selectedDay.value == false &&  */ <>
            {timeslotsData && timeslotsData.slots.length < 7 && (
              <Button color="primary" onClick={() => handleAddTimeslot()}>
                Aggiungi timeslots
              </Button>
            )}
            {selectedDay.value && (
              <>
                {" "}
                <Button color="success" onClick={() => appendTimeslot()}>
                  Aggiungi
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setSelectedDay({ value: false, index: false })}
                >
                  Ritorna a giorni
                </Button>
              </>
            )}
          </>
        }
        <Button color="dark" onClick={() => onHide()}>
          Ritorna indietro
        </Button>
      </ModalFooter>
    </Modal>
  )
}
