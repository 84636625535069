import { Input, Modal, Row, Col, Button } from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  getSeasons,
  getSeasonsNew,
  patchSeason,
  patchSeasonNew,
  patchType,
  putPricelist,
  putSeason,
  putSector,
} from "helpers/fakebackend_helper"
import { BsPlus, BsTrash } from "react-icons/bs"

const EditSeasonNew = props => {
  const [callPending, setCallPending] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    date_from: "",
    date_to: "",
    periods: [],
    id: false,
  })
  const checkOverlaps = async () => {
    const data = await getSeasonsNew()
    const dataWithoutCurrentId = data.filter(v => v.id !== formData.id)
    let overlap = false
    let allPeriods = []
    dataWithoutCurrentId.forEach(v =>
      v.periods.forEach(el =>
        allPeriods.push({ from: el.date_from, to: el.date_to })
      )
    )
    formData.periods.forEach(v =>
      allPeriods.push({ from: v.date_from, to: v.date_to })
    )
    for (let i = 0; i < allPeriods.length; i++) {
      for (let c = 0; c < allPeriods.length; c++) {
        if (c == i) {
        } else if (
          allPeriods[c].from <= allPeriods[i].to &&
          allPeriods[i].from <= allPeriods[c].to
        ) {
          overlap = true
        }
      }
    }
    return overlap
  }
  const handleSubmit = async () => {
    const overlap = await checkOverlaps()
    if (overlap) {
      return alert(
        "Le nuove date in questo periodo va in collisione con le altre già inserite, controlla e riprova"
      )
    }
    setCallPending(true)
    const resp = await patchSeasonNew(formData)
    if (resp.status) {
      setCallPending(false)
      props.onHide()
    }
  }
  const addPeriod = () => {
    setFormData(v => {
      let arr = v
      let { periods } = arr
      periods = [...periods, { date_from: "", date_to: "" }]
      arr = { ...arr, periods: periods }
      return arr
    })
  }
  useEffect(() => {
    setFormData(props.data)
  }, [props.show])
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Modifica periodo
        </h5>

        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <label>Nome</label>
            <Input
              value={formData.name}
              onChange={e => setFormData(v => ({ ...v, name: e.target.value }))}
            />
          </Col>
        </Row>
        {formData.periods &&
          formData.periods.map((v, i) => (
            <Row key={i} className="mt-3">
              <Col>
                <label>Da</label>
                <Input
                  type="date"
                  value={v.date_from}
                  onChange={e =>
                    setFormData(() => {
                      const arr = formData
                      let periods = arr.periods
                      periods[i] = { ...periods[i], date_from: e.target.value }
                      return { ...arr, periods: periods }
                    })
                  }
                />
              </Col>
              <Col>
                <label>A</label>
                <Input
                  type="date"
                  value={v.date_to}
                  onChange={e =>
                    setFormData(() => {
                      const arr = formData
                      let periods = arr.periods
                      periods[i] = { ...periods[i], date_to: e.target.value }
                      return { ...arr, periods: periods }
                    })
                  }
                />
              </Col>
              <Col className="d-flex flex-column">
                <label>&nbsp;</label>
                <div className="d-flex w-100">
                  <button
                    className="btn btn-danger mx-1"
                    onClick={e =>
                      setFormData(v => {
                        const periods = v.periods.filter((el, c) => i !== c)
                        return { ...v, periods: periods }
                      })
                    }
                  >
                    <BsTrash />
                  </button>
                  {i == formData.periods.length - 1 && (
                    <button
                      className="btn btn-success mx-1"
                      onClick={addPeriod}
                    >
                      <BsPlus />
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          ))}
        {formData.periods && formData.periods.length == 1 && (
          <Row className="mt-2">
            <Col>
              <button className="btn btn-success" onClick={addPeriod}>
                Aggiungi periodo
              </button>
            </Col>
          </Row>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            const conf = confirm(
              "Sei sicuro di voler uscire? le modifiche non salvate andranno perse"
            )
            if (!conf) return
            props.onHide()
            setFormData({
              name: "",
              date_from: "",
              date_to: "",
              id: false,
              periods: [],
            })
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          onClick={handleSubmit}
          disabled={callPending}
          type="button"
          className="btn btn-primary "
        >
          Salva
        </button>
      </div>
    </Modal>
  )
}

export default EditSeasonNew
