import React, { useEffect, useRef } from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Container,
  Button,
  Modal,
  Input,
  InputGroup,
  InputGroupText,
  Form,
  Toast,
  ToastBody,
} from "reactstrap"
import { useState } from "react"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import "./style.css"
import {
  getCabins,
  getElements,
  getPricelist,
  getFictionalCustomers,
  getPriceUmbrellas,
  getUmbrellas,
  getTypes,
  putBooking,
  getSeasonsNew,
  getSectors,
  getPricelistData,
  putPricelistData,
} from "helpers/fakebackend_helper"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreatableSelect from "react-select/creatable"
import Select from "react-select"
import moment from "moment"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { checkIfUserHasPurchasedModule } from "helpers/functions"

const ListinoPrezzi = () => {
  useEffect(() => {
    checkIfUserHasPurchasedModule(1)
  }, [])
  const [toggleSwitch, setToggleSwitch] = useState(true)
  const [userPlace, setUserPlace] = useState(null)
  const [types, setTypes] = useState([])
  const [umbrellaType, setUmbrellaType] = useState([])
  const [elements, setElements] = useState([])
  const [sectors, setSectors] = useState([])
  const [seasons, setSeasons] = useState([])
  const [pricelistId, setPricelistId] = useState(null)
  const [toast, setToast] = useState(false)
  const [toast2, setToast2] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [tabella, setTabella] = useState({
    header: {
      umbrellaColSpan: 0,
      elementsColSpan: 0,
      sectorsColSpan: 0,
      seasonsColSpan: 0,
      types: [],
      elements: [],
      sectors: [],
      seasons: [],
      //pricelistId: null,
    },
    body: {},
    rows: {
      0: "MEZZA GIORNATA",
      1: "GIORNALIERO",
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: "SETTIMANALE",
      8: 8,
      9: 9,
      10: 10,
      11: 11,
      12: 12,
      13: 13,
      14: 14,
      15: "QUINDICINALE",
      16: 16,
      17: 17,
      18: 18,
      19: 19,
      20: 20,
      21: 21,
      22: 22,
      23: 23,
      24: 24,
      25: 25,
      26: 26,
      27: 27,
      28: 28,
      29: 29,
      30: 30,
      31: "MENSILE",
      180: "STAGIONALE",
    },
  })

  const loadPricelist = async () => {
    const val = await getPricelist()
    setPricelistId(val)
    console.log("pricelist_id", val)
  }
  const loadTypes = async () => {
    const val = await getTypes()
    //importa solo gli elementi dispiegabili
    var x = val.filter(x => x.isEnvironment == 0 && x.isUmbrella != 1)
    var y = val.filter(x => x.isUmbrella === 1)
    console.log("all types", val)
    console.log("filtered types", x)
    console.log("umbrellaType", y)
    setUmbrellaType(y)
    setTypes(x)
  }
  const loadElements = async () => {
    const val = await getElements()
    console.log("elements", val)
    setElements(val)
  }
  const loadSectors = async () => {
    const val = await getSectors()
    if (val.length === 0) {
      alert(
        "I settori sono obbligatori. Vai in anagrafica ed inseriscine almeno uno."
      )
      return
    }
    console.log("sectors", val)
    setSectors(val)
  }
  const loadSeasons = async () => {
    const val = await getSeasonsNew()
    if (val.length === 0) {
      alert(
        "Le stagioni sono obbligatorie. Vai su Planner -> Periodi ed inseriscine almeno una."
      )
      return
    }
    console.log("seasons", val)
    setSeasons(val)
  }

  const loadTabella = async () => {}

  const saveDataToServer = async () => {
    //console.log("tabella da salvare", tabella)
    setIsDisabledButton(true)
    const returnData = await putPricelistData(tabella.body, pricelistId)
    if (returnData === "") {
      setToast(true)
      setTimeout(() => {
        setToast(false)
      }, 3000)
      setIsDisabledButton(false)
    } else {
      setToast2(true)
      setTimeout(() => {
        setToast2(false)
      }, 3000)
      setIsDisabledButton(false)
    }
  }

  useEffect(() => {
    loadPricelist()
    /*const userPlace = localStorage.getItem("authUser")
    setUserPlace(userPlace)*/
  }, [])

  useEffect(() => {
    ;(async () => {
      if (pricelistId != null) {
        loadTypes()
        loadElements()
        loadSectors()
        loadSeasons()
      }
    })()
  }, [pricelistId])

  useEffect(() => {
    ;(async () => {
      if (
        sectors.length > 0 &&
        seasons.length > 0 &&
        elements.length > 0
        //types.length > 0
      ) {
        const val = await getPricelistData(pricelistId)
        console.log("tabellacaricata", val)
        var umbrellaColSpan = sectors.length * seasons.length
        var elementsColSpan = elements.length * seasons.length
        var sectorsColSpan = seasons.length
        setTabella({
          ...tabella,
          header: {
            ...tabella.header,
            umbrellaColSpan: umbrellaColSpan,
            sectorsColSpan: sectorsColSpan,
            elementsColSpan: elementsColSpan,
            typeColSpan: seasons.length,
            sectors: sectors,
            elements: elements,
            seasons: seasons,
            types: types,
          },
          body: val,
        })
      }
    })()
  }, [sectors, seasons, elements, types])

  useEffect(() => {
    console.log("tab", tabella)
  }, [tabella])

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Planner" breadcrumbItem="Listino Prezzi" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <CardTitle>
                      Algoritmi di autocompilazione dei prezzi
                    </CardTitle>

                    <p className="card-title-desc">
                      ** Compila solo i campi in grigetto e seleziona un
                      algoritmo per l'autocompilazione
                    </p>

                    <div className="row row-cols-lg-auto g-2 align-items-center"></div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <Row>
                    <CardTitle>Listino</CardTitle>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-bordered border-dark text-center listino-prezzi"
                        >
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th colSpan={tabella.header.umbrellaColSpan}>
                                OMBRELLONE
                              </Th>
                              {tabella.header.elements &&
                                tabella.header.elements.length > 0 && (
                                  <Th colSpan={tabella.header.elementsColSpan}>
                                    ELEMENTI
                                  </Th>
                                )}

                              {tabella.header.types &&
                                tabella.header.types.length > 0 &&
                                tabella.header.types.map(elem => (
                                  <Th
                                    key={elem.id}
                                    colSpan={tabella.header.typeColSpan}
                                  >
                                    {elem.name.toUpperCase()}
                                  </Th>
                                ))}
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr className="small">
                              <Td></Td>
                              {tabella.header.sectors &&
                                tabella.header.sectors.length > 0 &&
                                tabella.header.sectors.map(element => (
                                  <Td
                                    key={element.id}
                                    colSpan={tabella.header.sectorsColSpan}
                                  >
                                    Settore {element.name}
                                  </Td>
                                ))}
                              {tabella.header.elements &&
                                tabella.header.elements.length > 0 &&
                                tabella.header.elements.map(element => (
                                  <Td
                                    key={element.id}
                                    colSpan={tabella.header.sectorsColSpan}
                                  >
                                    {element.name}
                                  </Td>
                                ))}
                              {/* <Td colSpan={tabella.header.sectorsColSpan}></Td> */}
                            </Tr>
                            <Tr className="small">
                              <Td>Giorni</Td>
                              {tabella.header.sectors &&
                                tabella.header.sectors.length > 0 &&
                                tabella.header.sectors.map(
                                  () =>
                                    tabella.header.seasons &&
                                    tabella.header.seasons.length > 0 &&
                                    tabella.header.seasons.map(season => (
                                      <Td key={season.id}>{season.name}</Td>
                                    ))
                                )}
                              {tabella.header.elements &&
                                tabella.header.elements.length > 0 &&
                                tabella.header.elements.map(
                                  () =>
                                    tabella.header.seasons &&
                                    tabella.header.seasons.length > 0 &&
                                    tabella.header.seasons.map(season => (
                                      <Td key={season.id}>{season.name}</Td>
                                    ))
                                )}
                              {tabella.header.types &&
                                tabella.header.types.length > 0 &&
                                tabella.header.types.map(
                                  () =>
                                    tabella.header.seasons &&
                                    tabella.header.seasons.length > 0 &&
                                    tabella.header.seasons.map(season => (
                                      <Td key={season.id}>{season.name}</Td>
                                    ))
                                )}
                            </Tr>
                            {tabella.rows &&
                              Object.keys(tabella.rows).length > 0 &&
                              Object.entries(tabella.rows).map(([key, row]) => {
                                var classname =
                                  typeof row == "string"
                                    ? "bg-light align-middle"
                                    : "align-middle"
                                return (
                                  <Tr key={key} className={classname}>
                                    <Td>{row}</Td>
                                    {tabella.header.sectors &&
                                      tabella.header.sectors.length > 0 &&
                                      tabella.header.sectors.map(
                                        sector =>
                                          tabella.header.seasons &&
                                          tabella.header.seasons.length > 0 &&
                                          tabella.header.seasons.map(season => {
                                            var sectorId = sector.id
                                            var seasonId = season.id
                                            return (
                                              <Td
                                                key={sectorId + "-" + seasonId}
                                              >
                                                <Input
                                                  type="number"
                                                  min={0}
                                                  value={
                                                    umbrellaType[0]
                                                      ? tabella.body[
                                                          [
                                                            key +
                                                              "-" +
                                                              "OMBRELLONE_" +
                                                              umbrellaType[0]
                                                                .id +
                                                              "-" +
                                                              sectorId +
                                                              "-" +
                                                              seasonId,
                                                          ]
                                                        ]
                                                      : null
                                                      ? tabella.body[
                                                          key +
                                                            "-" +
                                                            "OMBRELLONE_" +
                                                            umbrellaType[0].id +
                                                            "-" +
                                                            sectorId +
                                                            "-" +
                                                            seasonId
                                                        ]
                                                      : ""
                                                  }
                                                  onChange={e => {
                                                    //if (e.target.value != "") {
                                                    setTabella({
                                                      ...tabella,
                                                      body: {
                                                        ...tabella.body,
                                                        [key +
                                                        "-" +
                                                        "OMBRELLONE_" +
                                                        umbrellaType[0].id +
                                                        "-" +
                                                        sectorId +
                                                        "-" +
                                                        seasonId]:
                                                          e.target.value,
                                                      },
                                                    })
                                                    /*} else {
                                                      var tab = { ...tabella }
                                                      var x =
                                                        row +
                                                        "-" +
                                                        "OMBRELLONE_" +
                                                        umbrellaType[0].id +
                                                        "-" +
                                                        sectorId +
                                                        "-" +
                                                        seasonId
                                                      delete tab.body[x]
                                                      setTabella(tab)
                                                    }*/
                                                  }}
                                                />
                                              </Td>
                                            )
                                          })
                                      )}
                                    {tabella.header.elements &&
                                      tabella.header.elements.length > 0 &&
                                      tabella.header.elements.map(
                                        element =>
                                          tabella.header.seasons &&
                                          tabella.header.seasons.length > 0 &&
                                          tabella.header.seasons.map(season => {
                                            var elementId = element.id
                                            var seasonId = season.id
                                            return (
                                              <Td
                                                key={elementId + "-" + seasonId}
                                              >
                                                <Input
                                                  type="number"
                                                  min={0}
                                                  value={
                                                    tabella.body[
                                                      [
                                                        key +
                                                          "-" +
                                                          "ELEMENTO" +
                                                          "-" +
                                                          elementId +
                                                          "-" +
                                                          seasonId,
                                                      ]
                                                    ]
                                                      ? tabella.body[
                                                          key +
                                                            "-" +
                                                            "ELEMENTO" +
                                                            "-" +
                                                            elementId +
                                                            "-" +
                                                            seasonId
                                                        ]
                                                      : ""
                                                  }
                                                  onChange={e => {
                                                    //if (e.target.value != "") {
                                                    setTabella({
                                                      ...tabella,
                                                      body: {
                                                        ...tabella.body,
                                                        [key +
                                                        "-" +
                                                        "ELEMENTO" +
                                                        "-" +
                                                        elementId +
                                                        "-" +
                                                        seasonId]:
                                                          e.target.value,
                                                      },
                                                    })
                                                    /*} else {
                                                      var tab = { ...tabella }
                                                      var x =
                                                        row +
                                                        "-" +
                                                        "ELEMENTO" +
                                                        "-" +
                                                        elementId +
                                                        "-" +
                                                        seasonId
                                                      delete tab.body[x]
                                                      setTabella(tab)
                                                    }*/
                                                  }}
                                                />
                                              </Td>
                                            )
                                          })
                                      )}
                                    {tabella.header.types &&
                                      tabella.header.types.length > 0 &&
                                      tabella.header.types.map(
                                        type =>
                                          tabella.header.seasons &&
                                          tabella.header.seasons.length > 0 &&
                                          tabella.header.seasons.map(season => {
                                            var typeId = type.id
                                            var seasonId = season.id
                                            return (
                                              <Td key={typeId + "-" + seasonId}>
                                                <Input
                                                  type="number"
                                                  min={0}
                                                  value={
                                                    tabella.body[
                                                      [
                                                        key +
                                                          "-" +
                                                          "TIPO" +
                                                          "-" +
                                                          typeId +
                                                          "-" +
                                                          seasonId,
                                                      ]
                                                    ]
                                                      ? tabella.body[
                                                          key +
                                                            "-" +
                                                            "TIPO" +
                                                            "-" +
                                                            typeId +
                                                            "-" +
                                                            seasonId
                                                        ]
                                                      : ""
                                                  }
                                                  onChange={e => {
                                                    //if (e.target.value != "") {
                                                    setTabella({
                                                      ...tabella,
                                                      body: {
                                                        ...tabella.body,
                                                        [key +
                                                        "-" +
                                                        "TIPO" +
                                                        "-" +
                                                        typeId +
                                                        "-" +
                                                        seasonId]:
                                                          e.target.value,
                                                      },
                                                    })
                                                    /*} else {
                                                      var tab = { ...tabella }
                                                      var x =
                                                        row +
                                                        "-" +
                                                        "TIPO" +
                                                        "-" +
                                                        typeId +
                                                        "-" +
                                                        seasonId
                                                      delete tab.body[x]
                                                      setTabella(tab)
                                                    }*/
                                                  }}
                                                />
                                              </Td>
                                            )
                                          })
                                      )}
                                  </Tr>
                                )
                              })}
                          </Tbody>
                        </Table>
                      </div>
                    </div>
                  </Row>
                  {/* <h5 className="mt-4">Configurazione</h5>
                  <Row className="w-100">
                    <Col xs={3} className="mb-2">
                      <label>Clienti associabili</label>
                      <Input
                        type="number"
                        min={1}
                        value={formData.customers}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            customers: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    {elements.map((v, i) => (
                      <Col key={v.id} xs={3} className="mb-2">
                        <label>{v.name}</label>
                        <Input
                          type="number"
                          min={0}
                          value={elementsData[i] || "0"}
                          onChange={e => handleInputChange(e, i)}
                        />
                      </Col>
                    ))}
                    <Col xs={3} className="mb-2">
                      <label>Cabina</label>
                      <Select
                        isMulti
                        options={cabins}
                        onChange={e =>
                          setFormData(v => ({
                            ...v,
                            cabins: e.map(v => v.value),
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <h5 className="mt-4">Cliente</h5>
                  <Row className="mb-2">
                    <Col xs={6}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => {}}
                          onClick={() =>
                            setFormData(v => ({
                              ...v,
                              clientType: formData.clientType == 2 ? 1 : 2,
                            }))
                          }
                          checked={formData.clientType == 1}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Azienda
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Nome</label>
                      <Input
                        value={formData.name}
                        onChange={e =>
                          setFormData(v => ({ ...v, name: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Cognome</label>
                      <Input
                        value={formData.surname}
                        onChange={e =>
                          setFormData(v => ({ ...v, surname: e.target.value }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Email</label>
                      <Input
                        value={formData.email}
                        onChange={e =>
                          setFormData(v => ({ ...v, email: e.target.value }))
                        }
                      />
                    </Col>
                    <Col className="position-relative">
                      <label>Telefono</label>
                      <CreatableSelect
                        className="w-100"
                        value={formData.phone}
                        onChange={handleChange}
                        onCreateOption={handleCreate}
                        options={
                          fictionalCustomers
                            ? fictionalCustomers.map(v => ({
                                label: v.phone,
                                value: v.phone,
                              }))
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Sponsor</label>
                      <Select
                        defaultValue={formData.sponsor}
                        onChange={e => setFormData(d => ({ ...d, sponsor: e }))}
                        options={[
                          { label: "Nessuno", value: 0 },
                          { label: "Hotel", value: 1 },
                          { label: "Azienda", value: 2 },
                        ]}
                      />
                    </Col>
                    <Col>
                      {formData.sponsor.value !== 0 ? (
                        <>
                          <label>
                            Nome {formData.sponsor.label.toLowerCase()}
                          </label>
                          <Input
                            value={formData.sponsorName}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                sponsorName: e.target.value,
                              }))
                            }
                          />
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  {formData.clientType == 1 ? (
                    <>
                      <h5>Azienda</h5>
                      <Row className="mb-2">
                        <Col>
                          <label>Nome</label>
                          <Input
                            value={formData.companyName}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                companyName: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Via</label>
                          <Input
                            value={formData.address}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                address: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Nazione</label>
                          <Input
                            value={formData.nation}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                nation: e.target.value,
                              }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Provincia</label>
                          <Input
                            value={formData.province}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                province: e.target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>CAP</label>
                          <Input
                            value={formData.zip}
                            onChange={e =>
                              setFormData(v => ({ ...v, zip: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>P.IVA</label>
                          <Input
                            value={formData.vat}
                            onChange={e =>
                              setFormData(v => ({ ...v, vat: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>Codice Fiscale</label>
                          <Input
                            value={formData.fc}
                            onChange={e =>
                              setFormData(v => ({ ...v, fc: e.target.value }))
                            }
                          />
                        </Col>
                        <Col>
                          <label>PEC</label>
                          <Input
                            value={formData.certfiedEmail}
                            onChange={e =>
                              setFormData(v => ({
                                ...v,
                                certfiedEmail: e.target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <label>SDI</label>
                          <Input
                            value={formData.sdi}
                            onChange={e =>
                              setFormData(v => ({ ...v, sdi: e.target.value }))
                            }
                          />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                      </Row>
                    </>
                  ) : null}
                  <Row className="mt-3">
                    <Col>
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary"
                      >
                        Crea prenotazione
                      </button>
                      <button
                        onClick={calculatePrices}
                        className="btn btn-secondary mx-2"
                      >
                        Calcola prezzi
                      </button>
                    </Col>
                  </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="save-bottombar">
        <button
          style={{ paddingLeft: 50, paddingRight: 50 }}
          disabled={Object.values(tabella.body).length == 0 || isDisabledButton}
          className="btn btn-secondary mx-3"
          onClick={saveDataToServer}
        >
          Salva
        </button>
      </div>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast}>
          <ToastBody className="bg-success text-white">
            Modifiche salvate con successo.
          </ToastBody>
        </Toast>
        <Toast isOpen={toast2}>
          <ToastBody className="bg-danger text-white">Errore.</ToastBody>
        </Toast>
      </div>
    </>
  )
}

export default ListinoPrezzi
