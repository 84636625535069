import { Input, Modal, Row, Col, ModalBody } from "reactstrap"
import React, { useState, useEffect } from "react"

const Whatnext = props => {
  return (
    <Modal
      centered={true}
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header d-flex w-100 align-items-center justify-content-center">
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body w-100 h-100 d-flex flex-column align-items-center justify-content-center p-5">
        <h5>Vuoi essere reinderizzato al planner?</h5>
        <div className="d-flex mt-3 justify-content-center">
          <button
            className="btn btn-secondary mx-1 w-100"
            onClick={() => props.onHide()}
          >
            No, inserisco una nuova prenotazione
          </button>
          <button
            className="btn btn-primary mx-1 w-100"
            onClick={() => (window.location.href = "/planner/booking")}
          >
            Si, renderizzami al planner
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default Whatnext
