import {
  GET_ANAGRAFICA,
  GET_ANAGRAFICA_FAIL,
  GET_ANAGRAFICA_SUCCESS,
  GET_PAYMENT_OPTIONS,
  GET_PAYMENT_OPTIONS_FAIL,
  GET_PAYMENT_OPTIONS_SUCCESS,
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
} from "./actionTypes"

export const getAnagrafica = id => ({
  type: GET_ANAGRAFICA,
  payload: id,
})
export const getAnagraficaSuccess = data => ({
  type: GET_ANAGRAFICA_SUCCESS,
  payload: data,
})

export const getAnagraficaFail = error => ({
  type: GET_ANAGRAFICA_FAIL,
  payload: error,
})
export const getPaymentOptions = () => ({
  type: GET_PAYMENT_OPTIONS,
})
export const getPaymentOptionsSuccess = data => ({
  type: GET_PAYMENT_OPTIONS_SUCCESS,
  payload: data,
})
export const getPaymentOptionsFail = error => ({
  type: GET_PAYMENT_OPTIONS_FAIL,
  payload: error,
})
export const getTags = () => ({
  type: GET_TAGS,
})
export const getTagsSuccess = data => ({
  type: GET_TAGS_SUCCESS,
  payload: data,
})
export const getTagsFail = error => ({
  type: GET_TAGS_FAIL,
  payload: error,
})
