import { Input, Modal, Row, Col } from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  patchSeason,
  patchSector,
  patchType,
  putPricelist,
  putSeason,
  putSector,
} from "helpers/fakebackend_helper"

const Editsector = props => {
  const [formData, setFormData] = useState({
    name: "",
    id: false,
  })
  const handleSubmit = async () => {
    const resp = await patchSector(formData)
    if (resp.status) {
      await props.updateData()
      props.onHide()
    }
  }
  useEffect(() => {
    setFormData(props.data)
  }, [props.data])
  useEffect
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.onHide()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Modifica settore
        </h5>
        <button
          type="button"
          onClick={() => {
            props.onHide()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <label>Nome</label>
            <Input
              value={formData.name}
              onChange={e => setFormData(v => ({ ...v, name: e.target.value }))}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.onHide()
            setFormData({
              name: "",
              date_from: "",
              date_to: "",
              id: false,
            })
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Chiudi
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary "
        >
          Salva
        </button>
      </div>
    </Modal>
  )
}

export default Editsector
