import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const ModalEventAnswers = ({ isOpen, toggle, answers }) => {

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Risposte</ModalHeader>
            <ModalBody>
                <ul>
                    {answers.map((answer, index) => (
                        <li key={index}><label>{answer.label}</label><p>{answer.value}</p></li>
                    ))}
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Chiudi
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalEventAnswers