import ModalAdd from "components/events/ModalAdd"
import { deleteEvent, retrieveEvents } from "helpers/fakebackend_helper"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import {
  Container,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
} from "reactstrap"
export default function Events() {
  checkIfUserHasPurchasedModule(7)
  const [showAdd, setShowAdd] = useState(false)
  const [events, setEvents] = useState([])
  const [focusedEvents, setFocusedEvents] = useState({
    data: {},
    id: null,
  })

  const handleDelete = async id => {
    const conf = confirm("Sei sicuro di voler eliminare questo evento?")
    if (!conf) return
    const data = await deleteEvent(id)
    if (data.status) {
      //splice it
      const newEvents = events.filter(e => e.id !== id)
      setEvents(newEvents)
      toast.success("Evento eliminato con successo")
    }
  }
  const loadEvents = async () => {
    const data = await retrieveEvents()
    setEvents(data)
  }
  console.log(events)

  useEffect(() => {
    loadEvents()
  }, [])
  return (
    <>
      <ToastContainer />
      <ModalAdd
        show={showAdd}
        onHide={() => setShowAdd(false)}
        handleUpdate={loadEvents}
        data={focusedEvents.data}
        id={focusedEvents.id}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={3}>
                      <label>Cerca per nome</label>
                      <Input type="text" placeholder="Cerca..." />
                    </Col>
                    <Col xs={2} className="d-flex flex-column">
                      <label>&nbsp;</label>
                      <Button
                        onClick={() => {
                          setShowAdd(true)
                          setFocusedEvents({
                            data: {},
                            id: null,
                          })
                        }}
                      >
                        Aggiungi
                      </Button>
                    </Col>
                  </Row>
                  <Table className="mt-2">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Da</th>
                        <th>A</th>
                        <th>Descrizione</th>
                        <th>Foto</th>
                        <th>Partecipanti</th>
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      {events.map((e, i) => (
                        <tr key={i}>
                          <td>{e.title}</td>
                          <td>
                            {" "}
                            {new Date(e.date_from).toLocaleDateString()}{" "}
                            {new Date(e.date_from).toLocaleTimeString()}
                          </td>
                          <td>
                            {new Date(e.date_to).toLocaleDateString()}{" "}
                            {new Date(e.date_to).toLocaleTimeString()}
                          </td>
                          <td>{e.description}</td>
                          <td>
                            <img src={e.image} style={{ height: "56px" }} />
                          </td>
                          <td>{e.participants}</td>
                          <td className="d-flex flex-column">
                            <Button
                              size="sm"
                              className="mb-2"
                              onClick={() => {
                                setFocusedEvents({
                                  data: e,
                                  id: e.id,
                                })
                                setShowAdd(true)
                              }}
                              color="primary"
                            >
                              Modifica
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => handleDelete(e.id)}
                            >
                              Elimina
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
